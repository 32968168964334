import * as React from "react";
import { useEffect, useState } from "react";
import { FrontEndPoints } from "../../shared/Common";
import "./EqEventHolderDashboard.scss";
import { Row, Col, Checkbox, Skeleton } from "antd";
import { useTranslation, Trans } from "react-i18next";

import { Link, useLocation } from "react-router-dom";
import Api from "../../shared/Api";
import {
  GetEventMessage,
  ApiEndpoints,
  ValidateIsTrainer,
} from "../../shared/Common";
import { EqMessageType } from "../../shared/enums";
import { IStudentDto } from "../../shared/interfaces/IStudentDto";

import { useNavigate } from "react-router-dom";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState } from "../../context/Context";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqMessage, { IEqMessage, IEqMessageData } from "../../eqTrainer/eqMessage/EqMessage";
import EqActivityTrainingList from "../../eqTrainer/eqActivityTrainingList/EqActivityTrainingList";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";

const EqEventHolderDashboard = (props: any) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();
  const [fisCheckAll, fsetIsCheckAll] = useState(false);
  const [messageString, setMessageString] = useState("");
  const [messages, setMessages] = useState([] as Array<any>);
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [misCheckAll, msetIsCheckAll] = useState(false);
  const [trainings, setTrainings] = React.useState(null) as any;
  const [eventsList, setEventsList] = React.useState(null) as any;

  const [currentStudentslist, setCurrentStudentsList] = React.useState(
    [] as Array<IStudentDto>
  );
  const [keys, setKeys] = useState(new Array<any>());
  const [areas, setAreas] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState() as any;

  const [population, setPopulation] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [studentHorsesList, setStudentHorsesList] = React.useState({});

  const [message, setMessage] = React.useState("");
  const [imagesData, setImagesData] = React.useState([]) as any;

  var selectedMessages = new Array<any>();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

 
  const GetTrainerStudents = () => {
    Api.get(ApiEndpoints.GetTrainerStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var trainerStudents = response.data;
          setIsError(false);

          setMessage("");
          setTrainerStudents(response.data);

          [...trainerStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.trainer.horses.length > 0) {
              [...item.trainer.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.Location.GetUserAreaOptions).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getImages = () => {
    Api.get("image/getEventImages").then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);
        }
      },
      () => {
        setIsError(true);
        setMessage("Error Occurred while fetching images from server");
      }
    );
  };


  const replyEmail = (message: IEqMessageData) => {
    GetMessages();
  };
  const mardFlagedEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.saveMessage, {
        Message: { Key: message.key },
        IsFlaged: message.isFlaged,
        IsRead: message.isRead,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const deleteEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.deleteMessage, {
        Message: { Key: message.key },
      }).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const onCheckChange = (message: IEqMessageData, checked: boolean) => {
    if (checked) {
      selectedMessages.push({ Key: message.key });
    } else {
      if (selectedMessages != null && selectedMessages.length > 0) {
        const index = selectedMessages.findIndex((x) => x.Key == message.key);
        if (index > -1) {
          selectedMessages.splice(index, 1);
        }
      }
    }

    setKeys(selectedMessages);
  };
  const deleteSelectedMessages = () => {
    msetIsCheckAll(false);

    if (keys?.length > 0) {
      Api.post(ApiEndpoints.deleteSelectedMessages, keys).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();

            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const readSelectedMessages = () => {
    msetIsCheckAll(false);
    selectedMessages = [];

    keys.map((opt: any) => {
      selectedMessages.push({ Message: { Key: opt.Key }, IsRead: true });
    });
    if (keys?.length > 0) {
      Api.post(ApiEndpoints.saveSelectedMessages, selectedMessages).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            selectedMessages = [];
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };

  const GetMessages = async () => {
    setMessageString("");
    var messagesFromDB = [] as any;
    await Api.get(ApiEndpoints.getMessages).then(
      (response) => {
        if (response && response.status == 200) {
          response.data?.result?.map((d: any) => {
            if (d.message.messageType !== EqMessageType.Notification) {
              messagesFromDB.push({
                message: {
                  key: d.message.key,
                  messageOn: new Date(d.message.sentOn),
                  type: EqMessageType[d.message.messageType],
                  from: d.message?.sender?.userName,
                  to: "trainer",
                  subject: d.message.subject,
                  text: d.message.message,
                  isRead: d.isRead,
                  isSent: d.isSent,
                  isFlaged: d.isFlaged,
                },
                checkBoxBordered: false,
                clickedReplyFun: replyEmail,
                clickedFlagFun: mardFlagedEmail,
                clickedDeleteFunc: deleteEmail,
                onCheckChangeFunc: onCheckChange,
                selected: false,
              });
            }
          });
          messagesFromDB = messagesFromDB?.sort((obj1: any, obj2: any) => {
            if (obj1.message.isRead > obj2.message.isRead) {
              return 1;
            } else if (obj1.message.isRead < obj2.message.isRead) {
              return -1;
            } else {
              return 0;
            }
          });

          setMessages(messagesFromDB);
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };







  /// EVENTS

  const GetTrainerPublicEvents = async () => {
    let path = ApiEndpoints.GetTrainerEvents;
    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setEventsList(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleOnUpdateEvent = (newData: any, oldData: any) => {
    if (eventsList != undefined) {
      const events = [...eventsList];
      var foundIndex = events.findIndex(
        (x) => x.referenceKey == oldData.referenceKey
      );
      events[foundIndex] = newData;
      setEventsList(events);
    }
  };
  const removeEvent = (data: IEqEventData, refund?: boolean) => {
    // let path=ApiEndpoints.DeleteTrainerEvent + `/${data.key}`;
        let path =
        ApiEndpoints.DeleteTrainerEvent +
        `?key=${data.key}&refund=${refund}`;
    
      Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeEventItem(response.data);
          GetTrainerPublicEvents();
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeEventItem = (data: any) => {
    if (eventsList != undefined) {
      var updatedList =
        eventsList != undefined
          ? eventsList.filter((item:any) => item.key !== data.key)
          : new Array<any>();
      setEventsList(updatedList);

      eventsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };


  const messagesCheckAll = () => {
    msetIsCheckAll(!misCheckAll);

    messages.map((m: IEqMessage) => {
      if (m.selected == false) {
        m.selected = !misCheckAll;
        selectedMessages.push({ Key: m.message.key });
      } else {
        m.selected = !misCheckAll;
        if (selectedMessages != null && selectedMessages.length > 0) {
          const index = selectedMessages.findIndex(
            (x) => x.Key == m.message.key
          );
          if (index > -1) {
            selectedMessages.splice(index, 1);
          }
        }
      }
      setKeys(selectedMessages);
    });
    const modifiedList = [...messages];
    setMessages(modifiedList);
  };
  const finanacialCheckAll = () => {
    fsetIsCheckAll(!fisCheckAll);

    currentStudentslist.map((f: any) => {
      f.selected = !fisCheckAll;
    });
    const modifiedList = [...currentStudentslist];
    setCurrentStudentsList(modifiedList);
  };

  const GetStripeDashboardUrlAsync = () => {
    
    Api.get("Payment/GetAccountUrl").then(
      (response) => {
        if (response.status == 200) {
          const data = response.data;
          // window.location.href = data.url;
          window.open(data.url, '_blank');
         
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  useEffect(() => {
    
    getAreas();
    getWeekDays();
    getPopulation();
    getGroupPopulation();
    getImages();
    GetTrainerStudents();
    GetTrainerPublicEvents();
    GetMessages();
  }, []);

  return (
    <AnimationDiv>
   
    <div className="eq-sb-container">
      <div className="eq-container-fluid">
        <Row className="eq-sb-heading-row">
          <Col className="eq-sb-col" flex="auto" md={5} xs={24} sm={24}>
            <h2 className="eq-sb-heading">{t("myDashboard")}</h2>
          </Col>
        </Row>
        <div className="eq-section">
          <Row className="eq-db-row" align="stretch">
            <Col xs={24} sm={24} md={18} className="eq-db-icons">
              <Row>
                <div className="eq-db-h2">{t("quickLinks")}</div>
              </Row>
              <Row align="bottom" justify="start" className="eq-db-icon-row">
             
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <Link
                    to={FrontEndPoints.eventHolder.activityBoard}
                    state={{ prevPath: location.pathname }}
                  >
                    <span>
                      <img alt="" src="/calendarplus.svg" />
                    </span>
                    <div className="eq-db-icon-text">{t("createEvent")}</div>
                  </Link>
                </Col>
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <div
                 onClick={GetStripeDashboardUrlAsync}
                  >
                    <span>
                      <img alt="" src="/profile.svg" />
                    </span>
                    <div className="eq-db-icon-text">{t("stripeDashboard")}</div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

   


        <div className="eq-section-divider"></div>
        <div className="eq-section">
          <EqCollapsePanel
            header={t("newMessages")}
            children={
              <>
                <Row className="eq-msg-header" align="bottom">
                  <Col flex="auto">
                    <div className="eq-db-h2-collapse">{t("newMessages")}</div>
                  </Col>
                  <Col md={2}>
                    <Row>
                      <Col
                        className="eq-msg-icons"
                        onClick={readSelectedMessages}
                      >
                        <span>
                          <img alt="" src="/black-check.svg" />
                        </span>
                      </Col>
                      {/* <Col className="eq-msg-icons">
                  <Link to={FrontEndPoints.trainer.composeMessage}>
                    <span>
                      <img alt="" src="/black-Envelope.svg" />
                    </span>
                  </Link>
                </Col> */}
                      <Col
                        className="eq-msg-icons"
                        onClick={deleteSelectedMessages}
                      >
                        <span>
                          <img alt="" src="/trash-alt.svg" />
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                {messages.length > 0 && (
                  <>
                    {
                      <Row className="eq-list-header hidden-sm">
                        <Col md={22} flex="auto">
                          <Row>
                            <Col span={1} className="">
                              <Checkbox
                                onChange={messagesCheckAll}
                                checked={misCheckAll}
                              ></Checkbox>
                            </Col>
                            <Col span={3} className="">
                            {t("type")}
                            </Col>
                            <Col span={5} className="">
                            {t("from")}
                            </Col>
                            <Col flex="auto" className="">
                            {t("messageTitle")}
                            </Col>
                          </Row>
                        </Col>
                        <Col xs={4} sm={4} md={2} className="">
                        {t("action")}
                        </Col>
                      </Row>
                    }

                    {messages.map((item: IEqMessage, index: number) => (
                      <EqMessage key={index} {...item}></EqMessage>
                    ))}
                  </>
                )}
              </>
            }
          />
        </div>



        <div className="eq-section-divider"></div>

        <div className="eq-section">
        <Skeleton loading={!eventsList} active></Skeleton>

          <Row>
            {eventsList && eventsList?.length > 0 && (
              ////-------------------//
              <Col span={24}>
                <div className="eq-db-h2">{t("events")}</div>

                <EqActivityTrainingList
                  eventList={eventsList}
                  areas={areas}
                  weekDays={weekDays}
                  population={population}
                  groupPopulation={groupPopulation}
                  trainerStudents={trainerStudents}
                  studentHorsesList={studentHorsesList}
                  removeEventFunc={removeEvent}
                  // editEventFunc= editEvent
                  // onCreateOrUpdateActivity={trainerEventDefaults}
                  onCreateOrUpdateActivity={handleOnUpdateEvent}
                ></EqActivityTrainingList>
              </Col>
            )}
          </Row>
        </div>


    
      </div>
    </div>
    </AnimationDiv>

  );
};

export default EqEventHolderDashboard;
