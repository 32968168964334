import * as React from "react";
import "./EqGroupCreateOrEdit.scss";
import EqGroupMembersList from "../eqGroupMembersList/EqGroupMembersList";
import { Row, Col, Checkbox } from "antd";
import { ColorPicker, Radio, Spin } from "antd-v5";
import { EqInput } from "../../custom/eqInput/EqInput";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import EqButton from "../../custom/eqButton/EqButton";
import EqSelect from "../../custom/eqSelect/EqSelect";
import { useTranslation } from "react-i18next";
import Api from "../../shared/Api";
import { ApiEndpoints, GetGroupMessage } from "../../shared/Common";
import { EqGroupType, EqUserType } from "../../shared/enums";
import { ICreateGroupDto } from "../../shared/interfaces/ICreateGroupDto";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import { useState } from "react";
import EqHorseGroupList, { IEqGroupHorsesList } from "../eqHorseGroupList/EqHorseGroupList";
import { ICreateGroupInfoDto } from "../../shared/interfaces/ICreateGroupInfoDto";
import { IStudentGroupInfoDto } from "../../shared/interfaces/IStudentGroupInfoDto";

export interface IEqGroupCreateOrEdit {
    header: string;
    groupHeading: string;
    nameHeading: string;
    messageHeading: string;
    cancelFunc?: any;
    sendFunc?: any;
    groupData?: any;
    studentsList: Array<IStudentGroupDto>;
    searchStudentsList: any;
    colorAssign: any;
}
const EqGroupCreateOrEdit = (props: IEqGroupCreateOrEdit) => {
    const {
        header,
        groupHeading,
        nameHeading,
        messageHeading,
        cancelFunc,
        sendFunc,
        groupData,
        studentsList,
        searchStudentsList,
        colorAssign,
    } = props;
    const { t, i18n } = useTranslation();

    const [spinning, setSpinning] = React.useState<boolean>(false);

    const [isError, setIsError] = React.useState(false);
    const [message, setMessage] = React.useState("");
    const [isEdit, setIsEdit] = React.useState(groupData ? true : false);
    const [messageString, setMessageString] = React.useState("");
    const [selectAll, setSelectAll] = React.useState(false);
    const [formData, setFormData] = React.useState({
        type: groupData?.type ? groupData.type : EqGroupType.House,
        name: "Group 1",
        message: "Write message ...",
        color: "#FF8838",
    }) as any;
    const [membersList, setMembersList] = React.useState([] as Array<IStudentGroupDto>);

    const [filteredStudents, setFilteredStudents] = React.useState([] as any);
    const [horses, setHorses] = useState({
        selected: [] as any,
        data: [] as any,
        apiData: [] as any,
    });
    const [selectedGroupTypeOption, setSelectedGroupTypeOption] = useState(
        groupData?.type || EqGroupType.People
    );
    const [horseSelectLoading, setHorseSelectLoading] = useState(false);
    const setGroupDefaults = () => {
        setFormData({
            type: EqGroupType.House,
            name: "Group 1",
            message: "Write message ...",
            color: "#FF8838",
        });
        setMembersList([] as Array<IStudentGroupDto>);
        setFilteredStudents([]);
    };

    const onChangeGroupSelect = (value: any) => {
        const selectedMembers = value;
        const list = studentsList.filter((itemA: any) => {
            return selectedMembers.includes(itemA.key);
        });
        setFilteredStudents([...list]);
        setMembersList([...list]);
    };

    const removeGroupMember = (member: any) => {
        var selectedList = [...filteredStudents];
        if (selectedList != null && selectedList.length > 0) {
            const index = selectedList.findIndex((x: any) => x.key == member.key);
            if (index > -1) {
                selectedList.splice(index, 1);
            }
        }
        var selectedVals = selectedList?.map((d: any) => d.key);
        onChangeGroupSelect([...selectedVals]);
    };

    const setGroupObject = (data: any): ICreateGroupInfoDto => {
        const newDto: ICreateGroupInfoDto = {
            key: groupData?.key ? groupData.key : 0,
            name: data.name,
            type: selectedGroupTypeOption,
            color: data.color,
            message: data.message,
            events: null,
        };
        if (selectedGroupTypeOption !== EqGroupType.Horse) {
            if (newDto.GroupMembers == undefined) {
                newDto.GroupMembers = new Array<IStudentGroupInfoDto>();
            }
            if (newDto.Users == undefined) {
                newDto.Users = new Array<IStudentGroupInfoDto>();
            }
            membersList?.map((item: IStudentGroupInfoDto) => {
                // newDto.GroupMembers?.push(item);
                newDto.Users?.push(item);
            });
        } else {
            const list = toHorsesList(horses.apiData, horses.selected);
            newDto.horses = list;
        }
        return newDto;
    };

    const toHorsesList = (apiData: any, selectedArray: any): any => {
        const list = apiData.filter((itemA: any) => {
            return (
                selectedArray.some((e: any) => (e.key ? e.key == itemA.id : e == itemA.id)) ||
                selectedArray.some((f: any) => f.key == itemA.name)
            );
        });
        return list;
    };

    const saveGroup = (group: any) => {
        var data = setGroupObject(formData);
        setSpinning(true);

        Api.post(ApiEndpoints.groupEdit, data).then(
            (response) => {
                if (response && response.status == 200) {
                    setIsError(false);
                    setMessage(GetGroupMessage(response.status));
                    sendFunc(data);
                    // setGroupDefaults();
                    // setIsEdit(false);
                    //return <Redirect to={FrontEndPoints.trainer.eventsMain} />;
                    //SetEventsList(response.data.item2);
                } else {
                    setIsError(true);
                    setMessage(GetGroupMessage(response.status));
                }
        setSpinning(false);

            },
            (error) => {
                setIsError(true);
                setMessage(GetGroupMessage(error.response.status));
        setSpinning(false);

            }
        );
    };

    const createGroup = (group: any) => {
        setSpinning(true);
        var data = setGroupObject(formData);

        Api.post(ApiEndpoints.groupCreate, data).then(
            (response) => {
                if (response && response.status == 200) {
                    setIsError(false);
                    setMessage(GetGroupMessage(response.status));
                    setGroupDefaults();
                    sendFunc(data);
                    // setMembersList([] as Array<IStudentGroupDto>);
                    //SetEventsList(response.data.item2);
                } else {
                    setIsError(true);
                    setMessage(GetGroupMessage(response.status));
                }
                setSpinning(false);
            },
            (error) => {
                setIsError(true);
                setMessage(GetGroupMessage(error.response.status));
                setSpinning(false);
            }
        );
    };

    const togglerProps: IEqToggle = {
        items: [
            {
                key: EqGroupType.House.toString(),
                value: [<span className="hidden-sm">House</span>],
                icon: [
                    <span className="eq-btn-icon">
                        <img alt="" src="/home.svg" />
                    </span>,
                ],
            },
            {
                key: EqGroupType.Stable.toString(),
                value: [<span className="hidden-sm">{t("Stable")}</span>],
                icon: [
                    <span className="eq-btn-icon">
                        <img alt="" src="/stable.svg" />
                    </span>,
                ],
            },
            {
                key: EqGroupType.People.toString(),
                value: [<span className="hidden-sm">{t("People")}</span>],
                icon: [
                    <span className="eq-btn-icon">
                        <img alt="" src="/user-friends.svg" />
                    </span>,
                ],
            },
            {
                key: EqGroupType.Event.toString(),
                value: [<span className="hidden-sm">{t("Event")}</span>],
                icon: [
                    <span className="eq-btn-icon">
                        <img alt="" src="/award.svg" />
                    </span>,
                ],
            },
            {
                key: EqGroupType.Horse.toString(),
                value: [<span className="hidden-sm">Horse</span>],
                icon: [
                    <span className="eq-btn-icon">
                        <img alt="" src="/horse.svg" />
                    </span>,
                ],
            },
        ],
        defaultSelectedValue: formData?.type?.toString(),
        radioGroupClasses: "eq-toggle-orange-border",
        changeFunc: (value: string) => {
            setFormData({ ...formData, type: +value });
        },
    };

    const searchProps = {
        mode: "multiple",
        isTag: false,
        showArrow: false,
        allowClear: true,
        showSearch: true,
        optionFilterProp: "label",
        filterOption: true,
        changeFunc: onChangeGroupSelect,
        classNames: "eq-multiSelect eq-searchSelect extra-height",
        ddClassName: "eq-select-dropdown",
        icon: [
            <span className="eq-search-icon">
                <img alt="" src="/search-icon.svg" />
            </span>,
        ],
        selectedIcon: [
            <span className="">
                <img alt="" src="/check-square.svg" />
            </span>,
        ],
        defaultSelectedValue: filteredStudents,
        // defaultSelectedValue: memberSearch,
        options: searchStudentsList,
    };

    const [groupMembersList, setGroupMembersList] = useState(null as any);

    const removeHorseFromGroup = (member: any): void => {
        var arr = [...horses.selected];

        var item = arr.find((d) => d.key == member.id);
        arr.splice(arr.indexOf(item), 1);
        setHorses({ ...horses, selected: arr });

        var lst = [...groupMembersList];
        lst.splice(lst.indexOf(member), 1);
        setGroupMembersList(lst);
    };

    const onHorseChange = (values: any) => {
        const list = horses.apiData.filter((itemA: any) => {
            return (
                values.some((e: any) => e == itemA.id) || values.some((f: any) => f == itemA.name)
            );
        });
        setGroupMembersList(list);
        setHorses({ ...horses, selected: [...values] });
    };

    const horseListProps = {
        mode: "multiple",
        isTag: false,
        showArrow: false,
        allowClear: true,
        showSearch: true,
        optionFilterProp: "label",
        filterOption: true,
        changeFunc: onHorseChange,
        classNames: "eq-multiSelect eq-searchSelect extra-height",
        ddClassName: "eq-select-dropdown",
        icon: [
            <span className="eq-search-icon">
                <img alt="" src="/search-icon.svg" />
            </span>,
        ],
        selectedIcon: [
            <span className="">
                <img alt="" src="/check-square.svg" />
            </span>,
        ],
        defaultSelectedValue: horses.selected,
        options: horses.data,
        loading: horseSelectLoading,
    };

    const editGroupAttendees = () => {
        var memberData = [] as any;

        if (groupData?.students?.length > 0) {
            groupData?.students?.map((item: any) => {
                if (item.referenceGroup != null) {
                    var student: IStudentGroupDto = {
                        key: item.referenceGroup.key + "",
                        user: item,
                        group: item.referenceGroup,
                        userType: EqUserType.Group,
                    };
                } else {
                    var student: IStudentGroupDto = {
                        key: item.email + "",
                        user: item,
                        group: item.referenceGroup,
                        userType: EqUserType.Student,
                    };
                }

                memberData.push({ ...student });
                //memberData.push({ student });
            });
            setFilteredStudents([...memberData]);
            setMembersList([...memberData]);
        }
        return memberData;
    };
    const setEditGroup = () => {
        setFormData({
            type: groupData.type,
            name: groupData.groupName,
            message: groupData.message,
            color: groupData.color,
        });
        if (groupData.students?.length > 0) {
            editGroupAttendees();
        }
    };
    React.useEffect(() => {
        if (!horses?.data?.length) {
            if (isEdit && groupData.horses && groupData.horses.length) {
                var mappedHorsesList: any = groupData.horses.map((item: any, index: any) => {
                    let obj = {
                        key: item.id,
                        value: item.name,
                    };
                    return obj;
                });
                loadingHorsesData(mappedHorsesList);
            } else {
                loadingHorsesData();
            }
        }

        if (groupData != null) {
            setEditGroup();
        }
    }, [groupData, studentsList]);

    const handleRadioChange = (groupSource: any) => {
        setSelectedGroupTypeOption(groupSource.target.value);
    };

    const loadingHorsesData = (selected: any = []): void => {
        setHorseSelectLoading(true);
        Api.get(ApiEndpoints.horse.getAllHorsesWithGroupsOnly).then(
            (response: any) => {
                setHorseSelectLoading(false);
                if (response && response.status == 200) {
                    let data = response.data;

                    var mappedHorsesList: any = data.map((item: any, index: any) => {
                        let obj = {
                            key: item.id,
                            value: item.name,
                        };
                        return obj;
                    });
                    var lstt = toHorsesList(data, [...selected]);
                    setGroupMembersList(lstt);
                    const keysArray = selected.map((obj: any) => obj.key);

                    setHorses({
                        ...horses,
                        data: mappedHorsesList,
                        apiData: [...data],
                        selected: keysArray,
                    });
                }
            },
            (error: any) => {
                setHorseSelectLoading(false);
                console.log(error);
            }
        );
    };

    return (
        <Spin spinning={spinning} size="large">
            <Row className="eq-create-edit-g-container">
                <Col flex="auto">
                    <Row>
                        <h2 className="eq-cg-heading">{header}</h2>
                    </Row>
                    {!isEdit ? (
                        <Row>
                            <Col>
                                <div className="group-options">
                                    <span className="options-heading">{t("groupType")}</span>

                                    <div className="options">
                                        <Radio.Group
                                            onChange={handleRadioChange}
                                            value={selectedGroupTypeOption}
                                        >
                                            <Radio
                                                className="options_1 option"
                                                value={EqGroupType.People}
                                            >
                                                {t("students")}
                                            </Radio>
                                            <Radio
                                                className="options_2 option"
                                                value={EqGroupType.Horse}
                                            >
                                                {t("horse")}
                                            </Radio>
                                        </Radio.Group>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    ) : (
                        ""
                    )}

                    <Row>
                        <Col xs={24} sm={24} md={8} className="eq-cg-col-left">
                            <Row>
                                <h3 className="eq-cg-input-heading">{nameHeading}</h3>
                            </Row>
                            <Row>
                                <Col flex="auto">
                                    <EqInput
                                        className="eq-cg-input"
                                        placeholder={t("groupName")}
                                        value={formData.name}
                                        onChange={(event: any) => {
                                            setFormData({ ...formData, name: event.target.value });
                                        }}
                                    ></EqInput>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={24} sm={24} md={16} className="eq-cg-col-right">
                            <Row>
                                <h3 className="eq-cg-input-heading">{colorAssign}</h3>
                            </Row>
                            <Row>
                                <Col flex="auto">
                                    <ColorPicker
                                        className=""
                                        showText
                                        value={formData.color}
                                        onChange={(value: any, hex: string) => {
                                            setFormData({ ...formData, color: hex });
                                        }}
                                    ></ColorPicker>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className="eq-cg-members-header" align="middle">
                        <Col xs={24} sm={24} md={12}>
                            <Row justify="start">
                                <Col span={24}>
                                    <h3 className="eq-cg-members-heading">
                                        {t("addGroupMembers")}
                                    </h3>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {selectedGroupTypeOption == EqGroupType.Horse ? (
                        <>
                            <Row className="eq-cg-search-header" justify="end">
                                <EqSelect {...horseListProps}></EqSelect>
                            </Row>
                            <EqHorseGroupList
                                {...{
                                    groupMembersList: groupMembersList,
                                    delFunc: removeHorseFromGroup,
                                }}
                            ></EqHorseGroupList>
                        </>
                    ) : (
                        <>
                            <Row className="eq-cg-search-header" justify="end">
                                <EqSelect {...searchProps}></EqSelect>
                            </Row>
                            <EqGroupMembersList
                                {...{
                                    groupMembersList: filteredStudents,
                                    delFunc: removeGroupMember,
                                }}
                            ></EqGroupMembersList>
                        </>
                    )}
                    <Row>
                        <Col>{message}</Col>
                    </Row>
                    <Row justify="end" align="bottom" className="eq-cg-btn">
                        {isEdit ? (
                            <Col className="eq-cg-buttons">
                                <EqButton
                                    type="button"
                                    bordered="true"
                                    classNames="btn-light-gray"
                                    onClick={saveGroup}
                                >
                                    {t("save")}
                                </EqButton>
                            </Col>
                        ) : (
                            <>
                                <Col className="eq-cg-buttons">
                                    <EqButton
                                        type="button"
                                        bordered="true"
                                        classNames="btn-light-gray"
                                        onClick={cancelFunc}
                                    >
                                        {t("cancel")}
                                    </EqButton>
                                </Col>
                                <Col className="eq-cg-buttons">
                                    <EqButton
                                        type="button"
                                        classNames="btn-background-orange"
                                        onClick={createGroup}
                                    >
                                        {t("createGroup")}
                                    </EqButton>
                                </Col>
                            </>
                        )}
                    </Row>
                </Col>
            </Row>
        </Spin>
    );
};
export default EqGroupCreateOrEdit;
