import * as React from "react";
import "./EqSchoolActivityBoard.scss";
import { Row, Col, Tabs, Skeleton } from "antd";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsUser,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import { ProductType, TrainingFilters } from "../../shared/enums";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import EqProductList from "../../custom/eqProductList/EqProductList";
import TabPane from "antd/lib/tabs/TabPane";
import EqSchoolTrainings from "../schoolTrainings/EqSchoolTrainings";
import EqTrainerAllEvents from "../../eqTrainer/trainerAllEvents/EqTrainerAllEvents";
import EqSchoolProductTrainings from "../schoolProductTrainings/EqSchoolProductTrainings";
import { Badge } from "antd-v5";
import EqTrainerAllPreviousEvents from "../../eqTrainer/trainerAllPreviousEvents/EqTrainerAllPreviousEvents";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import RevealList from "../../custom/RevealList/RevealList";
import EqSchoolTrainingsPrevious from "../schoolTrainingsPrevious/EqSchoolTrainingsPrevious";
import EqPublicEventBoard from "../../../eqComponents/publicEvents/eqPublicEventBoard/EqPublicEventBoard";
import EqPublicEventPreviousBoard from "../../eqTrainer/eqPublicEventPreviousBoard/EqPublicEventPreviousBoard";
// import EqTrainerAllEvents from "../trainerAllEvents/EqTrainerAllEvents";
// import EqTrainerTrainings from "../trainerTrainings/EqTrainerTrainings";

export interface IFilteredTrainings {
  name: any;
  type: any;
  date: any;
}

const EqSchoolActivityBoard = (props: any) => {
  const userDetails: any = useAuthState();
  const isUserValid: boolean = ValidateIsUser(userDetails);
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);

  const [areas, setAreas] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState() as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [population, setPopulation] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;

  // const [showEventList, setShowEventList] = React.useState(true);
  // const [createActivityList, setCreateActivityList] = React.useState([] as Array<any>);
  const [trainingsList, setTrainingsList] = React.useState([]) as any;
  const [filteredTrainingsList, setFilteredTrainingsList] = React.useState(
    {} as IFilteredTrainings
  );
  const [selectedTrainingsList, setSelectedTrainingsList] = React.useState(
    []
  ) as any;

  const [toggleFilters, setToggleFilters] = React.useState([] as any);
  const [selectedToggle, setSelectedToggle] = React.useState(
    TrainingFilters.All
  ) as any;


  const [trainingsCount, setTrainingsCount] = React.useState(0) as any;
  const [productCount, setProductCount] = React.useState(0) as any;
  const [eventsCount, setEventsCount] = React.useState(0) as any;

  const [isLoading, setIsLoading] = React.useState(false);


  //initial gets

  const getAreas = () => {
    Api.get(ApiEndpoints.Location.GetUserAreaOptions).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const GetTrainerStudents = () => {
    Api.get(ApiEndpoints.GetTrainerStudents).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setTrainerStudents(response.data);
          // console.log("TrainerStudents:", response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const fetchCounts = () => {

    Api.get(ApiEndpoints.GetSchoolTrainingEventCount)
      .then((response) => {
        if (response && response.status === 200) {
          // Assuming the response data is an object with keys for counts
          const { trainingCount, schoolProductCount, eventsCount } = response.data;

          setTrainingsCount(trainingCount);
          setProductCount(schoolProductCount);
          setEventsCount(eventsCount);

          console.log(response.data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      })
      .catch((error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      });
  };

  const updateTrainingCount = (count: any) => {

    fetchCounts()
    setActiveTab("1");
    setTrainingsCount(count);

  };
  const updateProductCount = (count: any) => {
    fetchCounts()
    setActiveTab("2");
    setProductCount(count);

  };
  const updateEventsCount = (count: any) => {
    fetchCounts()
    setActiveTab("3");
    setEventsCount(count);

  };

  const defaultActiveTab = () => {
    if (trainingsCount >= productCount && trainingsCount >= eventsCount) {
      return "1"; // "trainings" tab has the greatest count
    } else if (productCount >= trainingsCount && productCount >= eventsCount) {
      return "2"; // "events" tab has the greatest count
    } else {
      return "3"; // "schoolTrainings" tab has the greatest count (or they are all equal)
    }
  };

  // const [activeTab, setActiveTab] = React.useState(defaultActiveTab());
  const [activeTab, setActiveTab] = React.useState("3");

  const [activeEvent, setActiveEvent] = React.useState(null) as any;





  React.useEffect(() => {
    fetchCounts();
    // setActiveTab(defaultActiveTab)
    const params = new URLSearchParams(window.location.search);
    var tabKey = params.get("tab");
    if (tabKey !== null) {
      setActiveTab(tabKey);
    }
    window.scrollTo(0, 0);

    if (trainingsCount >= 0 && productCount >= 0 && eventsCount >= 0) {
      setIsLoading(false);
    }
  }, [trainingsCount, productCount, eventsCount]);




  return (
    <AnimationDiv>

      <div className={`eq-school-activity-board`}>
        <div className="eq-container-fluid">
          {/* <Row className="eq-tp-heading-row" align="middle" justify="start">
          <Col className="eq-tp-heading-col" flex="auto">
            <h2 className="eq-tp-heading">{t("All Activities")}</h2>
          </Col>
        </Row> */}
          {isLoading ? (
            <div><Skeleton active /></div>
          ) : (
            <Tabs
              className="ab-tabs"
              // onChange={onChange}
              type="card"
              activeKey={activeTab}
              onChange={(key) => {
                setActiveTab(key);
              }}
            >
              <TabPane
                tab={
                  <span>
                    {t("trainings")}{" "}
                    <Badge
                      count={trainingsCount}
                      offset={[0, -15]}
                      className="tab-badge"
                      size="default"
                    >
                    </Badge>
                  </span>}
                key="1"
              >
                <RevealList>
                  <EqSchoolTrainings
                    updateCount={updateTrainingCount} />
                </RevealList>
              </TabPane>


              <TabPane
                tab={
                  <span>
                    {t("ridingCourses")}{" "}
                    <Badge
                      count={productCount}
                      offset={[0, -15]}
                      className="tab-badge"
                      size="default"
                    >
                    </Badge>
                  </span>}
                key="2">
                <RevealList>
                  <EqSchoolProductTrainings
                    updateCount={updateProductCount} />
                </RevealList>
              </TabPane>

              <TabPane
                tab={
                  <span>
                    {t("events")}{" "}
                    <Badge
                      count={eventsCount}
                      offset={[0, -15]}
                      className="tab-badge"
                      size="default"
                    >
                    </Badge>
                  </span>}
                key="3">
                <RevealList>
                <EqPublicEventBoard
                    updateCount={updateEventsCount}
                    activeEvent={activeEvent}
                  />
                 
                </RevealList>
              </TabPane>

              <TabPane tab={<span>{t("previousEvents")} </span>} key="4">
                                <RevealList>
                                    <EqPublicEventPreviousBoard
                                        updateCount={updateEventsCount}
                                        activeEvent={activeEvent}
                                    />
                
                                </RevealList>
                            </TabPane>

              <TabPane tab={<span>{t("previousTrainings")} </span>} key="5">
                <RevealList>
                  <EqSchoolTrainingsPrevious
            
                  />
                </RevealList>
              </TabPane>
            </Tabs>
          )}

        </div>
      </div>


    </AnimationDiv>
  );
};
export default EqSchoolActivityBoard;
