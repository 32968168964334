import { useEffect } from 'react';
import { Col, Row } from 'antd';
import * as React from 'react';
import { useAuthDispatch, useAuthState } from '../context/Context';
import './EqFooter.scss';
import { EquiButton } from '../equiButton/EquiButton';
import EqButton from '../custom/eqButton/EqButton';
import { useTranslation, Trans } from 'react-i18next';
import { FrontEndPoints } from '../shared/Common';
import { useNavigate } from 'react-router-dom';
import AsyncImage from '../custom/asyncImage/AsyncImage';


export const EqFooter = (props: any) => {
    const { t, i18n } = useTranslation();
    const scrolltoTop = () => {
        window.scrollTo(0, 0);
    }
    const navigate = useNavigate();
    const callRegister = () => {
        navigate(FrontEndPoints.register);
    }
    return (
        <div>

            <div className="eq-footer">
                <footer className="">

                    <div className="container">
                        {/*<Row>*/}
                        {/*    <Col span={24}>*/}
                        {/*        <h1 className="heading">{t("getInTouch")}</h1>*/}
                        {/*    </Col>*/}
                        {/*</Row>*/}
                        <Row>
                            <Col span={24} className='equiworld-logo'>
                                <div className='logo'>
                                    <AsyncImage className="logo-img" src="images/Logo.svg" />
                                    <p className="logo-description">
                                    {/*{t("connectwithServicesProviders")}*/}
                                        Equinet
                                    </p>

                                </div>

                                {/*<Row className='footer-profile-btn'>*/}
                                {/*    <EqButton type="button"*/}
                                {/*        onClick={callRegister}*/}
                                {/*    >*/}
                                {/*        {t("createProfile")}</EqButton>*/}

                                {/*</Row>*/}
                            </Col>
                        </Row>
                        <Row>
                            <Col className='first-col' md={10}
                            // className="mb-12"
                            >
                                <p className="comments">
                                    Gamle Kongsvingerveg 4, 2040 Kløfta Norway.
                                </p>
                                <p className="contact">
                                    +46 702 229 499
                                </p>
                                <p className="contact">
                                    sophie@equinet.no
                                </p>
                                {/*<EqButton type="button" */}
                                {/*style={{ backgroundColor: '#fff', borderColor: '#000000', color: '#000000', marginBottom: '36px', padding: '17px 24px', lineHeight: '2px' }} >*/}
                                {/*    {t("questionsComments")}</EqButton>*/}

                                {/*<p className="comments">*/}
                                {/*    {t("advertiseServices")}*/}
                                {/*</p>*/}
                                {/*<EqButton type="button" */}
                                {/*style={{ backgroundColor: '#fff', borderColor: '#000000', color: '#000000', marginBottom: '36px', padding: '17px 24px', lineHeight: '2px' }} >*/}
                                {/*    {t("advertise")}</EqButton>*/}
                            </Col>
                            <Col md={12}>
                                <Row>
                                    <Col xs={24} sm={24} md={12}>
                                        {/* <h3>{t("Company")}</h3>*/}
                                        {/*<h3>{t("Services")}</h3>*/}
                                        {/*<a className="footer-link">{t("aboutEquinet")}</a>*/}
                                        <p className="footer-sub">{t("ourServices")}</p>
                                        <a className="footer-link" href="https://equinet.no/allPublicEvents">{t("events")}</a>
                                        <a className="footer-link" href="https://equinet.no/trainer/allProfessionals">{t("trainers")}</a>
                                        <a className="footer-link" href="https://equinet.no/RidingSchools">{t("ridingschools")}</a>
                                        <a className="footer-link" href="/contact">{t("contactUs")}</a>
                                    </Col>
                                    <Col xs={24} sm={24} md={12}>
                                        {/*<h3>{t("Services")}</h3>*/}
                                        {/*<a className="footer-link">{t("trainers")}</a>*/}
                                        {/*<a className="footer-link">{t("stables")}</a>*/}
                                        {/*<a className="footer-link">{t("ridingschools")}*/}
                                        {/*</a>*/}
                                        {/*<a className="footer-link">{t("farriers")}*/}
                                        {/*</a>*/}
                                        {/*<a className="footer-link">{t("veterinarians")}</a>*/}
                                        {/*<a className="footer-link">{t("therapists")}</a>*/}

                                        <a className="footer-link" href="https://www.facebook.com/equinetno">Facebook</a>
                                        <a className="footer-link" href="https://www.instagram.com/equinetno/">Instagram</a>
                                    </Col>
                                </Row>
                                
                            </Col>
                            {/* <Col sm={4}>
                                <div className="to-empty">
                                    &nbsp;
                                </div>
                            </Col> */}
                            <Col className='ft-top' xs={24} sm={24} md={2}>
                                <div className="to-top" onClick={scrolltoTop} >
                                    <img className="to-top-img" alt="Go to top" src="images/arrow-up.svg" />
                                </div>
                            </Col>
                        </Row>
                        <div className="rights">
                            {/*© 2021 Equinet. {t("allRightsReserved")}.*/}
                            {t("ownership")}
                        </div>
                    </div>

                    {/*<div className="text-center p-3 copy-right">*/}
                    {/*    © Developed by:*/}
                    {/*    <a className="ittrends" href="https://ittrends.no/">ittrends.no</a>*/}
                    {/*</div>*/}
                </footer>
            </div>
        </div>
    );
}