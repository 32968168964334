import React, { useEffect, useState } from "react";
import Api from "../../components/shared/Api";
import "./EqPublicEventSingle.scss";
import { useTranslation } from "react-i18next";
import EqEventSingleDetails from "./eqEventSingleDetails/EqEventSingleDetails";
import EqJoinSingleEvent from "./eqJoinSingleEvent/EqJoinSingleEvent";
import { openNotificationWithIcon, ValidateIsUser } from "../../components/shared/Common";
import { eqNotificationTypes } from "../../components/shared/enums";
import { useLocation, useParams } from "react-router-dom";
import { useAuthState } from "../../components/context/Context";

const EqPublicEventSingle: React.FC = () => {
  // const id = "96940d13-7c63-4b84-42cc-08dcd3f03e5b"; // Hardcoded for now, replace with useParams if dynamic
  // const { id } = useParams<{ id: string }>(); // <-- Get the id from params
  const userDetails: any = useAuthState();
  const isLoggedInUser: boolean = ValidateIsUser(userDetails);

  const location = useLocation(); // <-- Get the location object
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id'); // <-- Get the 'id' from query string

  const [eventData, setEventData] = useState<any | null>(null);
  const [attendUsingName, setAttendUsingName] = useState<any>(userDetails.name);

  const [selectedClasses, setSelectedClasses] = useState<{ [key: string]: string | string[] }>({});
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const [showJoinEvent, setShowJoinEvent] = useState(false);

  useEffect(() => {
    debugger;
    if(id){

      Api.get(`/api/EqPublicEvent/${id}`).then((response) => {
        setEventData(response.data);
      });
    }
    }, [id]);

  // const handleRadioChange = (alternativeId: string, classId: string) => {
  //   debugger;
  //   setSelectedClasses((prev) => ({
  //     ...prev,
  //     [alternativeId]: classId,
  //   }));
    
  // };


  const handleRadioChange = (alternativeId: string, classId: string) => {
    setSelectedClasses((prevSelectedClasses:any) => {
        // Check if the current selection is already the selected item
        if (prevSelectedClasses[alternativeId] === classId) {
          
          const { [alternativeId]: _, ...updatedSelectedClasses } = prevSelectedClasses;
          return updatedSelectedClasses;
          // If yes, unselect it by setting it to null
            // return {
            //     ...prevSelectedClasses,
            //     [alternativeId]: null,
            // };
        }
        // Otherwise, set it to the new selection
        return {
            ...prevSelectedClasses,
            [alternativeId]: classId,
        };
    });
};


  const handleCheckboxChange = (alternativeId: string, classId: string, checked: boolean) => {
    setSelectedClasses((prev) => {
      const selected = prev[alternativeId] || [];
      let updatedSelected = [] as any;
  
      if (checked) {
        // If checked, add the classId to the selected array
        updatedSelected = [...(Array.isArray(selected) ? selected : []), classId];
      } else {
        // If unchecked, filter out the classId
        updatedSelected = Array.isArray(selected) ? selected.filter((id) => id !== classId) : selected;
      }
  
      // Check if updatedSelected is empty; if so, remove the alternativeId from selectedClasses
      if (updatedSelected.length === 0) {
        const { [alternativeId]: _, ...rest } = prev; // Destructure to remove the alternativeId
        return rest;
      } else {
        return {
          ...prev,
          [alternativeId]: updatedSelected,
        };
      }
    });
  };
  

  // Calculate the total price based on selected classes
  const calculateTotalPrice = () => {
    let totalPrice = 0;
    eventData?.alternatives.forEach((alternative: any) => {
      const selected = selectedClasses[alternative.id];
      if (Array.isArray(selected)) {
        // Sum prices for multiple selected classes
        alternative.classList.forEach((classItem: any) => {
          if (selected.includes(classItem.id)) {
            totalPrice += classItem.price;
          }
        });
      } else if (selected) {
        // Sum price for single selected class
        const selectedClass = alternative.classList.find((classItem: any) => classItem.id === selected);
        if (selectedClass) {
          totalPrice += selectedClass.price;
        }
      }
    });
    return totalPrice;
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setLinkCopied(false);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/publicEventSingle?id=${eventData.id}`);
    setLinkCopied(true);
  };


  const handleNextClick = () => {
    setShowJoinEvent(true);
};

const handleBuyRequest = async (data:any) => {
  try {

    let path="api/EqJoinPublicEvent/checkout-guest"
   if(isLoggedInUser){
    path="api/EqJoinPublicEvent/checkout-loggedIn";
   }
    // Replace with your API endpoint and method
    const response = await Api.post(path, data);
    if (response && response.status === 200) {
      window.location.href = response.headers.location;    
    } 
    handleBackClick();
    setSelectedClasses({});
  
    // Optionally handle the result
} catch (error:any) {
  if (error.response.status === 409) {
    // already exist
    openNotificationWithIcon(
      "Error!",
      error.response.data,
      eqNotificationTypes.error
    );
    // setIsEventSubscribed(false);
  }else{
    openNotificationWithIcon(
      t('serverError'),
      t('generalServerError')
  ); console.error('Error Buy Event:', error);
  }

}

};

const handleBackClick = () => {
  setShowJoinEvent(false); // Or any logic to go back
};
  return (
      <div className="eq-container-fluid">
    <div className="eq-public-event-single">

     

{!showJoinEvent ? (
      <EqEventSingleDetails
        eventData={eventData}
        handleAttendUsingName={setAttendUsingName}
        attendUsingName={attendUsingName}
        selectedClasses={selectedClasses}
        handleRadioChange={handleRadioChange}
        handleCheckboxChange={handleCheckboxChange}
        handleNextClick={handleNextClick}
        calculateTotalPrice={calculateTotalPrice}
        isModalOpen={isModalOpen}
        showModal={showModal}
        handleModalClose={handleModalClose}
        handleCopyLink={handleCopyLink}
        linkCopied={linkCopied}
        t={t}
      />
    ) : (
    // Pass the new prop to EqJoinSingleEvent
<EqJoinSingleEvent
  attendUsingName={attendUsingName}
  selectedClasses={selectedClasses}
  eventData={eventData}
  handleBuyRequest={handleBuyRequest}
  handleBackClick={handleBackClick} // Pass the back function
/>
    )}
     </div>
    </div>
  );
};

export default EqPublicEventSingle;
