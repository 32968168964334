import * as React from "react";
import "./EqTrainerActivityBoard.scss";
import { Tabs } from "antd";
import { ApiEndpoints, GetEventMessage, ValidateIsUser } from "../../shared/Common";
import Api from "../../shared/Api";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import { TrainingFilters } from "../../shared/enums";
import TabPane from "antd/lib/tabs/TabPane";
import EqTrainerAllEvents from "../trainerAllEvents/EqTrainerAllEvents";
import EqTrainerTrainings from "../trainerTrainings/EqTrainerTrainings";
import EqTrainerAllSchoolTrainings from "../trainerAllSchoolTrainings/EqTrainerAllSchoolTrainings";
import { Badge, Skeleton } from "antd-v5";
import EqTrainerAllPreviousEvents from "../trainerAllPreviousEvents/EqTrainerAllPreviousEvents";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import RevealList from "../../custom/RevealList/RevealList";
import EqTrainerTrainingsPrevious from "../trainerTrainingsPrevious/EqTrainerTrainingsPrevious";
import EqHorseTaskBoard from "../../custom/eqHorseTaskBoard/EqHorseTaskBoard";
import EqPublicEventBoard from "../../../eqComponents/publicEvents/eqPublicEventBoard/EqPublicEventBoard";
import EqPublicEventPreviousBoard from "../eqPublicEventPreviousBoard/EqPublicEventPreviousBoard";

export interface IFilteredTrainings {
    name: any;
    type: any;
    date: any;
}

const EqTrainerActivityBoard = (props: any) => {
    const userDetails: any = useAuthState();
    const isUserValid: boolean = ValidateIsUser(userDetails);
    const location = useLocation();
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const [message, setMessage] = React.useState("");
    const [isError, setIsError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");
    const [toggle, setToggle] = React.useState(false);

    const [areas, setAreas] = React.useState([]) as any;
    const [weekDays, setWeekDays] = React.useState() as any;
    const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
    const [population, setPopulation] = React.useState([]) as any;
    const [groupPopulation, setGroupPopulation] = React.useState([]) as any;

    // const [showEventList, setShowEventList] = React.useState(true);
    // const [createActivityList, setCreateActivityList] = React.useState([] as Array<any>);
    const [trainingsList, setTrainingsList] = React.useState([]) as any;

    const [trainingsCount, setTrainingsCount] = React.useState(0) as any;
    const [eventCount, setEventCount] = React.useState(0) as any;
    const [schoolTrainingsCount, setSchoolTrainingsCount] = React.useState(0) as any;

    const [isLoading, setIsLoading] = React.useState(true);

    const [filteredTrainingsList, setFilteredTrainingsList] = React.useState(
        {} as IFilteredTrainings
    );
    const [selectedTrainingsList, setSelectedTrainingsList] = React.useState([]) as any;

    const [toggleFilters, setToggleFilters] = React.useState([] as any);
    const [selectedToggle, setSelectedToggle] = React.useState(TrainingFilters.All) as any;

    //initial gets

    const fetchCounts = () => {
        Api.get(ApiEndpoints.GetTrainingEventCount)
            .then((response) => {
                if (response && response.status === 200) {
                    // Assuming the response data is an object with keys for counts
                    const { trainingCount, eventsCount, schoolTrainingCount } = response.data;

                    setTrainingsCount(trainingCount);
                    setEventCount(eventsCount);
                    setSchoolTrainingsCount(schoolTrainingCount);

                    console.log(eventsCount);
                } else {
                    setIsError(true);
                    setErrorMessage(GetEventMessage(response.status));
                }
            })
            .catch((error) => {
                setErrorMessage(GetEventMessage(error.response.status));
            });
    };

    const defaultActiveTab = () => {
        if (trainingsCount >= eventCount && trainingsCount >= schoolTrainingsCount) {
            return "1"; // "trainings" tab has the greatest count
        } else if (eventCount >= trainingsCount && eventCount >= schoolTrainingsCount) {
            return "2"; // "events" tab has the greatest count
        } else {
            return "3"; // "schoolTrainings" tab has the greatest count (or they are all equal)
        }
    };
    // const [activeTab, setActiveTab] = React.useState(defaultActiveTab());
    const [activeTab, setActiveTab] = React.useState("2");

    const [activeEvent, setActiveEvent] = React.useState(null) as any;

    // ...

    const updateTrainingCount = (count: any) => {
        fetchCounts();
        setActiveTab("1");
        setTrainingsCount(count);
    };
    const updateEventCount = (count: any) => {
        fetchCounts();
        setActiveTab("2");
        setEventCount(count);
    };
    const updateSchoolTrainingsCount = (count: any) => {
        fetchCounts();
        setActiveTab("3");
        setSchoolTrainingsCount(count);
    };

    React.useEffect(() => {
        fetchCounts();

        const params = new URLSearchParams(window.location.search);
        var tabKey = params.get("tab");
        if (tabKey !== null) {
            setActiveTab(tabKey);
        }
        // setActiveTab(defaultActiveTab)

        window.scrollTo(0, 0);

        if (trainingsCount >= 0 && eventCount >= 0 && schoolTrainingsCount >= 0) {
            // setActiveTab(defaultActiveTab());
            setIsLoading(false);
        }
    }, [trainingsCount, eventCount, schoolTrainingsCount]);

    return (
        <AnimationDiv>
            <div className={`eq-trainer-activity-board`}>
                <div className="">
                    {isLoading ? (
                        <div>
                            <Skeleton />
                        </div>
                    ) : (
                        <Tabs
                            className="ab-tabs"
                            type="card"
                            activeKey={activeTab}
                            onChange={(key) => {
                                setActiveTab(key);
                                navigate(`?tab=${key}`);
                            }}
                        >
                            <TabPane
                                tab={
                                    <span>
                                        {t("trainings")}{" "}
                                        <Badge
                                            count={trainingsCount}
                                            offset={[0, -15]}
                                            className="tab-badge"
                                            size="default"
                                        ></Badge>
                                    </span>
                                }
                                key="1"
                            >
                                <RevealList>
                                    <EqTrainerTrainings updateCount={updateTrainingCount} />
                                </RevealList>
                            </TabPane>
                            <TabPane
                                tab={
                                    <span>
                                        {t("events")}{" "}
                                        <Badge
                                            count={eventCount}
                                            offset={[0, -15]}
                                            className="tab-badge"
                                            size="default"
                                        ></Badge>
                                    </span>
                                }
                                key="2"
                            >
                                <RevealList>
                                    <EqPublicEventBoard
                                        updateCount={updateEventCount}
                                        activeEvent={activeEvent}
                                    />
    
                                </RevealList>
                            </TabPane>


                            {/* <TabPane
                                tab={
                                    <span>
                                        {t("events")}{" "}
                                        <Badge
                                            count={eventCount}
                                            offset={[0, -15]}
                                            className="tab-badge"
                                            size="default"
                                        ></Badge>
                                    </span>
                                }
                                key="2"
                            >
                                <RevealList>
                                
                                    <EqTrainerAllEvents
                    updateCount={updateEventCount}
                    activeEvent={activeEvent}
                  />
                                </RevealList>
                            </TabPane> */}
                            <TabPane
                                tab={
                                    <span>
                                        {t("schoolTrainings")}{" "}
                                        <Badge
                                            count={schoolTrainingsCount}
                                            offset={[0, -15]}
                                            className="tab-badge"
                                            size="default"
                                        ></Badge>
                                    </span>
                                }
                                key="3"
                            >
                                <RevealList>
                                    <EqTrainerAllSchoolTrainings
                                        updateCount={updateSchoolTrainingsCount}
                                    />
                                </RevealList>
                            </TabPane>

                            <TabPane tab={<span>{t("previousEvents")} </span>} key="4">
                                <RevealList>
                                    <EqPublicEventPreviousBoard
                                        updateCount={updateEventCount}
                                        activeEvent={activeEvent}
                                    />
                                    {/* <EqTrainerAllPreviousEvents
                    onCreateDuplicateEvent={(event: any) => {
                      setActiveTab("2");
                      setActiveEvent(event);
                    }}
                  /> */}
                                </RevealList>
                            </TabPane>

                            <TabPane tab={<span>{t("previousTrainings")} </span>} key="5">
                                <RevealList>
                                    <EqTrainerTrainingsPrevious
                                    // onCreateDuplicateEvent={(event: any) => {
                                    //   setActiveTab("2");
                                    //   setActiveEvent(event);
                                    // }}
                                    />
                                </RevealList>
                            </TabPane>
                            {/*<TabPane tab={t("schoolTrainings")} key="3">*/}
                            {/*    <EqTrainerAllSchoolTrainings></EqTrainerAllSchoolTrainings>*/}
                            {/*</TabPane>*/}

                            {/* <TabPane tab={t("Horse Task")} key="4">
                                  <EqHorseTaskBoard></EqHorseTaskBoard>
                              </TabPane> */}
                        </Tabs>
                    )}
                </div>
            </div>
        </AnimationDiv>
    );
};
export default EqTrainerActivityBoard;
