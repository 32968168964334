import * as React from "react";
import { Document, Page, Text, View, StyleSheet, PDFViewer, Image } from "@react-pdf/renderer";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { getDefaultImage, imageType } from "../../../components/shared/Common";
import { QuestionType } from "../../../components/shared/enums";
import { useEffect } from "react";

// Create styles for the PDF document
const styles = StyleSheet.create({
    page: {
        flexDirection: "column",
        padding: 30,
    },
    heading: {
        fontSize: 20,
        marginBottom: 5,
    },
    subHeading: {
        fontSize: 12,
        marginBottom: 10,
    },
    section: {
        marginBottom: 20,
        textAlign: "left",
    },

    alternativeContainer: {
        border: "1px solid #FF8838",
        marginBottom: 10,
        padding: 5,
    },
    tableContainer: {
        border: "1px solid #f0f0f0",
        marginBottom: 10,
        padding: 10,
    },
    tableHeader: {
        flexDirection: "row",
        backgroundColor: "#ff883873",
        padding: 5,
        fontSize: 13,
        fontWeight: "bold",
        color: "#ff8838",
    },
    tableRow: {
        flexDirection: "row",
        borderBottom: "1px solid #d9d9d9",
        paddingVertical: 8,
    },
    tableHeaderCell: {
        flex: 1,
        textAlign: "left", // Align text to the left
        fontSize: 15, // Set the font size to 15px
        fontWeight: 600, // Set the font weight to 600
    },
    tableCellFirst: {
        flex: 1,
        textAlign: "left",
        flexDirection: "row", // Add this line
        alignItems: "center", // Add this line
        fontSize: 13, // Set the font size to 15px
        fontWeight: 400, // Set the font weight to 600
    },
    avatar: {
        border: "1px solid #f0f0f0",
        width: 30, // Set the width of the avatar image
        height: 30, // Set the height of the avatar image
        marginRight: 5, // Set the right margin for spacing
        borderRadius: "50%", // Make the image round
    },
    tableCell: {
        flex: 1,
        textAlign: "left",
        fontSize: 13, // Set the font size to 15px
        fontWeight: 400, // Set the font weight to 600
        justifyContent: "center",
    },
    row: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    sectionTitle: {
        fontSize: 16,
        fontWeight: "bold",
        marginVertical: 5,
    },
});

// Component to render the PDF document
export interface IEqPublicEventPdfGenerate {
    eventData: any;
    selectedDates: any;
}

const EqPublicEventPdfGenerate = ({ eventData, selectedDates }: IEqPublicEventPdfGenerate) => {
    const { t } = useTranslation();
    const options = { weekday: "long", month: "long", day: "numeric" };

    const event=eventData;
    useEffect(() => {
   
  
    }, [eventData]);

    const getTotalAttendees = () => {
        let attendeeCount = 0;

        event?.alternatives?.forEach((alt: any) => {
            alt?.classList.forEach((classItem: any) => {
                if (classItem.classOrders && classItem.classOrders.length) {
                    attendeeCount = attendeeCount + classItem.classOrders?.length;
                }
            });
        });

        return attendeeCount;
    };

    const renderAnswers = (questions: any, answers: any) => {
        return (
            <>
                {questions?.map((question: any, index: any) => {
                    const correspondingAnswer = answers?.find(
                        (answer: any) => answer?.questionId === question.id
                    );
                    return (
                        <View style={styles.tableCell}>
                            <Text>
                                {correspondingAnswer?.question?.questionType === QuestionType.Text ? (
                                    <Text>{correspondingAnswer?.answerText}</Text>
                                ) : (
                                    correspondingAnswer?.selectedOptions.map(
                                        (option: any, oIdx: number) => (
                                            <Text key={oIdx}>{option.option.optionText}</Text>
                                        )
                                    )
                                )}
                            </Text>
                        </View>
                    );
                })}
            </>
        );
    };

    return (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document>
                <Page style={styles.page}>
                    <Text style={styles.heading}>{event?.title}</Text>
                    <Text style={styles.subHeading}>
                        {" (" +
                            new Date(event?.startDate).toLocaleDateString(
                                t("dateLang"),
                                options as any
                            ) +
                            ")"}
                        {moment(event?.startTime).format("hh:mm A")}
                    </Text>
                    <View style={styles.row}>
                        <Text style={styles.subHeading}>
                            {t("Starting")}:{moment(event?.startDate).format("hh:mm A")}
                        </Text>
                        <Text style={styles.subHeading}>
                            {t("Total Number of Attendees")}:
                            {getTotalAttendees()}
                        </Text>
                    </View>
                    {event?.alternatives?.map((alternative: any, altIndex: any) => (
                        <View key={altIndex} style={styles.alternativeContainer}>
                            <Text style={styles.sectionTitle}>{alternative.customClassName}</Text>

                            {alternative?.classList?.map((classItem: any, classIdx: any) => (
                                <View key={classIdx} style={styles.tableContainer}>
                                    <Text style={styles.sectionTitle}>{classItem.title}</Text>

                                    {/* Table Header */}
                                    <View style={styles.tableHeader}>
                                        <View style={styles.tableHeaderCell}>
                                            <Text>{t("attendee")}</Text>
                                        </View>
                                        {alternative?.questions.map((question: any, index: any) => (
                                            <View style={styles.tableHeaderCell}>
                                                <Text key={index}>{question.questionText}</Text>
                                            </View>
                                        ))}
                                    </View>
                                    {/* Table Rows */}
                                    {classItem?.classOrders?.map(
                                        (classOrder: any, orderIdx: any) => (
                                            <View key={orderIdx} style={styles.tableRow}>
                                                {/* User Avatar and Name */}
                                                <View style={styles.tableCellFirst}>
                                                    <Image
                                                        src={
                                                            classOrder.user?.userImage?.url ||
                                                            getDefaultImage(imageType.partner)
                                                        }
                                                        style={styles.avatar}
                                                    />
                                                    <Text>
                                                        {/* {classOrder.user?.firstName}{" "}
                                                        {classOrder.user?.lastName} */}
                                                        {classOrder.attendUsingName
                                                        ? classOrder.attendUsingName
                                                        : classOrder?.user?.firstName ||
                                                        classOrder?.user?.userName}
                                                    </Text>
                                                </View>

                                                {renderAnswers(
                                                    alternative?.questions,
                                                    classOrder?.answers
                                                )}
                                            </View>
                                        )
                                    )}
                                </View>
                            ))}
                        </View>
                    ))}
                </Page>
            </Document>
        </PDFViewer>
    );
};

export default EqPublicEventPdfGenerate;
