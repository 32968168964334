import * as React from "react";
import "./App.css";
import { EqLayout } from "./components/shared/EqLayout";
import {
  Route,
  Routes,
  BrowserRouter,
  Navigate,
  useLocation,
} from "react-router-dom";
import Logout from "./components/logout/Logout";
import { Home } from "./components/home/Home";
import { removeState } from "./components/shared/LocalStorage";
import { FrontEndPoints } from "./components/shared/Common";
import ConfirmEmail from "./components/confirmEmail/ConfirmEmail";
import ForgotPassword from "./components/forgotPassword/ForgotPassword";
import ResetPassword from "./components/resetPassword/ResetPassword";
import EqTrainerDashboard from "./components/eqTrainer/dashboard/EqTrainerDashboard";
import EqStudentDashboard from "./components/eqStudent/dashboard/EqStudentDashboard";
import EqStudentGroupsBoard from "./components/eqStudent/groupsBoard/EqStudentGroupsBoard";
import EqTrainingsBoard from "./components/eqStudent/trainingsBoard/EqTrainingsBoard";
import EqAdminDashboard from "./components/eqAdmin/dashboard/EqAdminDashboard";

import RoleBasedRoute from "./components/shared/RoleBasedRoute";
import EqTrainerEvent from "./components/eqTrainer/trainerEvent/EqTrainerEvent";
import EqTrainerStudentsBoard from "./components/eqTrainer/studentBoard/EqTrainerStudentsBoard";
import EqFinancialsBoard from "./components/eqTrainer/FinancialsBoard/EqFinancialsBoard";
import EqGroupsBoard from "./components/eqTrainer/GroupsBoard/EqGroupsBoard";
import MessageBoard from "./components/eqTrainer/eqMessageBoard/EqMessageBoard";
import EqComposeMessageBoard from "./components/eqTrainer/ComposeMessageBoard/EqComposeMessageBoard";
import EqStudentComposeMessageBoard from "./components/eqStudent/composeMessageBoard/EqStudentComposeMessageBoard";
import Checkout from "./components/eqStudent/checkout/checkout";
import EqStudentBoard from "./components/eqStudent/studentBoard/EqStudentBoard";
import EqStudentFinancialsBoard from "./components/eqStudent/financialsBoard/EqStudentFinancialsBoard";
import EqProfessionalsBoard from "./components/eqTrainer/professionalsBoard/EqProfessionalsBoard";
import EqEditStudentBoard from "./components/eqTrainer/EditStudentBoard/EqEditStudentBoard";
import EqAdminComposeMessageBoard from "./components/eqAdmin/composeMessageBoard/EqAdminComposeMessageBoard";
import EqAdminMessageBoard from "./components/eqAdmin/messageBoard/EqAdminMessageBoard";
import EqInviteStudentBoard from "./components/eqTrainer/InviteStudentBoard/EqInviteStudentBoard";
import EqViewEvent from "./components/eqStudent/viewEvent/EqViewEvent";
import EqStudentProfileBoard from "./components/eqStudent/profileBoard/EqStudentProfileBoard";
import EqAccountCreated from "./components/stripe/accountCreated/EqAccountCreated";
import EqEventInvitationBoard from "./components/eqStudent/eventInvitationBoard/EqEventInvitationBoard";
import EqTrainerEventInvitationBoard from "./components/eqTrainer/trainerEventInvitationBoard/EqTrainerEventInvitationBoard";
import EqTrainerAllTrainings from "./components/eqTrainer/trainerAllTrainings/EqTrainerAllTrainings";
import { AxiosInterceptor } from "./components/shared/Api";
import EqTrainerTrainings from "./components/eqTrainer/trainerTrainings/EqTrainerTrainings";
import EqTrainerAllEvents from "./components/eqTrainer/trainerAllEvents/EqTrainerAllEvents";
import EqStudentAllTrainings from "./components/eqStudent/studentAllTrainings/EqStudentAllTrainings";
import EqTrainerInvitationBoard from "./components/eqStudent/trainerInvitationBoard/EqTrainerInvitationBoard";
import UpdatePassword from "./components/updatePassword/UpdatePassword";
import EqTrainerProductsBoard from "./components/eqTrainer/trainerProductsBoard/EqTrainerProductsBoard";
import EqTrainerActivityBoard from "./components/eqTrainer/trainerActivityBoard/EqTrainerActivityBoard";
import EqJoinEvent from "./components/eqStudent/eqEventJoin/EqJoinEvent";
import CheckoutGuest from "./components/eqStudent/checkoutGuest/checkoutGuest";
import EqEventPrint from "./components/eqTrainer/EventPrintView/eqEventPrint";
import EqSchoolDashboard from "./components/eqSchool/dashboard/EqSchoolDashboard";
import EqSchoolActivityBoard from "./components/eqSchool/schoolActivityBoard/EqSchoolActivityBoard";
import EqSchoolProductsBoard from "./components/eqSchool/schoolProductsBoard/EqSchoolProductsBoard";
import EqSchoolStudentsBoard from "./components/eqSchool/studentBoard/EqSchoolStudentsBoard";
import EqSchoolTrainersBoard from "./components/eqSchool/trainerBoard/EqSchoolTrainersBoard";
import EditProfile from "./components/editProfile/EditProfile";
import EqSchoolProfileBoard from "./components/eqSchool/schoolProfileBoard/EqSchoolProfileBoard";
import EqRidingSchools from "./components/eqSchool/eqRidingSchools/EqRidingSchools";
import EqEventHolderDashboard from "./components/eqEventHolder/dashboard/EqEventHolderDashboard";
import EqEventHolderActivityBoard from "./components/eqEventHolder/eventHolderActivityBoard/EqEventHolderActivityBoard";
import EqEventHolderProfileBoard from "./components/eqEventHolder/eventHolderprofileBoard/EqEventHolderProfileBoard";
import EqSubscriptionSettings from "./components/eqAdmin/subscriptionSettings/EqSubscriptionSettings";
import Features from "./components/features/Features";
import EqFeatureRidingSchool from "./components/eqStudent/eqFeatureRidingSchool/EqFeatureRidingSchool";
import EqFeatureEventHolder from "./components/eqEventHolder/eqFeatureEventHolder/EqFeatureEventHolder";
import ProfessionalConnections from "./components/eqStudent/eqProfessionalConnections/ProfessionalConnections";
import EqStudentScheduleList from "./components/eqStudent/EqStudentScheduleList/EqStudentScheduleList";
import EqStudentProductBoard from "./components/eqStudent/eqStudentProductBoard/EqStudentProductBoard";
import EqFeatureTrainer from "./components/eqTrainer/eqFeatureTrainer/EqFeatureTrainer";
import EqApproveProfessionals from "./components/eqAdmin/approveProfessionals/EqApproveProfessionals";
import EqCreateSubscription from "./components/eqAdmin/createSubscription/EqCreateSubscription";
import EqSubscriptionProduct from "./components/eqAdmin/subscriptionProduct/EqSubscriptionProduct";
import EqSubscriptionSetting from "./components/eqAdmin/subscriptionSetting/EqSubscriptionSetting";

import { AnimatePresence } from "framer-motion";
import EqTrainerProfileBoard from "./components/eqTrainer/trainerProfileBoard/EqTrainerProfileBoard";
import EqSubscriptions from "./components/eqAdmin/subscriptions/EqSubscriptions";
import { Contact } from "./components/contact/Contact";
import EqEmailReportBoard from "./components/eqAdmin/EmailReportBoard/EqEmailReportBoard";
import EqTrainerInvitationsBoard from "./components/eqTrainer/trainerInvitationsBoard/EqTrainerInvitationsBoard";
import EqSchoolInvitationsBoard from "./components/eqSchool/schoolInvitationsBoard/EqSchoolInvitationsBoard";
import EqTrainerRequestsBoard from "./components/eqTrainer/trainerRequestsBoard/EqTrainerRequestsBoard";
// import EqHorseBoard from "./components/custom/EqHorseBoard/EqHorseBoard";
// import EqHorseBoard from "./components/custom/EqHorseBoard/EqHorseBoard";
import EqHorseTaskBoard from "./components/custom/eqHorseTaskBoard/EqHorseTaskBoard";
import EqHorseBoard from "./components/custom/eqHorseBoard/EqHorseBoard";
import EqLogin from "./components/eqLogin/EqLogin";
import EqRegister from "./components/eqRegister/EqRegister";
import EqForgetPassword from "./components/eqForgetPassword/EqForgetPassword";
import EqResetPassword from "./components/eqResetPassword/EqResetPassword";
import EqPublicEventList from "./eqComponents/eqPublicEventList/EqPublicEventList";
import EqPublicEventSingle from "./eqComponents/eqPublicEventSingle/EqPublicEventSingle";
import EqPublicEventSucess from "./eqComponents/eqPublicEventSucess/EqPublicEventSucess";
import EqPublicBookingConfirmation from "./eqComponents/eqPublicBookingConfirmation/EqPublicBookingConfirmation";
import EqPublicEventListPage from "./eqComponents/eqPublicEventListPage/EqPublicEventListPage";

function App(props: any) {
  const location = useLocation;
  const handleLogout = () => {
    removeState();
  };

  React.useEffect(() => { }, []);

  return (
    <BrowserRouter>
      <AxiosInterceptor>
        <AnimatePresence>
          <Routes>
            <Route element={<EqLayout />}>


            <Route
                path={FrontEndPoints.publicEventSingle}
                element={<EqPublicEventSingle />}
              />

              <Route
                path={FrontEndPoints.allPublicEvents}
                // element={<EqTrainerEvent />}
                element={<EqPublicEventListPage />}
                
              />
                {/* <Route
                path={FrontEndPoints.allOldEvents}
                element={<EqTrainerEvent />}
              /> */}
            <Route
                path={FrontEndPoints.publicEventBookingConfirm}
                element={<EqPublicBookingConfirmation />}
              />
              <Route path={FrontEndPoints.all} element={<Home />} />


              <Route path={FrontEndPoints.contact} element={<Contact />} />
              {/* <Route path={FrontEndPoints.login} element={<Login />} /> */}
              <Route path={FrontEndPoints.login} element={<EqLogin />} />


              <Route path={FrontEndPoints.features} element={<Features />} />
              {/* Feature */}
              <Route
                path={FrontEndPoints.ridingSchoolFeatures}
                element={<EqFeatureRidingSchool />}
              />
              <Route
                path={FrontEndPoints.eventHolderFeatures}
                element={<EqFeatureEventHolder />}
              />
              <Route
                path={FrontEndPoints.trainerFeatures}
                element={<EqFeatureTrainer />}
              />
              {/* <Route path={FrontEndPoints.horseEnthusiastFeatures} element={<Features />} /> */}

              {/* <Route path={FrontEndPoints.login} element={<Login />} /> */}
              <Route path={FrontEndPoints.logout} element={<Logout />} />
              <Route
                path={FrontEndPoints.confirmEmail}
                element={<ConfirmEmail />}
              />
              <Route
                path={FrontEndPoints.forgotPassword}
                element={<EqForgetPassword />}
              />
              <Route
                path={FrontEndPoints.resetPassword}
                element={<EqResetPassword />}
              />
              <Route
                path={FrontEndPoints.updatePassword}
                element={<UpdatePassword />}
              />

              <Route
                path={FrontEndPoints.RidingSchools}
                element={<EqRidingSchools />}
              />

              <Route
                path={FrontEndPoints.trainer.allProfessionalsBoard}
                element={<EqProfessionalsBoard />}
              />

              <Route
                path={FrontEndPoints.trainer.profileBoard}
                element={<EqTrainerProfileBoard />}
              />

              <Route
                path={FrontEndPoints.eventHolder.profileBoard}
                element={<EqEventHolderProfileBoard />}
              />

              <Route
                path={FrontEndPoints.school.profileBoard}
                element={<EqSchoolProfileBoard />}
              />
              {/* Trainer */}
         
              <Route
                path={FrontEndPoints.student.viewEvent}
                element={<EqViewEvent />}
              />
              <Route
                path={FrontEndPoints.student.joinEvent}
                element={<EqJoinEvent />}
              />
              <Route path={FrontEndPoints.register} element={<EqRegister />} />

              <Route
                path={FrontEndPoints.guestCheckout}
                element={<CheckoutGuest />}
              />

              <Route
                path={FrontEndPoints.trainer.eventPrint}
                element={<EqEventPrint />}
              />
            </Route>

            <Route element={<RoleBasedRoute />}>
           
            <Route path={FrontEndPoints.eventSuccess} 
                 element={<EqPublicEventSucess />} />
          


              <Route
                path={FrontEndPoints.trainer.accountCreated}
                element={<EqAccountCreated />}
              />

              <Route
                path={FrontEndPoints.admin.subscriptionProduct}
                element={<EqSubscriptionProduct />}
              />

              <Route
                path={FrontEndPoints.admin.subscriptionSetting}
                element={<EqSubscriptionSetting />}
              />

              <Route
                path={FrontEndPoints.admin.subscription}
                element={<EqSubscriptions />}
              />

              <Route
                path={FrontEndPoints.admin.subscriptionSettings}
                element={<EqSubscriptionSettings />}
              />
              <Route
                path={FrontEndPoints.admin.createSubscription}
                element={<EqCreateSubscription />}
              />
              <Route
                path={FrontEndPoints.admin.emailReport}
                element={<EqEmailReportBoard />}
              />

              <Route
                path={FrontEndPoints.student.editProfile}
                element={<EditProfile />}
              />
              <Route
                path={FrontEndPoints.student.dashboard}
                element={<EqStudentDashboard />}
              />
              <Route
                path={FrontEndPoints.student.allTrainings}
                element={<EqStudentAllTrainings />}
              />
              <Route
                path={FrontEndPoints.student.allProfessionalsBoard}
                element={<EqProfessionalsBoard />}
              />
              <Route
                path={FrontEndPoints.student.horses}
                element={<EqHorseBoard />}
              />
              {/* <Route
          <Route element={<RoleBasedRoute />}>
            <Route
              path={FrontEndPoints.trainer.accountCreated}
              element={<EqAccountCreated />}
            />
            <Route
              path={FrontEndPoints.student.editProfile}
              element={<EditProfile />}
            />
            <Route
              path={FrontEndPoints.student.dashboard}
              element={<EqStudentDashboard />}
            />
            <Route
              path={FrontEndPoints.student.allTrainings}
              element={<EqStudentAllTrainings />}
            />
            <Route
              path={FrontEndPoints.student.allProfessionalsBoard}
              element={<EqProfessionalsBoard />}
            />
            <Route
              path={FrontEndPoints.student.horses}
              element={<EqHorseBoard />}
            />
            
            <Route
            path={FrontEndPoints.student.taskList}
            element={<EqHorseTaskBoard  />}
            />
            {/* <Route
            path={FrontEndPoints.student.schoolTrainings}
            element={<EqStudentAllSchoolTrainings />}
            /> */}
              <Route
                path={FrontEndPoints.student.schedule}
                element={<EqStudentScheduleList />}
              />
              <Route
                path={FrontEndPoints.student.productBoard}
                element={<EqStudentProductBoard />}
              />
              <Route
                path={FrontEndPoints.student.publicEvents}
                element={<EqPublicEventList />}
              />
              
              <Route
                path={FrontEndPoints.student.trainingBoard}
                element={<EqTrainingsBoard />}
              />
              <Route
                path={FrontEndPoints.student.studentBoard}
                element={<EqStudentBoard />}
              />
              <Route
                path={FrontEndPoints.student.paymentBoard}
                element={<EqStudentFinancialsBoard />}
              />
              <Route
                path={FrontEndPoints.student.profileBoard}
                element={<EqStudentProfileBoard />}
              />
              <Route
                path={FrontEndPoints.student.trainerInvitationsBoard}
                element={<EqTrainerInvitationBoard />}
              />
              <Route
                path={FrontEndPoints.student.eventInvitationsBoard}
                element={<EqEventInvitationBoard />}
              />
              <Route
                path={FrontEndPoints.student.connection}
                element={<ProfessionalConnections />}
              />

              <Route
                path={FrontEndPoints.trainer.trainerEventInvitationsBoard}
                element={<EqTrainerEventInvitationBoard />}
              />
              <Route
                path={FrontEndPoints.admin.dashboard}
                element={<EqAdminDashboard />}
              />
              {/* <Route
              path={FrontEndPoints.admin.approveTrainers}
              element={<EqAdminHome />}
            /> */}
              <Route
                path={FrontEndPoints.admin.approveProfessionals}
                element={<EqApproveProfessionals />}
              />
              <Route
                path={FrontEndPoints.admin.messageBoard}
                element={<EqAdminMessageBoard />}
              />
              <Route
                path={FrontEndPoints.admin.composeMessage}
                element={<EqAdminComposeMessageBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.dashboard}
                element={<EqTrainerDashboard />}
              />
              <Route
                path={FrontEndPoints.trainer.editTrainer}
                element={<EditProfile />}
              // element={<EqEditTrainer />} edit trainer profile
              />
              {/* <Route
              path={FrontEndPoints.trainer.createEvent}
              element={<CreateActivity />}
            /> */}
              <Route
                path={FrontEndPoints.trainer.studentBoard}
                element={<EqTrainerStudentsBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.inviteStudent}
                element={<EqInviteStudentBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.editStudentBoard}
                element={<EqEditStudentBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.eventBoard}
                element={<EqTrainerEvent />}
              />
              <Route
                path={FrontEndPoints.trainer.trainings}
                element={<EqTrainerTrainings />}
              />
              <Route
                path={FrontEndPoints.trainer.allTrainings}
                element={<EqTrainerAllTrainings />}
              />
              <Route
                path={FrontEndPoints.trainer.allEvents}
                element={<EqTrainerAllEvents />}
              />

              <Route
                path={FrontEndPoints.trainer.productBoard}
                element={<EqTrainerProductsBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.activityBoard}
                element={<EqTrainerActivityBoard />}
              />
              {/* <Route
              path={FrontEndPoints.trainer.search}
              element={<EqEventsMain />}
            /> */}
              <Route
                path={FrontEndPoints.trainer.professionalsBoard}
                element={<EqProfessionalsBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.horses}
                element={<EqHorseBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.invitations}
                element={<EqTrainerInvitationsBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.taskList}
                element={<EqHorseTaskBoard />}
              />

              <Route
                path={FrontEndPoints.trainer.requests}
                element={<EqTrainerRequestsBoard />}
              />
              <Route
                path={FrontEndPoints.student.groupBoard}
                element={<EqStudentGroupsBoard />}
              />
              <Route
                path={FrontEndPoints.student.composeMessage}
                element={<EqStudentComposeMessageBoard />}
              />
              <Route
                path={FrontEndPoints.student.messageBoard}
                element={<MessageBoard />}
              // element={<EqStudentMessageBoard />}

              />
              <Route
                path={FrontEndPoints.student.checkout}
                element={<Checkout />}
              />
              <Route
                path={FrontEndPoints.trainer.groupsBoard}
                element={<EqGroupsBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.messageBoard}
                element={<MessageBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.composeMessage}
                element={<EqComposeMessageBoard />}
              />
              <Route
                path={FrontEndPoints.trainer.paymentBoard}
                element={<EqFinancialsBoard />}
              />

              {/* Event Holder Routes */}
              <Route
                path={FrontEndPoints.eventHolder.dashboard}
                element={<EqEventHolderDashboard />}
              />
              <Route
                path={FrontEndPoints.eventHolder.editEventHolder}
                element={<EditProfile />}
              />

              <Route
                path={FrontEndPoints.eventHolder.activityBoard}
                element={<EqEventHolderActivityBoard />}
              />

              {/* school Routes */}

              <Route
                path={FrontEndPoints.school.groupsBoard}
                element={<EqGroupsBoard />}
              />
              <Route
                path={FrontEndPoints.school.editSchool}
                element={<EditProfile />}
              />
              <Route
                path={FrontEndPoints.school.dashboard}
                element={<EqSchoolDashboard />}
              />
              <Route
                path={FrontEndPoints.school.activityBoard}
                element={<EqSchoolActivityBoard />}
              />

              <Route
                path={FrontEndPoints.school.productBoard}
                element={<EqSchoolProductsBoard />}
              />

              <Route
                path={FrontEndPoints.school.studentBoard}
                element={<EqSchoolStudentsBoard />}
              />
              <Route
                path={FrontEndPoints.school.trainerBoard}
                element={<EqSchoolTrainersBoard />}
              />

              <Route
                path={FrontEndPoints.school.paymentBoard}
                element={<EqFinancialsBoard />}
              />
              <Route
                path={FrontEndPoints.school.horses}
                element={<EqHorseBoard />}
              />
              <Route
                path={FrontEndPoints.school.taskList}
                element={<EqHorseTaskBoard />}
              />
              <Route
                path={FrontEndPoints.school.invitations}
                element={<EqSchoolInvitationsBoard />}
              />
              <Route
                path={FrontEndPoints.school.requests}
                element={<EqTrainerRequestsBoard />}
              />
              <Route
                path={FrontEndPoints.school.messageBoard}
                element={<MessageBoard />}
              />
            </Route>

            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </AnimatePresence>
      </AxiosInterceptor>
    </BrowserRouter>
  );
}

export default App;
