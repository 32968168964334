import React, { useEffect, useState } from 'react';
import './EqButton.scss';
import {
     ButtonProps
} from 'antd';
import { Button } from 'antd-v5';


export interface IEqButton extends ButtonProps {
    //changeFunc?: (e: any) => void;
    classNames?: string;
    centered?: any;
    bordered?: any;
    type?: any;
}

const EqButton = ({classNames, bordered, centered, ...rest }: IEqButton) => {

    //const onChange = (e: any) => {
    //    changeFunc(e);
    //}
// make is span instead of div
    return (
        <span className={`eqButton ${centered ? "centered" : "" } ${classNames}`} >
            <Button {...rest} className={`${bordered ? "bordered" : ""} ${classNames}`}/>
        </span>
    );
}

export default EqButton;
