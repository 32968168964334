import './EqMultiDatePicker.scss';
import React from 'react';
import { useState } from "react";
import DatePicker from "react-multi-date-picker";
import moment from 'moment';

export interface IMultiDatePickerOptions {
    defaultValue?: any;
    placeHolder?: string;
    classNames?: string;
    onChangeFunc: (selectedDates: any) => void;
}

const EqMultiDatePicker = ({  defaultValue, placeHolder, classNames, onChangeFunc }: IMultiDatePickerOptions) => {

    const dateFormat = 'DD/MM/YYYY HH:mm';
    var dateArr = [] as any;

    const [values, setValues] = useState(defaultValue);
    const change = (value: any) => {
        //console.log(value);
        dateArr = [];
        if (value && value.length > 0) {
            dateArr = value.map((date: any) => {
                return new Date(date);
            });
        } 
        //console.log(dateArr);
        onChangeFunc(dateArr);
        setValues(value);
    }

    React.useEffect(() => {
        setValues(defaultValue);
    }, [defaultValue]);
    return (
        <>
            <div className={`eq-date-pick ${classNames ? classNames : ""}`} >
                <DatePicker
                    multiple
                    format={dateFormat}
                    placeholder={placeHolder}
                    value={values}
                    onChange={change}
                />
                </div>
            </>
        );
}
export default EqMultiDatePicker;
// {/*defaultValue={defaultValue ? moment(defaultValue).format(dateFormat) : null as any}*/}