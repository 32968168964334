import React, { useState } from "react";
import "./EqForgetPassword.scss";
import Api from "../shared/Api";
import {
    ApiEndpoints,
    FrontEndPoints,
    GetRegistrationMessageKey,
} from "../shared/Common";
import { useNavigate } from "react-router-dom";
import {
    Form,
    Typography,
    Divider,
} from "antd";
import {
    UserOutlined,
} from "@ant-design/icons";
import { EqInput } from "../custom/eqInput/EqInput";
import { EqSpace } from "../custom/eqSpace/EqSpace";
import EqButton, { IEqButton } from "../custom/eqButton/EqButton";
import { useTranslation } from "react-i18next";


const EqForgetPassword = () => {
    const { t } = useTranslation();
  
    const [loginError, setIsError] = useState("");
    const [isPasswordChanged, setIsPasswordChanged] = useState("");


   
    const [email, setEmail] = useState("");
    // const [isValidUser, setIsValidUser] = useState(false);
  
    const navigate = useNavigate();
    // const location = useLocation();

    const { Title } = Typography;
    // const { state } = useLocation();

    const tailLayout = {
        wrapperCol: { offset: 4, span: 16 },
    };




    React.useEffect(() => {


    }, []);





    const validateAndSend = async () => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        
        if (!email.length || !email.length) {
            setIsError("emailError");
            return;

        } else if (!emailRegex.test(email)) {
            setIsError("emailError");
            return;
    } 

        setIsError("");

        Api.post(ApiEndpoints.UserAccount.ResetPasswordWithToken, { email: email }).then(() => {
            setIsError("");
            setIsPasswordChanged("forgetPassOk");
        }, (err) => {
            
            setIsPasswordChanged("");
            setIsError((GetRegistrationMessageKey(err.response.status)));
            
        });
        
        
 
    };




    const buttonProps: IEqButton = {
        //changeFunc: validateUser,
        classNames: "eq-sign-btn",
    };


    const onFinishFailed = () => { };


    return (
        <div className="eq-forgot-password">
            <div className="eq-form-wrapper">
                {/* <Image className="eq-logo" src="logo.png" /> */}
                <Title className=" ant-col ant-col-20 ant-col-offset-2 custom-title">
                    {t("ForgotPassword")}
                </Title>
                <Form
                    {...tailLayout}
                    layout="vertical"
                    size="small"
                    // onFinish={validateUser}
                    onFinish={validateAndSend}
                    onFinishFailed={onFinishFailed}
                >
                    <p className="label">{t("email")}</p>
                    <div className="eq-form-item-wrapper">
                        <EqInput
                            type="email"
                            placeholder={t("emailAddress")}
                            prefix={<UserOutlined />}
                            value={email}
                            onChange={(e: any) => setEmail(e.target.value)}
                        />
                    </div>
                
                    <div className="eq-form-item-wrapper">
                        {loginError && (
                            <span id="merrorMsg" className="error-message">
                                {t(loginError)}
                            </span>
                        )}
                        {isPasswordChanged && (
                            <span id="merrorMsg" className="success-message">
                                {t(isPasswordChanged)}
                            </span>
                        )}
                    </div>

             
                    <div className="eq-form-item-wrapper">
                        <EqSpace direction="vertical" style={{ padding: "1% 8%" }}>

                        </EqSpace>
                    </div>
                    <div className="eq-form-item-wrapper">
                        <EqButton
                            type="primary"
                            htmlType="submit"
                            centered="centered"
                            {...buttonProps}
                        >
                            {t("send")}
                        </EqButton>
                    </div>
                  
                    <Divider plain>{t("or")}</Divider>

                    <div className="eq-form-item-wrapper">
                        <EqSpace
                            direction="vertical"
                            style={{
                                width: "100%",
                                padding: "1% 8%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                        >
                            <div className="eq-anchor-register">
                                {t("RememberPassword?")}{(" ")}
                                <a
                                                            href={FrontEndPoints.login}

                                    // onClick={()=>{
                                    //     navigate(FrontEndPoints.login);
                                    // }}
                                // href={FrontEndPoints.register}
                                className="eq-register"
                                >
                                    {t("logIn")}
                                </a>
                            </div >
                        </EqSpace>
                    </div>
                </Form>
            </div>
        </div>
    );
};
export default EqForgetPassword
