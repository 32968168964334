import * as React from "react";
import "./EqStudentAllTrainings.scss";
import { Row, Col, notification, Empty, Skeleton } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsUser,
  FrontEndPoints,
  getEmailFromToken,
  openNotificationWithIcon,
  GenericMessage,
} from "../../shared/Common";
import Api from "../../shared/Api";
import "./EqStudentAllTrainings.scss";
import { useTranslation, Trans } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import {
  EqDisciplineType,
  EqEventAttendeeStatus,
  EqEventUserType,
  EqSlotStatusEnum,
  StudentTrainingFilters,
  eqNotificationTypes,
} from "../../shared/enums";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import EqStudentActivityCollapse from "../../eqTrainer/eqStudentActivityCollapse/EqStudentActivityCollapse";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import RevealList from "../../custom/RevealList/RevealList";
import { Ro } from "react-flags-select";
import { FloatButton, Popconfirm } from "antd-v5";
import { useState } from "react";

export interface IFilteredTrainings {
  previous: any;
  available: any;
  joined: any;
  joinedProducts: any;
  unPaid: any;
}

const EqStudentAllTrainings = (props: any) => {
  const userDetails: any = useAuthState();
  const isUserValid: boolean = ValidateIsUser(userDetails);
  const location = useLocation();
  const currentUserKey = getEmailFromToken();
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);

  const [areas, setAreas] = React.useState([]) as any;
  const [population, setPopulation] = React.useState([]) as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;

  // const [showEventList, setShowEventList] = React.useState(true);
  // const [createActivityList, setCreateActivityList] = React.useState([] as Array<any>);
  const [studentHorsesList, setStudentHorsesList] = React.useState({});

  const [trainingsList, setTrainingsList] = React.useState([]) as any;

  const [filteredTrainingsList, setFilteredTrainingsList] = React.useState(
    {} as IFilteredTrainings
  );
  const [selectedTrainingsList, setSelectedTrainingsList] = React.useState(
    null
  ) as any;

  const [toggleFilters, setToggleFilters] = React.useState([] as any);
  const [selectedToggle, setSelectedToggle] = React.useState(
    StudentTrainingFilters.Available
  ) as any;
  const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([] as any);

  const [credits, setCredits] = useState(null);

  React.useEffect(() => {
    fetchUserCredits()
    getAreas();
    createToggleFilters();
    GetAllTrainingLessonsByConnections();
    GetAllStudentHorses();
    window.scrollTo(0, 0);

    if (location.state) {
      if (
        location.state.prevPath == FrontEndPoints.student.eventInvitationsBoard
      ) {
        setSelectedToggle(toggleFilters[1]);
      } else if (location.state.prevPath == FrontEndPoints.login) {
        setSelectedToggle(StudentTrainingFilters.unPaid);
      }
    } else {
      setSelectedToggle(StudentTrainingFilters.Available);
    }
  }, []);

  //initial gets
  const fetchUserCredits = async () => {
    try {
      const response = await Api.get(ApiEndpoints.UserCredit.GetAll, {
        params: { unused: true }
      });

      setCredits(response.data);
    } catch (error:any) {
      setIsError(true)
      setMessage(GenericMessage(error.response.status));
      
    } 
  };


  const getAreas = () => {
    Api.get(ApiEndpoints.Location.GetUserAreaOptions).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  // Toggle Filters
  const createToggleFilters = () => {
    var filters = [];
    for (var x in StudentTrainingFilters) {
      if (isNaN(Number(x)) == false) {
        filters.push({ key: Number(x), value: StudentTrainingFilters[x] });
      }
    }
    setToggleFilters(filters);
    console.log("butnv", filters);
    setSelectedToggle(filters[0]);
  };

  const accessTogglerProps: IEqToggle = {
    items: toggleFilters.map((filter: any) => ({
      key: filter.key,
      value: t(filter.value), // Translate the filter label
    })),
    defaultSelectedValue: selectedToggle,
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: string) => {
  
      let filter: number = +value;
      setSelectedToggle(filter);

      // if (filter === StudentTrainingFilters.Available) {
      //   setSelectedTrainingsList(filteredTrainingsList.available);
      // } else if (filter === StudentTrainingFilters.MyTrainings) {
      //   setSelectedTrainingsList(filteredTrainingsList.joined);
      // } else if (filter === StudentTrainingFilters.Previous) {
      //   setSelectedTrainingsList(filteredTrainingsList.previous);
      // } else if (filter === StudentTrainingFilters.unPaid) {
      //   setSelectedTrainingsList(filteredTrainingsList.unPaid);
      // }
      //  else if (filter === StudentTrainingFilters.MyProducts) {
      //   setSelectedTrainingsList(filteredTrainingsList.joinedProducts);
      // }
    },
  };

  /////

  const trainerEventDefaults = (e: any) => {
    GetAllTrainingLessonsByConnections();

  };
  

  const removeEventAttendee = (data: IEqEventData) => {
    //console.log(data);
    Api.post(ApiEndpoints.StudentCancelJoinedTraining, { Key: data.key }).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(data);
          GetAllTrainingLessonsByConnections();
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handlePayWithCredit = (data: any) => {
    //console.log(data);
    Api.post(ApiEndpoints.StudentTraining.PayWithCredit, { Key: data.key }).then(
      (response) => {
        if (response && response.status == 200) {
      
          GetAllTrainingLessonsByConnections();
          fetchUserCredits();
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetAllStudentHorses = () => {
    Api.get(ApiEndpoints.GetAllHorses).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          var data = response.data ? [...response.data] : new Array<any>();
          var studentHorsesListOptions = [] as any;

          if (data && data.length > 0) {
            let horseListObj = [] as any;
            let useKey = "";
            [...data].map((h: any) => {
              useKey = h.userId;
              let horseObj = {
                key: h.id,
                value: h.name,
                label: h.name,
              };
              horseListObj.push(horseObj);
            });
            let studentHorsesObj = {
              key: useKey,
              horseList: horseListObj,
            };
            studentHorsesListOptions.push(studentHorsesObj);
          }
          setStudentHorsesList(studentHorsesListOptions);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetAllTrainingLessonsByConnections = () => {
    // let path = ApiEndpoints.GetAllStudentTrainings;
   let path =  ApiEndpoints.StudentTraining.GetAllTrainingLessonsByConnections;
    Api.get(path).then(
      (response) => {
        if (response && response.status === 200) {
          const data = response.data || new Array<any>();
          setTrainingsList(data);

          const formattedData = formatTrainingListData(data);
          setFilteredTrainingsList(formattedData);

          if (location.state) {
            if (
              location.state.prevPath ==
              FrontEndPoints.student.eventInvitationsBoard
            ) {
              setSelectedTrainingsList(formattedData.joined);
            } else if (location.state.prevPath == FrontEndPoints.login) {
              setSelectedTrainingsList(formattedData.unPaid);
            }
          } else {
            setSelectedTrainingsList(formattedData.available);
          }
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const formatTrainingListData = (data: any): IFilteredTrainings => {
    // only prev trainings Student has Joined
    // const prevActivities = data.filter((opt: any) => opt.startDate < todayTime);

    const prevActivities = data.filter((opt: any) => 
      opt.startDate < todayTime &&
      opt.trainingTimes[0].attendees?.some(
        (attendee: any) =>
          attendee.key === currentUserKey &&
          attendee.userStatus === EqEventAttendeeStatus.Accept
      )
    );


    const unPaid = data.filter((item: any) =>
      item.trainingTimes[0].attendees?.some(
        (attendee: any) =>
          attendee.key === currentUserKey &&
          attendee.userStatus === EqEventAttendeeStatus.Accept &&
          attendee.slotStatus === EqSlotStatusEnum.Pending
      )
    );

    const latestActivities = data.filter(
      (opt: any) => opt.startDate > todayTime
    );
    const available = latestActivities.filter((item: any) => {
      const isUserJoined =
        item.trainingTimes[0].attendees?.some(
          (attendee: any) =>
            attendee.key === currentUserKey &&
            (attendee.userStatus === EqEventAttendeeStatus.Accept ||
              attendee.userStatus === EqEventAttendeeStatus.Requested || 
              attendee.userStatus === EqEventAttendeeStatus.Waiting ||
              attendee.userStatus === EqEventAttendeeStatus.Cancelled)
        ) ?? false;

      return !isUserJoined;
    });

    const joined = latestActivities.filter((item: any) => {
      const isUserJoined =
        item.trainingTimes[0].attendees?.some(
          (attendee: any) =>
            attendee.key === currentUserKey &&
            (attendee.userStatus === EqEventAttendeeStatus.Accept ||
              attendee.userStatus === EqEventAttendeeStatus.Requested|| 
              attendee.userStatus === EqEventAttendeeStatus.Waiting||
              attendee.userStatus === EqEventAttendeeStatus.Cancelled)
        ) ?? false;

      return isUserJoined;
    });

    // Joined Products

    const joinedProducts = joined.filter(
      (opt: any) => opt.disciplineType === EqDisciplineType.RidingCourse
    );

    return {
      previous: prevActivities,
      available: available,
      joined: joined,
      joinedProducts: joinedProducts,
      unPaid: unPaid,
    };
  };

  const removeItem = (data: any) => {
    if (trainingsList != undefined) {
      var updatedList =
        trainingsList != undefined
          ? trainingsList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setTrainingsList(updatedList);

      trainingsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  const handleOnCheck = (order: any) => {
    setFilteredTrainingsList((prevOrders: any) => {
      const list = [...prevOrders.unPaid];
      const updatedList = list.map((item) =>
        item.id === order.id ? { ...item, selected: !item.selected } : item
      );

      const updatedSelectedCheckboxes = updatedList.filter((x) => x.selected);
      setSelectedCheckboxes(updatedSelectedCheckboxes);

      return {
        ...prevOrders,
        unPaid: updatedList,
      };
    });
  };

  const checkIfSameOwner = (selectedTrainings: any) => {
    const ownerOrganizer = selectedTrainings[0].organizers.find(
      (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
    );
    const firstOwnerId = ownerOrganizer.user.email;
    const isSameOwner = selectedTrainings.every((training: any) => {
      const ownerOrganizer = training.organizers.find(
        (organizer: any) => organizer.eventUserType === EqEventUserType.Owner
      );
      return ownerOrganizer.user.email === firstOwnerId;
    });

    return isSameOwner;
  };

  const CreateCheckoutSessionForMultipleLessons = () => {
    let result = selectedCheckboxes.map((lesson: any) => {
      return { key: lesson.key };
    });
   
    Api.post(ApiEndpoints.StudentTrainingStripeCheckout.CreateForMultiple, result)
      .then((response) => {
        console.log(response);
        window.location.href = response.data.sessionUrl;
      })
      .catch((error) => {
        openNotificationWithIcon(
          "Error!",
          "Unable to Create checkout",
          eqNotificationTypes.error
        );
      });
  };


  return (
    // <AnimationDiv>
    <div className={`eq-all-student-trainings-board`}>
      <div className="eq-container-fluid">
        <Row className="eq-heading-row" align="middle" justify="start">
          <Col className="eq-heading-col" flex="auto">
            <h3 className="eq-heading">{t("allTrainings")}</h3>
          </Col>
          <Col>
            <Row>
              <EqToggle {...accessTogglerProps} />
            </Row>
          </Col>
        </Row>

        {/* Training List Component Removed and Created EqStudentActivityCollapse for student */}
        {/* <Row>
            <Skeleton loading={!selectedTrainingsList} active></Skeleton>
            <Col span={24}>
              {selectedTrainingsList && (
                <>
                  {selectedTrainingsList.length > 0 ? (
                    <>
                      {selectedTrainingsList.map((item: any, index: number) => (
                        <RevealList>
                          <EqStudentActivityCollapse
                            key={index}
                            event={item}
                            areas={areas}
                            population={population}
                            groupPopulation={groupPopulation}
                            trainerStudents={trainerStudents}
                            studentHorsesList={studentHorsesList}
                            removeAttendeeFunc={removeEventAttendee}
                            onCreateOrUpdateActivity={trainerEventDefaults}
                            onCheck={() => handleOnCheck(item)}
                          ></EqStudentActivityCollapse>
                        </RevealList>
                      ))}
                    </>
                  ) : (
                    <Empty></Empty>
                  )}
                </>
              )}
            </Col>
          </Row> */}

        {selectedToggle == StudentTrainingFilters.Available ? (
          <>
            {filteredTrainingsList?.available?.length > 0 ? (
              <>
                {filteredTrainingsList.available.map(
                  (item: any, index: number) => (
                    <RevealList>
                      <EqStudentActivityCollapse
                        key={index}
                        event={item}
                        areas={areas}
                        population={population}
                        groupPopulation={groupPopulation}
                        trainerStudents={trainerStudents}
                        studentHorsesList={studentHorsesList}
                        removeAttendeeFunc={removeEventAttendee}
                        onCreateOrUpdateActivity={trainerEventDefaults}
                        userCredits={credits}
                        onPayWithCredit={(event:any)=>{
                          handlePayWithCredit(event);
                        }}
                      ></EqStudentActivityCollapse>
                    </RevealList>
                  )
                )}
              </>
            ) : (
              <Empty></Empty>
            )}
          </>
        ) : selectedToggle == StudentTrainingFilters.MyTrainings ? (
          <>
            {filteredTrainingsList?.joined?.length > 0 ? (
              <>
                {filteredTrainingsList.joined.map(
                  (item: any, index: number) => (
                    <RevealList>
                      <EqStudentActivityCollapse
                        key={index}
                        event={item}
                        areas={areas}
                        population={population}
                        groupPopulation={groupPopulation}
                        trainerStudents={trainerStudents}
                        studentHorsesList={studentHorsesList}
                        removeAttendeeFunc={removeEventAttendee}
                        onCreateOrUpdateActivity={trainerEventDefaults}
                        userCredits={credits}
                        onPayWithCredit={(event:any)=>{
                          handlePayWithCredit(event);
                        }}
                      ></EqStudentActivityCollapse>
                    </RevealList>
                  )
                )}
              </>
            ) : (
              <Empty></Empty>
            )}
          </>
        ) : selectedToggle == StudentTrainingFilters.Previous ? (
          <>
            {filteredTrainingsList?.previous?.length > 0 ? (
              <>
                {filteredTrainingsList.previous.map(
                  (item: any, index: number) => (
                    <RevealList>
                      <EqStudentActivityCollapse
                        key={index}
                        event={item}
                        areas={areas}
                        population={population}
                        groupPopulation={groupPopulation}
                        trainerStudents={trainerStudents}
                        studentHorsesList={studentHorsesList}
                        removeAttendeeFunc={removeEventAttendee}
                        onCreateOrUpdateActivity={trainerEventDefaults}
                        userCredits={credits}
                        onPayWithCredit={(event:any)=>{
                          handlePayWithCredit(event);
                        }}
                      ></EqStudentActivityCollapse>
                    </RevealList>
                  )
                )}
              </>
            ) : (
              <Empty></Empty>
            )}
          </>
        ) : selectedToggle == StudentTrainingFilters.unPaid ? (
          <Row>
            <Col span={24}>
              <Row justify="end" className="action-all-btn">
                {selectedCheckboxes.length > 1 &&
                  checkIfSameOwner(selectedCheckboxes) && (
                    <Popconfirm
                      // className="action-all-btn"
                      title="Payment"
                      description="Pay for All the selected Products"
                      onConfirm={CreateCheckoutSessionForMultipleLessons}
                      // onCancel={Oncancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <FloatButton description={t("payAll")} shape="square" />
                    </Popconfirm>
                  )}
              </Row>
            </Col>

            <Col span={24}>
              {filteredTrainingsList?.unPaid?.length > 0 ? (
                <>
                  {filteredTrainingsList.unPaid.map(
                    (item: any, index: number) => (
                      <RevealList>
                        <EqStudentActivityCollapse
                          key={index}
                          event={item}
                          areas={areas}
                          population={population}
                          groupPopulation={groupPopulation}
                          trainerStudents={trainerStudents}
                          studentHorsesList={studentHorsesList}
                          removeAttendeeFunc={removeEventAttendee}
                          onCreateOrUpdateActivity={trainerEventDefaults}
                          onCheck={() => handleOnCheck(item)}
                          
                          userCredits={credits}
                          onPayWithCredit={(event:any)=>{
                            handlePayWithCredit(event);
                          }}
                        ></EqStudentActivityCollapse>
                      </RevealList>
                    )
                  )}
                </>
              ) : (
                <Empty></Empty>
              )}
            </Col>
          </Row>
        ) : (
          ""
        )}
      </div>
    </div>
    // </AnimationDiv>
  );
};
export default EqStudentAllTrainings;
