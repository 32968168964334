import React, { useEffect, useState } from "react";
import "./EqGroupsBoard.scss";
import { Row, Col, Input } from "antd";
import EqSelect from "../../custom/eqSelect/EqSelect";
import EqButton from "../../custom/eqButton/EqButton";
// import EqCreateGroup from "../eqCreateGroup/EqCreateGroup";
import EqGroupList from "../eqGroupList/EqGroupList";
import { ISelectProps } from "../../shared/Interfaces";
import Api from "../../shared/Api";
import { ApiEndpoints, FrontEndPoints, GetEventMessage } from "../../shared/Common";
import moment from "moment";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import { IEqGroupDto } from "../../shared/interfaces/IEqGroup";
import { eqLoadingState, EqLocationType, EqUserType } from "../../shared/enums";
import EqGroupCreateOrEdit from "../eqGroupCreateOrEdit/EqGroupCreateOrEdit";
import { useAuthState } from "../../context/Context";
import Item from "antd/lib/list/Item";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";
import { Empty, Skeleton } from "antd-v5";

const EqGroupsBoard = () => {
    const userDetails: any = useAuthState();
    const location = useLocation();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();

    const [addNew, setAddNew] = useState(false);

    // initial Gets
    const [loading, setLoading] = React.useState(eqLoadingState.initial);
    const [areas, setAreas] = React.useState([]) as any;
    const [trainerData, setTrainerData] = React.useState([]) as any;
    const [weekDays, setWeekDays] = React.useState([]) as any;
    const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
    const [studentHorsesList, setStudentHorsesList] = React.useState([]);
    const [groupPopulation, setGroupPopulation] = React.useState([]) as any;

    const [messageString, setMessageString] = useState("");
    const [delMessage, setDelMessage] = useState("");
    const [show, setShow] = useState(true);
    const [groups, setGroups] = useState({
        allGroups: [],
        filteredGroups: [],
    }) as any;
    const [studentsList, setStudentsList] = React.useState<
        Array<IStudentGroupDto>
    >([]);
    const [searchStudentsList, setSearchStudentsList] = React.useState([] as any);
    var sortedList: Array<IEqGroupDto>;
    const toggleShow = () => {

        setShow(!show);
    };
    const { Search } = Input;
    const [searchStr, setSearchStr] = useState("");
    const showAddNew = () => {
        setAddNew(true);
    };
    const searchClicked = (e: any) => {
        if (searchStr != "") {
            var filteredResult = groups.allGroups.filter((g: any) => {
                return g?.groupName?.includes(searchStr);
            });
            setGroups({ ...groups, filteredGroups: filteredResult });
        } else {
            setGroups({ ...groups, filteredGroups: groups.allGroups });
        }
    };
    const sortAscending = (unsorted: Array<IEqGroupDto>) => {
        try {
            const sorted = [...unsorted].sort((obj1, obj2) => {
                if ((obj1?.groupName).toLowerCase() > (obj2?.groupName).toLowerCase()) {
                    return 1;
                } else if (
                    (obj1?.groupName).toLowerCase() < (obj2?.groupName).toLowerCase()
                ) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return sorted;
        } catch {
            return unsorted;
        }
    };

    const sortDescending = (unsorted: Array<IEqGroupDto>) => {
        try {
            const sorted = [...unsorted].sort((obj1, obj2) => {
                if ((obj1?.groupName).toLowerCase() < (obj2?.groupName).toLowerCase()) {
                    return 1;
                } else if (
                    (obj1?.groupName).toLowerCase() > (obj2?.groupName).toLowerCase()
                ) {
                    return -1;
                } else {
                    return 0;
                }
            });
            return sorted;
        } catch {
            return unsorted;
        }
    };

    const onSortChange = (event: any) => {
        switch (event) {
            case "Sort A-Z":
                sortedList = sortAscending(groups.filteredGroups);
                setGroups({ ...groups, filteredGroups: sortedList });
                break;
            case "Sort Z-A":
                sortedList = sortDescending(groups.filteredGroups);
                setGroups({ ...groups, filteredGroups: sortedList });
                break;
            default:
                sortedList = sortAscending(groups.filteredGroups);
                setGroups({ ...groups, filteredGroups: sortedList });
        }
    };

    const cancelFunction = (e: any) => {
        setAddNew(false);
    };

    const sendFunction = (data: any) => {
        setAddNew(false);
        GetGroups();

        if (location.state) {
            if (location.state.prevPath == FrontEndPoints.trainer.trainings) {
                navigate(FrontEndPoints.trainer.trainings, {
                    state: { prevPath: location.pathname },
                });
            }
        }
    };

    const getGroupIcon = (type: any) => {
        switch (type) {
            case 1:
                return [
                    <span className="eq-btn-icon">
                        <img alt="" src="/home.svg" />
                    </span>,
                ];
            case 2:
                return [
                    <span className="eq-btn-icon">
                        <img alt="" src="/stable.svg" />
                    </span>,
                ];
            case 3:
                return [
                    <span className="eq-btn-icon">
                        <img alt="" src="/user-friends.svg" />
                    </span>,
                ];
            case 4:
                return [
                    <span className="eq-btn-icon">
                        <img alt="" src="/award.svg" />
                    </span>,
                ];
            case 5:
                return [
                    <span className="eq-btn-icon">
                        <img alt="" src="/horse.svg" />
                    </span>,
                ];
            default:
                return [
                    <span className="eq-btn-icon">
                        <img alt="" src="/user-friends.svg" />
                    </span>,
                ];
        }
    };

    const editFunc = (data: IEqGroupDto) => {
        GetGroups();
    };

    const delFunc = (data: IEqGroupDto) => {
        setDelMessage("");
        Api.post(ApiEndpoints.groupDelete, { key: data.groupKey }).then(
            (response) => {
                if (response && response.status == 200) {
                    var e = response.data ? response.data : "";
                    var msg =
                        "Group cannot be deleted because of the event" +
                        e.event?.title +
                        " on " +
                        new Date(e.event?.startDate).toDateString() +
                        " at " +
                        new Date(e.event?.startTime).toTimeString() +
                        " by " +
                        e.user?.firstName;
                    setDelMessage(msg);
                } else if (response && response.status == 204) {
                    GetGroups();
                } else {
                    setMessageString("Not found");
                }
            },
            (err) => {
                setMessageString("Not found");
            }
        );
    };

    const GetGroups = () => {
        setMessageString("");
        setLoading(eqLoadingState.loading);
        Api.get(ApiEndpoints.GetTrainerGroups).then(
            (response) => {
                setLoading(eqLoadingState.loadingCompleted);
                if (response && response.status == 200) {

                    var groups = [] as Array<any>;
                    response.data?.map((d: any, index: number) => {
                        // var noMessage=d.messages?.filter((opt: any)=>opt.messageRecipients?.recipient?.username==userDetails.name);
                        groups.push(
                            {
                                horses: d.horses,
                                key: d.key,
                                groupKey: d.key,
                                groupIcon: getGroupIcon(d.type),
                                groupName: d.name,
                                type: d.type,
                                color: d.color,
                                message: d.message,
                                userGroups: d.userGroups,
                                numberOfMessages: d.messages?.length,
                                numberOfTrainings: d.events?.length,
                                // sessionFrom: (d.events != undefined && d.events.length > 0 ? moment(d.events[0].startTime, "hh:mm") : "00:00"),
                                // sessionTo:  "00:00",
                                sessionFrom:
                                    d.events != undefined && d.events.length > 0
                                        ? moment(d.events[0].startTime).format("hh:mm")
                                        : "00:00",
                                sessionTo:
                                    d.events != undefined && d.events.length > 0
                                        ? moment(d.events[0].startTime)
                                            .minutes(d.events[0].duration)
                                            .format("hh:mm")
                                        : "00:00",
                                display: index == 0 ? true : false,
                                students: d.joinedUserGroups?.map((s: any, index: number) => {
                                    return {
                                        email: s.user?.email,
                                        firstName: s.user?.firstName,
                                        lastName: s.user?.lastName,
                                        userName: s.user?.userName,
                                        userType: s.user?.professionType,
                                        phoneNumber: s.user?.phoneNumber,
                                        discipline: s.user?.discipline,
                                        locationKey: s.user?.locationKey,
                                        location: s.user?.location,
                                        locations: s.user?.locations
                                            ?.filter(
                                                (opt: any) => opt.location?.type == EqLocationType.Home
                                            )
                                            .shift(),
                                        payment: s.user?.payment ? s.user?.payment : "-",
                                        groups: s.user.groups,
                                        selected: false,
                                    };
                                }),
                            }
                        );
                    });
                    console.log("all groups",[...groups])
                    setGroups({ allGroups: [...groups], filteredGroups: [...groups] });
                } else {
                    setMessageString("Not found");
                }
            },
            (err) => {
                setLoading(eqLoadingState.loadingCompleted);
                setMessageString("Not found");
            }
        );
    };

    const sortProps: ISelectProps = {
        border: false,
        allowClear: false,
        classNames: "eq-select-sort",
        defaultSelectedValue: "Sort A-Z",
        changeFunc: onSortChange,
        options: [
            { key: "Sort A-Z", value: "Sort A-Z" },
            { key: "Sort Z-A", value: "Sort Z-A" },
        ],
    };

    const createGroupProps = {
        header: t("createGroup"),
        groupHeading: "Please select type of group",
        nameHeading: t("groupName"),
        messageHeading: "Message",
        colorAssign: t("groupColor"),
        cancelFunc: cancelFunction,
        sendFunc: sendFunction,
        studentsList: studentsList,
        searchStudentsList: searchStudentsList,
    };

    const GetAllStudents = () => {

        setMessageString("");
        let path = "";
        if (location.pathname == "/school/groups") {
            path = ApiEndpoints.GetSchoolStudentsAndGroups;
        } else if (location.pathname == "/trainer/groups") {
            path = ApiEndpoints.getTrainerStudents
        }

        Api.get(path).then(
            (response) => {
                if (response && response.status == 200) {
                    var students = new Array<IStudentGroupDto>();
                    var studentsData = [] as any;

                    response.data?.map((d: any) => {
                        if (d.userType == EqUserType.Student) {
                            students.push(d);

                            var name =
                                d.user != undefined
                                    ? d.user.firstName
                                        ? d.user.firstName
                                        : d.user.userName
                                    : d.group != undefined
                                        ? d.group.name
                                        : "";
                            var newKey = d.key;

                            studentsData.push({
                                key: newKey,
                                value: name,
                                label: name,
                            });
                        }
                    });
                    setStudentsList([...students]);

                    setSearchStudentsList([...studentsData]);
                } else {
                    setMessageString("Not found");
                }
            },
            (err) => {
                setMessageString("Not found");
            }
        );
    };
    useEffect(() => {
        // improve that get specific Data
        GetTrainerProfile();
        getAreas();
        getWeekDays();
        GetTrainerStudents();
        getGroupPopulation();
        GetAllStudents();
        GetGroups();

        // Check Prev Path
        if (location.state) {
            if (
                addNew == false &&
                (location.state.prevPath == FrontEndPoints.trainer.trainings ||
                    location.state.prevPath == FrontEndPoints.trainer.dashboard)
            ) {
                showAddNew();
            }
        }
        // sortAscending(groups);
    }, []);

    const GetTrainerProfile = () => {
        Api.get(ApiEndpoints.GetTrainerProfile).then(
            (response) => {
                if (response && response.status == 200) {


                    setTrainerData(response.data);
                } else {

                    GetEventMessage(response.status);
                }
            },
            (error) => {
                GetEventMessage(error.response.status)
            }
        );
    };
    const getAreas = () => {
        Api.get(ApiEndpoints.Location.GetUserAreaOptions).then(
            (response) => {
                if (response && response.status == 200) {

                    setAreas(response.data);
                } else {
                    GetEventMessage(response.status);
                }
            },
            (error) => {
                GetEventMessage(error.response.status)
            }
        );
    };
    const getWeekDays = () => {
        Api.get(ApiEndpoints.eventGetWeekDays).then(
            (response) => {
                if (response && response.status == 200) {

                    setWeekDays(response.data);
                } else {
                    GetEventMessage(response.status)
                }
            },
            (error) => {
                GetEventMessage(error.response.status)
            }
        );
    };

    const GetTrainerStudents = () => {
        Api.get(ApiEndpoints.GetTrainerStudents).then(
            (response) => {
                if (response && response.status == 200) {
                    var studentHorsesListOptions = [] as any;
                    var trainerStudents = response.data;
                    setTrainerStudents(response.data);
                    [...trainerStudents].map((item: any) => {
                        let horseListObj = [] as any;

                        if (item.user.horses.length > 0) {
                            [...item.user.horses].map((h: any) => {
                                let horseObj = {
                                    key: h.id,
                                    value: h.name,
                                    label: h.name,
                                };
                                horseListObj.push(horseObj);
                            });
                        }
                        if (item.trainer.horses.length > 0) {
                            [...item.trainer.horses].map((h: any) => {
                                let horseObj = {
                                    key: h.id,
                                    value: h.name,
                                    label: h.name,
                                };
                                horseListObj.push(horseObj);
                            });
                        }

                        let studentHorsesObj = {
                            key: item.key,
                            horseList: horseListObj,
                        };

                        studentHorsesListOptions.push(studentHorsesObj);
                    });
                    setStudentHorsesList(studentHorsesListOptions);
                    
                } else {
                    GetEventMessage(response.status)
                }
            },
            (error) => {
                GetEventMessage(error.response.status);
            }
        );
    };

    const getGroupPopulation = () => {
        Api.get(ApiEndpoints.eventGetGroupPopulation).then(
            (response) => {
                if (response && response.status == 200) {

                    setGroupPopulation(response.data);
                    //console.table(population);
                } else {
                    GetEventMessage(response.status);
                }
            },
            (error) => {
                GetEventMessage(error.response.status);
            }
        );
    };

    return (
        <>
            <AnimationDiv>

                <div className="eq-gb-container">
                    <Row className="eq-gb-heading-row" align="bottom">
                        <Col className="eq-gb-col" flex="auto" sm={5} xs={4}>
                            <h3 className="eq-gb-heading">{t("groups")}</h3>
                        </Col>
                        <Col className="eq-gb-col" flex="auto" sm={19} xs={20}>
                            <Row className="eq-gb-student-header" justify="end" align="bottom">
                                <Col className="eq-gb-student-sort hidden-sm">
                                    <EqSelect {...sortProps}></EqSelect>
                                </Col>
                                <Col className="eq-gb-student-search" xs={14} sm={14} md={6}>
                                    <Search
                                        placeholder={t("inputSearchText")}
                                        onChange={(e: any) => setSearchStr(e.target.value)}
                                        onSearch={searchClicked}
                                    />
                                </Col>

                                <Col>
                                    <div className="eq-gb-student-btn">
                                        <EqButton
                                            type="button"
                                            onClick={showAddNew}
                                            classNames="btn-background-orange"
                                            disabled={loading == eqLoadingState.loading}
                                        >
                                            <img alt="" src="/plus.svg" />
                                            <span className="hidden-sm">
                                                <span className="icon-space"></span>{t("addNew")}
                                            </span>
                                        </EqButton>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <div>
                        {
                            addNew && (
                                <>
                                    <div className="eq-cg-collapse">
                                        <Row
                                            className="eq-cg-collapse-header"
                                            justify="start"
                                            align="middle"
                                            onClick={toggleShow}
                                        >
                                            <Col className="eq-cg-collapse-header-name" flex="auto">
                                                {/* {groupIcon} */}
                                                {t("createGroup")}
                                            </Col>
                                            <Col flex="auto">
                                                <div >
                                                    <Row justify="end">
                                                        <Col
                                                            className="group-header-arrow"
                                                            xs={24}
                                                            sm={24}
                                                            md={1}
                                                        >
                                                            {show ? (
                                                                <span className="gh-arrow-icon">
                                                                    <img alt="" src="/caret-up.svg" />
                                                                </span>
                                                            ) : (
                                                                <span className="gh-arrow-icon">
                                                                    <img alt="" src="/caret-down.svg" />
                                                                </span>
                                                            )}
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Col>
                                        </Row>

                                        {show && (
                                            <EqGroupCreateOrEdit
                                                {...createGroupProps}
                                            ></EqGroupCreateOrEdit>
                                        )}
                                    </div>
                                </>
                            )
                            // <EqCreateGroup {...createGroupProps}></EqCreateGroup>
                        }
                    </div>
                    <Row className="del-msg">
                        <Col>{delMessage}</Col>
                    </Row>
                    <div>
                        {loading == eqLoadingState.loading ?
                            <div className="loading"><Skeleton loading={loading === eqLoadingState.loading} active></Skeleton></div> :
                            <>
                                {groups && groups.filteredGroups && groups.filteredGroups.length ? <EqGroupList
                                    creatorUser={trainerData}
                                    trainerStudents={trainerStudents}
                                    studentHorsesList={studentHorsesList}
                                    weekDays={weekDays}
                                    areas={areas}
                                    groupPopulation={groupPopulation}

                                    groupList={[...groups.filteredGroups]}
                                    editGroupFunc={editFunc}
                                    removeGroupFunc={delFunc}
                                    studentsList={studentsList}
                                    searchStudentsList={searchStudentsList}

                                ></EqGroupList> :
                                    <Empty description={t("noGroupAvailable")}></Empty>
                                }</>
                        }
                    </div>
                </div>

            </AnimationDiv>
        </>
    );
};

export default EqGroupsBoard;
