import * as React from "react";
import "./EqTrainerInvitationBoard.scss";
import Api from "../../shared/Api";
import { ApiEndpoints } from "../../shared/Common";
import EqTrainerInvite from "./EqTrainerInvite/EqTrainerInvite";
import modal from "antd/lib/modal";
import { ExclamationCircleOutlined } from "@ant-design/icons";

export interface IEqTrainerInvitationBoard {
  onloadActivity?: any;
 }
const EqTrainerInvitationBoard = ({onloadActivity}:IEqTrainerInvitationBoard) => {
  const [messageString, setMessageString] = React.useState("");
  const [eventInvites, setEventInvites] = React.useState([] as any);
  const [pendingInvites, setPendingInvites] = React.useState([] as any);
  const [trainerPendingInvites, setTrainerPendingInvites] = React.useState(
    [] as any
  );
  const [acceptedInvites, setAcceptedInvites] = React.useState([] as any);
  const [steps, setSteps] = React.useState(1 as number);
  const [eventDetail, setEventDetail] = React.useState({} as any);



  function AcceptTrainerInvite(e: any) {
    // e.stopPropagation();
    // setStatus("accept");
    Api.post(ApiEndpoints.AcceptTrainerInvite, { key: e }).then(
      (response) => {
        if (response && response.status == 200) {
            GetAllPendingTrainerInvites();
          setSteps(1);
        } else {
          setMessageString("");
        }
      },
      () => {
        setMessageString("");
      }
    );
  }

  function RejectTrainerInvite(e: any) {
    


    // e.stopPropagation();
    // setStatus("accept");
    Api.post(ApiEndpoints.RejectTrainerInvite, { key: e }).then(
      (response) => {
        if (response && response.status == 200) {
            GetAllPendingTrainerInvites();
          setSteps(1);
        } else {
          setMessageString("");
        }
      },
      () => {
        setMessageString("");
      }
    );
  }
  const confirmTrainerPayment = (trainer: any) => {
    modal.confirm({
      width: 1000,
      title: "Terms And Conditions",
      icon: <ExclamationCircleOutlined />,
      content: (
        <p style={{ whiteSpace: "pre-wrap" }}>  <div dangerouslySetInnerHTML={{ __html: trainer?.trainerTerms }} /></p>
      ),
     
      okText: "Accept",
      // cancelText: "Pay Later",
      onOk: () => AcceptTrainerInvite(trainer.key),
      // onCancel: () => AcceptProductOrderInvite(productOrder),
    });
  };
  const acceptClickedFun = (trainer: any) => {   
    
    confirmTrainerPayment(trainer)
    // AcceptTrainerInvite(e.key)
    // setSteps(3);
    console.log(trainer);
}

const rejectClickedFun = (e: any) => {
        
    RejectTrainerInvite(e.key)
    console.log(e);
    // setSteps(5);
}


//   };

  const GetAllPendingTrainerInvites = () => {
    setMessageString("");
    Api.get(ApiEndpoints.GetAllPendingTrainerInvites).then(
      (response) => {
        if (response && response.status == 200) {
          // var pendingInvites = [] as any;
          var data = response?.data ? response?.data : ([] as any);
          setTrainerPendingInvites(data);
          if(data.length)
          {
              onloadActivity(true);
          }
          else{
              onloadActivity(false);

          }
          //data.map((d: any, index: number) => {
          //    pendingInvites.push(d);
          //});
          console.log(data);
        } else {
          setMessageString("Not found");
        }
      },
      () => {
        setMessageString("Not found");
      }
    );
  };



  React.useEffect(() => {
    
    // GetAllPendingStudentEventInvites();
    GetAllPendingTrainerInvites();
    // getAcceptedInvitations();
    console.log(eventInvites);
  }, []);
  return (
    <>
      <div className="eq-trainerInvitationBoard-container">
        {/* <Row className="eq-trainerInvitationBoard-row">
          <Col className="eq-trainerInvitationBoard-col">
            <div className="eq-trainerInvitationBoard-heading">
              Trainer Invitations
            </div>
          </Col>
        </Row> */}

        <div className="">
          {trainerPendingInvites && trainerPendingInvites.length > 0
            ? [...trainerPendingInvites].map((item: any, index: number) => (
                <EqTrainerInvite 
                acceptClicked={acceptClickedFun}
                rejectClicked={rejectClickedFun}
                key={index} trainer={item}></EqTrainerInvite>
              ))
            : ""}
        </div>
      </div>
    </>
  );
};
export default EqTrainerInvitationBoard;
