import { Col, Row, Typography } from "antd-v5";
import React from "react";
import { useTranslation } from "react-i18next";
import "./EqPublicEventSucess.scss";
import AsyncImage from "../../components/custom/asyncImage/AsyncImage";
import EqButton from "../../components/custom/eqButton/EqButton";
import { useLocation, useNavigate } from "react-router-dom"; // Import useNavigate

const { Text } = Typography;

const EqPublicEventSucess = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate(); // Initialize navigate

    const { eventId, eventTitle } = location.state || {}; // Retrieve eventId and eventTitle from state

    // Handle navigation to the event page
    const NavigateToEvent = () => {
        if (eventId) {
            // Navigate to the event page with the eventId as a query parameter
            navigate(`/publicEventSingle?id=${eventId}`);
        }
    };

    return (
        <div className="eq-container-fluid ">
            <Row className="eq-public-sucess">
                <Col>
                    <span>
                        <img style={{ width: "20px" }} alt="" src="icons/party_popper.svg" />
                    </span>
                    <span><strong> {t("Hooray!")}</strong></span>
                    <span>
                        <img style={{ width: "20px" }} alt="" src="icons/party_popper.svg" />
                    </span>
                </Col>
                <Text> {t("eventLiveText1")} 🐴.</Text>

                {/* <Progress percent={70} showInfo={false} className="progress-bar" /> */}
                <AsyncImage className="confirm-image" src="publicEvent/Two-horse-run.png" />

                <Text>{t("eventLiveText2")}</Text>
                <Text>{t("eventLiveText3")}</Text>

                <EqButton
                    style={{ margin: "15px" }}
                    type="primary"
                    onClick={NavigateToEvent} // Use the navigation function on button click
                >
                    {t("viewEvent")}
                </EqButton>
            </Row>
        </div>
    );
};

export default EqPublicEventSucess;
