import * as React from "react";
import "./EqTrainerTrainings.scss";
import { Row, Col, Empty, Skeleton, Input } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsTrainer,
  ValidateIsUser,
  FrontEndPoints,
  GenericMessage,
  openNotificationWithIcon,
} from "../../shared/Common";
import Api from "../../shared/Api";
import "./EqTrainerTrainings.scss";
import { useTranslation, Trans } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import CreateActivity from "../createActivity/CreateActivity";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqActivityTrainingList from "../eqActivityTrainingList/EqActivityTrainingList";
import CreateOrEditPrivateTraining from "../createOrEditPrivateTraining/CreateOrEditPrivateTraining";
import { Spin } from "antd-v5";

export interface IEqTrainerTrainings {
  updateCount?: (count: number) => void;
}
const EqTrainerTrainings = (
  { updateCount }: IEqTrainerTrainings
) => {
  const { Search } = Input;

  const userDetails: any = useAuthState();
  const isUserValid: boolean = ValidateIsUser(userDetails);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [toggle, setToggle] = React.useState(false);
  const [createActivityHeader, setCreateActivityHeader] = React.useState(
    []
  ) as any;
  const [message, setMessage] = React.useState("");
  const [areas, setAreas] = React.useState([]) as any;
  const [trainerData, setTrainerData] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState([]) as any;

  const [population, setPopulation] = React.useState([]) as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;

  const [showEventList, setShowEventList] = React.useState(true);
  const [createActivityShow, setCreateActivityShow] = React.useState(false);
  const [createActivityList, setCreateActivityList] = React.useState(
    [] as Array<any>
  );
  const [trainingsList, setTrainingsList] = React.useState(null) as any;

  const [studentHorsesList, setStudentHorsesList] = React.useState([]);
  const [trainerHorsesList, setTrainerHorsesList] = React.useState({});

  const [loading, setLoading] = React.useState(false);



  //filter
  // const [selectedTraining, setSelectedTraining] = React.useState([] as any);
  // const [trainingOptions, setTrainingOptions] = React.useState([] as any);

  const [trainingData, setTrainingData] = React.useState([] as any);
  const [studentsName, setStudentsName] = React.useState([] as any)
  const [search, setSearch] = React.useState({
    searchVal: "",
  });
  const location = useLocation();

  const GetAllTrainerHorses = () => {

    Api.get(ApiEndpoints.GetAllHorses).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainerHorsesList(data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerStudents = () => {
// 
    // updated Api 
    // Api.get(ApiEndpoints.GetTrainerStudents).then(
    Api.get(ApiEndpoints.TrainerStudents.GetTrainerStudentsGroupDto).then(
      (response) => {
        
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var trainerStudents = response.data;
          setIsError(false);

          // console.log(userDetails);
          setMessage("");
          setTrainerStudents(response.data);
          // console.log(trainerHorsesList);
          [...trainerStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.trainer.horses.length > 0) {
              [...item.trainer.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };


  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };


  const GetTrainerProfile = () => {
    Api.get(ApiEndpoints.GetTrainerProfile).then(
      (response) => {
        if (response && response.status == 200) {

          setIsError(false);
          setMessage("");
          setTrainerData(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getAreas = () => {
    Api.get(ApiEndpoints.Location.GetUserAreaOptions).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  React.useEffect(() => {
    // console.log("TrainerTrainings ", location);
    console.log("Training", GetTrainerPrivateTrainings)
    // improve that get specific Data
    GetTrainerProfile();



    GetAllTrainerHorses();
    // eventGetTrainerStudents();
    GetTrainerPrivateTrainings();

    GetTrainerStudents();
    getAreas();
    getWeekDays();
    getPopulation();
    getGroupPopulation();

    window.scrollTo(0, 0);
    if (location.state) {
      if (
        location.state.prevPath == FrontEndPoints.trainer.dashboard ||
        location.state.prevPath == FrontEndPoints.trainer.groupsBoard
      ) {
        setCreateActivityShow(true);
        // setTimeout(() => {
        //   onHandleAddActivity();
        // }, 10000);
      }
    }
  }, []);

  const handleOnCreateTraining = (data: any) => {
    setCreateActivityList([]);
    // console.log(createActivityList);
    const trainings = [...trainingsList];
    data.forEach((e: any) => {
      trainings.push(e);
    });
    setTrainingsList(trainings);


    onHandleAddActivity();


    if (updateCount) {
      updateCount(trainingsList.length);
    }
  };

  const trainerEventDefaults = (e: any, i: any) => {
    setCreateActivityList([]);
    GetTrainerPrivateTrainings();
    // console.log(createActivityList);
    onHandleAddActivity();
  };
  const toggleEventList = () => {
    setShowEventList(!showEventList);
  };
  const editTraining = (data: IEqEventData) => {
    navigate(FrontEndPoints.trainer.createEvent, { state: data });
    // history.push({ pathname: FrontEndPoints.trainer.createEvent, });
    // history.push(FrontEndPoints.trainer.createEvent, { event: data });
  };

  const handleOnUpdateTraining = (newData: any, oldData: any) => {
    if (trainingsList != undefined) {
      const trainings = [...trainingsList];
      var foundIndex = trainings.findIndex((x) => x.key == oldData.key);
      trainings[foundIndex] = newData;
      setTrainingsList(trainings);
      // setCreateActivityList([]);
      // console.log(createActivityList);
      // const trainings = [...trainingsList];
      // newData.forEach((e: any) => {
      //   trainings.push(e);
      // });
      // setTrainingsList(trainings);
      // onHandleAddActivity();
    }

    if (updateCount) {
      updateCount(trainingsList.length); // Replace 'newCount' with the actual count
    }
  };

  const removeTraining = (
    trainingData: any,
    refund?: boolean) => {

      setLoading(true);
      let path =
      ApiEndpoints.TrainerTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(trainingData);
          // GetTrainerPrivateTrainings();
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      setLoading(false);

      },
      (error) => {
        setIsError(true);
        setLoading(false);

        setErrorMessage(GetEventMessage(error.response.status));
        openNotificationWithIcon(
          GenericMessage(error.response.status),
          "Error occurred while Deleting. Please try again or contact administrator."
        );
      }
    );

    if (updateCount) {
      updateCount(trainingsList.length);
    }
  };




  const GetTrainerPrivateTrainings = async () => {
    
    setErrorMessage("");
    // let path =
    //   ApiEndpoints.TrainerTraining.GetTrainerTrainings;
      let path =
      ApiEndpoints.TrainerTraining.GetTrainerTrainingsOfMonths
    // let path =
    // ApiEndpoints.TrainerTraining.GetTrainerTrainings + `?ExcludeDisciple=${EqDisciplineType.Open}`;

    Api.get(path).then(
      (response) => {


        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();
          setTrainingsList(data);
          console.log(data)
          console.log("GetTrainerPrivateTrainings",data);
          // onloadSendCount(data.length)
          // setTrainingsList(data);

          //   const today = new Date();
          // today.setHours(0, 0, 0, 0);
          // const todayTime = today.getTime();
          // const eventsFromToday = data.filter((event) => event.startDate >= todayTime);
          // const eventsCountFromToday = eventsFromToday.length;
          // onloadSendCount(eventsCountFromToday);
          // console.log(data);
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const removeItem = (data: any) => {
    if (trainingsList != undefined) {
      var updatedList =
        trainingsList != undefined
          ? trainingsList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setTrainingsList(updatedList);

      trainingsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  const handleCancelActivity = (e: any) => {
    // console.log(createActivityList);

    if (e > -1) {
      // only splice array when item is found
      const dataArr = [...createActivityList];

      // dataArr.splice(e, 1); // 2nd parameter means remove one item only
      setCreateActivityList([...dataArr]);
    }
    // setCreateActivityShow(false);
  };
  const createActivityComponent = (key: any) => {
    return (
      <EqCollapsePanel
        key={key}
        defaultOpen={true}
        header={"Create Activity"}
        children={
          <CreateActivity
            creatorUser={trainerData}
            areas={areas}
            weekDays={weekDays}
            population={population}
            groupPopulation={groupPopulation}
            trainerStudents={trainerStudents}
            onCreateOrUpdateActivity={trainerEventDefaults}
            onCancelActivity={() => handleCancelActivity(key)}
          />
          // <CreateActivity onCreateOrUpdateActivity={()=>trainerEventDefaults(createActivityList)} />
        }
      />
    );
  };

  const onHandleAddActivity = () => {
    // console.log(areas);
    const activityComponent = createActivityComponent(
      createActivityList.length
    );
    if (createActivityList.length == 0) {
      setCreateActivityList([...createActivityList, activityComponent]);
      console.log(createActivityList);
    }
  };

  //filter

  const filterTrainingList = (trainingList: any[]) => {
    console.log('studentsName:', studentsName);
    console.log('productList:', trainingList);

    let filteredList = trainingList;

    if (studentsName && studentsName.length > 0) {
      const searchName = studentsName.toLowerCase();
      //name
      filteredList = trainingList.map((item) => {
        const productOrders = item?.productOrders;

        const filteredProductOrders = productOrders.filter((productOrder: any) => {
          const firstName_Pro = productOrder?.student?.firstName;
          const lastName_Pro = productOrder?.student?.lastName;
          const username_Pro = productOrder?.student?.email;

          return (
            (firstName_Pro && (firstName_Pro + " " + lastName_Pro).toLowerCase().includes(searchName)) ||
            (username_Pro && username_Pro.toLowerCase().includes(searchName))
          );
        });

        return {
          ...item,
          productOrders: filteredProductOrders,
        };
      });
    }


    //Product 
    // if (selectedProducts && selectedProducts.length > 0) {
    //   filteredList = filteredList.filter((item) => {

    //     return selectedProducts.includes(item?.title + "")

    //   }
    //   );
    // }

    console.log('filteredList:', filteredList);
    return filteredList;
  };

  const searchNameClick = (searchStr: string) => {
    setSearch({ ...search, searchVal: searchStr });
    setStudentsName(searchStr)

    const filteredTrainings = filterTrainingList(trainingsList);
    setTrainingData(filteredTrainings);
  };



  return (
    <div className={`eq-training-board`}>
      <div className="eq-container-fluid">
      <Spin spinning={loading} size="large">


        <Row align="middle" justify="end">
          {!createActivityShow ? (
            <>
              {ValidateIsTrainer(userDetails) ? (
                <Row align="middle" justify="end">
                  <Col className="eq-event-buttons">
                    <EqButton
                      type="button"
                      classNames="btn-background-orange"
                      // onClick={createEvent}
                      // onClick={onHandleAddActivity}
                      onClick={() => setCreateActivityShow(true)}
                    >
                      {t("createTraining")}
                    </EqButton>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          ) : (
            ""
          )}
        </Row>
        {/* <Row className="eq-event-heading-row" align="middle" justify="start">
          <Col className="eq-event-heading-col" flex="auto">
            <h2 className="eq-event-heading">{t("Trainings")}</h2>
          </Col>
          <Col>
            {!createActivityShow ? (
              <>
                {ValidateIsTrainer(userDetails) ? (
                  <Row align="middle" justify="end">
                    <Col className="eq-event-buttons">
                      <EqButton
                        type="button"
                        classNames="btn-background-orange"
                        // onClick={createEvent}
                        // onClick={onHandleAddActivity}
                        onClick={() => setCreateActivityShow(true)}
                      >
                        {t("createTraining")}
                      </EqButton>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </Col>
        </Row> */}
        <Row className="eq-event-heading-line"></Row>

        {/* list of Create Components don't Remove*/}
        {/* <>
          {createActivityList && createActivityList.length > 0
            ? createActivityList.map((item: any, i: any) => (
                <div key={i}>{item}</div>
              ))
            : ""}
        </> */}

        {/* singleCreateActivityComponent */}
        {createActivityShow && (
          <EqCollapsePanel
            defaultOpen={true}
            header={t("createTraining")}
            children={
              <CreateOrEditPrivateTraining
                creatorUser={trainerData}
                areas={areas}
                weekDays={weekDays}
                population={population}
                groupPopulation={groupPopulation}
                trainerStudents={trainerStudents}
                studentHorsesList={studentHorsesList}
                onCreateOrUpdateActivity={handleOnCreateTraining}
                onCancelActivity={() => setCreateActivityShow(false)}
              />
            }
          />
        )}

        <Row>
          {/* <Skeleton loading={!trainingsList} active></Skeleton> */}
          {[...Array(5)].map((_, index) => (
          <Skeleton key={index} loading={!trainingsList} paragraph={{ rows: 2 }} active />
        ))}
        </Row>
        {trainingsList && (
          <>

            {trainingsList.length > 0 ? (
              <EqActivityTrainingList
                {...{
                  creatorUser: trainerData,
                  areas: areas,
                  weekDays: weekDays,
                  studentHorsesList: studentHorsesList,
                  trainerStudents: trainerStudents,
                  eventList: [...trainingsList],
                  population: population,
                  groupPopulation: groupPopulation,
                  removeEventFunc: removeTraining,
                  editEventFunc: editTraining,
                  onCreateOrUpdateActivity: handleOnUpdateTraining,
                }}
              ></EqActivityTrainingList>
            ) : (
              <Row justify="center">
                <Empty description={t("noTrainingsAvailable")} />
              </Row>
            )}
          </>
        )}
        
        </Spin>
      </div>
    </div>
  );
};
export default EqTrainerTrainings;
