import * as React from "react";
import "./EqSelect.scss";
import { Input, Select, Tag, Divider } from "antd-v5";
import { ISelectProps } from "../../shared/Interfaces";
import { useTranslation } from "react-i18next";

const EqSelect = ({
    label,
    classNames,
    mode,
    placeholder,
    defaultSelectedValue,
    focusBorder,
    extraHeight,
    changeFunc,
    options,
    border,
    icon,
    selectedIcon,
    unselectedIcon,
    showArrow,
    selectedOption,
    dropdownClassName,
    isTag,
    ddClassName,
    allowClear,
    showSearch,
    optionFilterProp,
    filterOption,
    isDisabled,
    isSeperator,
    seperatorList,
    dropdownRender,
    openSelect,
    handleOnBlur,
    isDropdownRenderClicked,
    ...rest
}: ISelectProps) => {
    //console.log(options);
    //console.log(seperatorList);
    const { Option, OptGroup } = Select;
    const { t } = useTranslation();

    const [defaultvals, setDefaultVals] = React.useState(defaultSelectedValue);
    const [openMSelect, setOpenMSelect] = React.useState(false);
    const [visible, setVisible] = React.useState(false);
    function tagRender(props: any) {
        const { label, value, closable, onClose } = props;
        //console.log(label, value);
        return (
            <Tag color={value} closable={closable} onClose={onClose}>
                {label}
            </Tag>
        );
    }
    const onChange = (value: any | undefined, param: any) => {
        debugger;
        //console.log(value, param);
        // setOpenMSelect(!openMSelect);

        var selectedVals =
            param?.constructor.name == "Array" ? param?.map((d: any) => d.key) : param?.key;
        setDefaultVals(selectedVals);
        if (changeFunc != undefined) {
            isTag == false ? changeFunc(value) : changeFunc(selectedVals);
        }
    };

    React.useEffect(() => {
        // console.log(options);
        setDefaultVals(defaultSelectedValue);
    }, [options, defaultSelectedValue]);

    return (
        <div className={`eq-select ${classNames ? classNames : ""}`}>
            {label && label.length > 0 && <label className="eq-label">{label}</label>}
            <Select
                mode={mode == "multiple" ? mode : (null as any)}
                // mode={mode}
                showSearch={showSearch ? showSearch : false}
                filterOption={filterOption ? filterOption : false}
                optionFilterProp={filterOption ? optionFilterProp : undefined}
                showArrow={showArrow ? showArrow : true}
                // allowClear={mode == "multiple" ? true : allowClear}
                allowClear={allowClear ? allowClear : false}
                bordered={border}
                suffixIcon={icon}
                className={`${focusBorder ? "sharp-border" : ""} ${
                    extraHeight ? "extra-height" : ""
                }`}
                placeholder={placeholder}
                value={isTag ? undefined : defaultvals}
                defaultValue={defaultvals}
                menuItemSelectedIcon={selectedIcon}
                tagRender={tagRender}
                dropdownClassName={ddClassName}
                disabled={isDisabled ? true : false}
                dropdownRender={dropdownRender}
                open={openMSelect}
                onChange={onChange}
                onClick={() => {
                    //
                    setTimeout(() => {
                        setOpenMSelect(!openMSelect);
                    }, 300);
                }}
                onBlur={(e: any) => {
                    setTimeout(() => {
                        setOpenMSelect(false);
                    }, 300);
                    console.log(isDropdownRenderClicked);
                    //
                    // console.log(e);
                    // if(!dropdownRender){

                    // }
                }}
            >
                {isSeperator
                    ? isTag
                        ? options &&
                          options.length > 0 &&
                          options.map((g: any) => (
                              <OptGroup label={g.label}>
                                  {/* <label> {g.label}</label> */}

                                  {g.options &&
                                      g.options.length > 0 &&
                                      g.options.map((opt: any) => (
                                          <Option
                                              label={opt.label}
                                              key={opt.key}
                                              value={opt.value}
                                              disabled={opt.disabled}
                                          >
                                              {opt.label}
                                              {opt.description && (
                                                  <span className="text-description">
                                                      {opt.description}
                                                  </span>
                                              )}
                                          </Option>
                                      ))}
                              </OptGroup>
                          ))
                        : options &&
                          options.length > 0 &&
                          options.map((g: any) => (
                              <OptGroup label={g.label}>
                                  {g.options &&
                                      g.options.length > 0 &&
                                      g.options.map((opt: any) => (
                                          <Option
                                              className={opt.className}
                                              label={opt.value}
                                              key={opt.key}
                                              value={opt.key}
                                              disabled={opt.disabled}
                                          >
                                              <span>
                                                  <span className="text-icon">{opt.icon}</span>{" "}
                                                  {opt.value}
                                              </span>
                                              {opt.description && (
                                                  <span className="text-description">
                                                      {opt.description}
                                                  </span>
                                              )}
                                          </Option>
                                      ))}
                              </OptGroup>
                          ))
                    : isTag
                    ? options &&
                      options.length > 0 &&
                      options.map((opt: any) => (
                          <Option
                            className={opt.className}

                            key={opt.key}
                            value={opt.value}
                              label={opt.label}
                              disabled={opt.disabled}
                          >
                              {opt.label}
                              {opt.description && (
                                  <span className="text-description">{opt.description}</span>
                              )}
                          </Option>
                      ))
                    : options &&
                      options.length > 0 &&
                      options.map((opt: any) => (
                          <>
                              <Option
                                  className={opt.className}
                                
                                  key={opt.key}
                                  value={opt.key}
                                  label={opt.value}

                                  disabled={opt.disabled}
                              >
                                  <span>
                                      <span className="text-icon">{opt.icon}</span> {opt.value}
                                  </span>
                                  {opt.description && (
                                      <span className="text-description">{opt.description}</span>
                                  )}
                              </Option>
                          </>
                      ))}

                {isSeperator && (
                    <>
                        <Option value="disabled" disabled>
                            <Divider
                                style={{
                                    margin: "8px 0",
                                }}
                            />
                        </Option>
                        <OptGroup label={t("others")}>
                            {seperatorList &&
                                seperatorList.length > 0 &&
                                seperatorList.map((d: any) => (
                                    <>
                                        <Option
                                            className={d.className}
                                            label={d.value}
                                            value={d.key}
                                            key={d.key}
                                        >
                                            <span>
                                                <span className="text-icon">{d.icon}</span>{" "}
                                                {d.value}
                                            </span>
                                            {d.description && (
                                                <span className="text-description">
                                                    {d.description}
                                                </span>
                                            )}
                                        </Option>
                                    </>
                                ))}
                        </OptGroup>
                    </>
                )}

                {/* {
                <OptGroup>
                    
                </OptGroup>
                } */}
            </Select>
        </div>
    );
};

export default EqSelect;
