import * as React from "react";
import "./EqTrainerAllEvents.scss";
import { Row, Col, Skeleton, Empty } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsTrainer,
  ValidateIsUser,
  ValidateIsSchool,
} from "../../shared/Common";
import Api from "../../shared/Api";
import { useTranslation } from "react-i18next";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "../../context/Context";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqActivityTrainingList from "../eqActivityTrainingList/EqActivityTrainingList";
import EqCreateOrEditPublicEvent from "../eqCreateOrEditPublicEvent/EqCreateOrEditPublicEvent";
import { Drawer, Spin } from "antd-v5";
import EqCreateOrEditEvent from "../../../eqComponents/publicEvents/eqCreateorEditEvent/EqCreateOrEditEvent";
import { CloseCircleFilled } from "@ant-design/icons";
export interface IEqTrainerEvents {
  updateCount?: (count: number) => void;
  activeEvent?: any;
}
const EqTrainerAllEvents = ({ updateCount, activeEvent }: IEqTrainerEvents) => {
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const isSchool: boolean = ValidateIsSchool(userDetails);
  const { t, i18n } = useTranslation();

  const [creatorUser, setCreatorUser] = React.useState([]) as any;

  const [message, setMessage] = React.useState("");
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  const [areas, setAreas] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState([]) as any;
  const [population, setPopulation] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [studentHorsesList, setStudentHorsesList] = React.useState({});
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;

  const [imagesData, setImagesData] = React.useState([]) as any;

  const [CreatePublicEventShow, setCreatePublicEventShow] =
    React.useState(true);

  const [eventsList, setEventsList] = React.useState(null) as any;
  // const [eventsList, setEventsList] = React.useState([] as Array<IEqEventData>);


  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (eventsList != undefined) {
      GetTrainerPublicEvents();

      //   const events = [...eventsList];
      //   var foundIndex = events.findIndex(
      //   (x) => x.referenceKey == activeEvent.referenceKey
      // );
      // if (foundIndex!==-1) {
      //   events[foundIndex] = activeEvent;
      // } else {
      //   events.push(activeEvent);
      // }
      // setEventsList(events);
    }

    // window.scrollTo(0, 0);
  }, [activeEvent]);

  React.useEffect(() => {
    GetTrainerPublicEvents();
    GetTrainerStudents();
    getImages();
    getAreas();
    getWeekDays();
    getPopulation();
    getGroupPopulation();

    // window.scrollTo(0, 0);
  }, []);

  const GetUserProfile = () => {
    Api.get(ApiEndpoints.GetTrainerProfile).then(
      (response) => {
        if (response && response.status == 200) {

          setIsError(false);
          setMessage("");
          setCreatorUser(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const handleOnUpdateEvent = (newData: any, oldData: any) => {
    if (eventsList != undefined) {
      const events = [...eventsList];
      var foundIndex = events.findIndex(
        (x) => x.referenceKey == oldData.referenceKey
      );
      events[foundIndex] = newData;
      setEventsList(events);
    }

    if (updateCount) {
      updateCount(eventsList.length);
    }
  };
  const handleOnCreateEvent = (data: any) => {
    // setCreatePublicEventList([]);

    const events = [...eventsList];

    events.push(data);
    setEventsList(events);

    if (updateCount) {
      updateCount(eventsList.length);
    }
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.Location.GetLocationOptions).then(
      (response) => {
        if (response && response.status === 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
          // console.log("population:",response.data);
          // console.table(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const updateImageLibrary = (newImage: any) => {
    
    setImagesData((prevImages:any) => [...prevImages, newImage]);
  };
  const getImages = () => {
    Api.get(ApiEndpoints.Image.GetUserImages).then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);
        }
      },
      () => {
        setIsError(true);
        setMessage("Error Occurred while fetching images from server");
      }
    );
  };



  
  const GetTrainerStudents = () => {
    Api.get(ApiEndpoints.GetTrainerStudents).then(
      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var trainerStudents = response.data;
          setIsError(false);
          // console.log(userDetails);
          setMessage("");
          setTrainerStudents(response.data);
          // console.log(trainerHorsesList);
          [...trainerStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.trainer.horses.length > 0) {
              [...item.trainer.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const GetTrainerPublicEvents = async () => {
    // let path = ApiEndpoints.GetTrainerPublicEvents;
    
    let path = ApiEndpoints.GetTrainerEventsByMonths;
    // let path = ApiEndpoints.GetTrainerEvents;
    Api.get(path).then(
      (response) => {
        if (response && response.status == 200) {
          var data = response.data ? [...response.data] : new Array<any>();

          if (activeEvent) {
            var foundIndex = data.findIndex(
              (x) => x.referenceKey == activeEvent.referenceKey
            );
            if (foundIndex !== -1) {
              data[foundIndex].collapseOpen = true
            }
            setEventsList(data);
            console.log("EventListObject", data)
          } else {
            setEventsList(data);
            console.log("EventListObject", data)
          }


        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeEvent = (data: IEqEventData, refund?: boolean) => {

    setLoading(true)
    // let path=ApiEndpoints.DeleteTrainerEvent + `/${data.key}`;
    let path =
      ApiEndpoints.DeleteTrainerEvent + `?key=${data.key}&refund=${refund}`;

    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(response.data);
          GetTrainerPublicEvents();
        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
    setLoading(false);
        
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
    setLoading(false);

      }
    );

    if (updateCount) {
      updateCount(eventsList.length); // Replace 'newCount' with the actual count
    }
  };
  const removeItem = (data: any) => {
    if (eventsList != undefined) {
      var updatedList =
        eventsList != undefined
          ? eventsList.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setEventsList(updatedList);

      eventsList.map((item: any, index: number) => {
        item.display = index == 0 ? true : false;
      });
    }
  };

  return (
    <div className={`eq-trainer-event-board`}>
      <div className="eq-container-fluid">
      <Spin spinning={loading} size="large">

        <Row align="middle" justify="end">
          {!CreatePublicEventShow && (
            <>
              {isTrainer || isSchool ? (
                <Row align="middle" justify="end">
                  <Col className="eq-event-buttons">
                    <EqButton
                      type="button"
                      classNames="btn-background-orange"
                      onClick={() => setCreatePublicEventShow(true)}
                    >
                      {t("createEvent")}
                    </EqButton>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </>
          )}
        </Row>

        <Row className="eq-event-heading-line"></Row>

        {CreatePublicEventShow && (
          <EqCollapsePanel
            defaultOpen={true}
            header={t("createEvent")}
            children={
              <EqCreateOrEditPublicEvent
                imagesData={imagesData}
                areas={areas}
                population={population}
                weekDays={weekDays}
                groupPopulation={groupPopulation}
                trainerStudents={trainerStudents}
                studentHorsesList={studentHorsesList}
                onCreateOrUpdateActivity={handleOnCreateEvent}
                onCancelActivity={() => setCreatePublicEventShow(false)}
              />
            }
          />
        )}

{/* {CreatePublicEventShow && (
          <Drawer
          size="large"
            open={true}
            title={t("createEvent")}
            children={
              <EqCreateOrEditPublicEvent
                imagesData={imagesData}
                areas={areas}
                population={population}
                weekDays={weekDays}
                groupPopulation={groupPopulation}
                trainerStudents={trainerStudents}
                studentHorsesList={studentHorsesList}
                onCreateOrUpdateActivity={handleOnCreateEvent}
                onCancelActivity={() => setCreatePublicEventShow(false)}
              />
            }
          />
        )} */}
{/* {CreatePublicEventShow && (
          <Drawer
          closeIcon={<CloseCircleFilled  style={{ fontSize: '24px', color: '#08c' }}/>}
          width={1000}
          open={true}
          // size={"large"}
            // title={t("createEvent")}FF
            children={
              <EqCreateOrEditEvent
              imagesData={imagesData}
              creatorUser={creatorUser}
              areas={areas}
                // eventData={null}
                // population={population}
                // groupPopulation={groupPopulation}
                // trainerStudents={trainerStudents}
                // studentHorsesList={studentHorsesList}
                onCreateOrUpdateActivity={()=>{}}
                onCancelActivity={() => setCreatePublicEventShow(false)}
                onImageUploadSuccess={updateImageLibrary}
              />
            }
          />
        )} */}




        <Row>
          {[...Array(5)].map((_, index) => (
          <Skeleton key={index} loading={!eventsList} paragraph={{ rows: 2 }} active />
        ))}
        </Row>
        {eventsList && (
          <>
            {eventsList.length > 0 ? (
              <EqActivityTrainingList
                eventList={eventsList}
                areas={areas}
                weekDays={weekDays}
                population={population}
                groupPopulation={groupPopulation}
                trainerStudents={trainerStudents}
                studentHorsesList={studentHorsesList}
                removeEventFunc={removeEvent}
                // editEventFunc= editEvent
                // onCreateOrUpdateActivity={trainerEventDefaults}
                onCreateOrUpdateActivity={handleOnUpdateEvent}
              ></EqActivityTrainingList>
            ) : (
              <Row justify="center">
                <Empty description={t("noEventsAvailable")} />
              </Row>
            )}
          </>
        )}
        </Spin>
      </div>
    </div>
  );
};
export default EqTrainerAllEvents;
