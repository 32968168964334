import * as React from "react";
import { useEffect, useState } from "react";
import {
  FrontEndPoints,
  GenericMessage,
  openNotificationWithIcon,
} from "../../shared/Common";
import "./EqTrainerDashboard.scss";
import { Row, Col, Checkbox, Skeleton, DatePicker } from "antd";
import EqButton from "../../custom/eqButton/EqButton";
import { useTranslation } from "react-i18next";
import EqMessage, { IEqMessage, IEqMessageData } from "../eqMessage/EqMessage";
import { EqDisciplineType, EqLocationType, EqTrainingType } from "../../shared/enums";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Api from "../../shared/Api";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import {
  GetEventMessage,
  ApiEndpoints,
  ValidateIsTrainer,
} from "../../shared/Common";
import { EqMessageType } from "../../shared/enums";
import { IStudentDto } from "../../shared/interfaces/IStudentDto";
import { useNavigate } from "react-router-dom";
import { IEqEventData } from "../../shared/interfaces/IEqEventDto";
import { useAuthState } from "../../context/Context";
import EqTrainerEventInvitationBoard from "../trainerEventInvitationBoard/EqTrainerEventInvitationBoard";
import EqCollapsePanel from "../../custom/eqCollapsePanel/EqCollapsePanel";
import EqSchoolInvitationBoard from "../../schoolInvitation/EqSchoolInvitationBoard";
import EqTrainerTrainingJoinRequestBoard from "../trainerTrainingJoinRequestBoard/EqTrainerTrainingJoinRequestBoard";
import EqActivityTrainingListDashboard from "../eqActivityTrainingList/EqActivityTrainingListDashbord/EqActivityTrainingListDashboard";
import { IGetTrainingDto } from "../../shared/interfaces/trainingDto/IGetTrainingDto";
import EqOverviewProfile from "../overViewTrainerProfile/EqOverviewTrainerProfile";
import { ISelectProps } from "../../shared/Interfaces";
import EqSelect from "../../custom/eqSelect/EqSelect";
import EqOverviewTrainerProfile from "../overViewTrainerProfile/EqOverviewTrainerProfile";
import AnimationDiv from "../../custom/animationDiv/AnimationDiv";


const EqTrainerDashboard = (props: any) => {
  const DATE_FORMAT = "YYYY-MM-DD";
  const userDetails: any = useAuthState();
  const isTrainer: boolean = ValidateIsTrainer(userDetails);
  const navigate = useNavigate();
  const location = useLocation();

  const { t, i18n } = useTranslation();
  const [fisCheckAll, fsetIsCheckAll] = useState(false);
  const [messageString, setMessageString] = useState("");
  const [messages, setMessages] = useState([] as Array<any>);
  const [isError, setIsError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [misCheckAll, msetIsCheckAll] = useState(false);
  const [trainings, setTrainings] = React.useState(null) as any;
  const [eventsList, setEventsList] = React.useState(null) as any;
  const [schoolInvitation, setSchoolInvitation] = useState(true);

  const [activityInvitation, setActivityInvitation] = useState(true);
  const [trainingInvitation, setTrainingInvitation] = useState(true);

  const [currentStudentslist, setCurrentStudentsList] = React.useState(
    [] as Array<IStudentDto>
  );
  const [keys, setKeys] = useState(new Array<any>());
  const [areas, setAreas] = React.useState([]) as any;
  const [weekDays, setWeekDays] = React.useState() as any;

  const [population, setPopulation] = React.useState([]) as any;
  const [groupPopulation, setGroupPopulation] = React.useState([]) as any;
  const [trainerStudents, setTrainerStudents] = React.useState([]) as any;
  const [studentHorsesList, setStudentHorsesList] = React.useState([]);
  const [trainerTrainings, setTrainerTrainings] = React.useState(null) as any;
  const [trainerEvents, setTrainerEvents] = React.useState(null) as any;

  const [stateView, setStateView] = useState({
    unpaidTraining: [],
    pendingTraining: [],
    unpaidProduct: [],
    pendingProduct: [],
    upcomingTraining: [],
    monthlySales: []
  });

  const [message, setMessage] = React.useState("");
  const [imagesData, setImagesData] = React.useState([]) as any;
  const [paymentInfo, setPaymentInfo] = React.useState() as any;

  //filter
  const [selectedTraining, setSelectedTraining] = React.useState([] as any);
  const [trainingOptions, setTrainingOptions] = React.useState([] as any);

  const [selectedEvents, setSelectedEvents] = React.useState([] as any);
  const [eventsOptions, setEventsOptions] = React.useState([] as any);

  const [fromDate, setFromDate] = useState<moment.Moment | null>(null);

  var selectedMessages = new Array<any>();

  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const todayTime = today.getTime();

  const goToTrainerProfileboard = (): void => {
    if (userDetails && userDetails.key) {
      navigate(`${FrontEndPoints.trainer.profileBoard}?key=${userDetails.key}`);
    }
  };
  //const [messagesList, setMessagesList] = useState(messages);
  //initial gets


  const GetTrainerEventsByMonth = () => {

    Api.get(ApiEndpoints.GetTrainerEventsByMonth)
      .then((response) => {
        if (response && response.status === 200) {
          var data = response.data
          setTrainerEvents(data);
          // console.log('trainingevents', data)
        } else {
          setIsError(true);
          GetEventMessage(response.status);
        }
      })
      .catch((error) => {
        GetEventMessage(error.response.status);
      });
  };

  const GetTrainerTrainingByMonth = () => {

    Api.get(ApiEndpoints.TrainerTraining.GetTrainerTrainingsOfMonths)
      .then((response) => {
        if (response && response.status === 200) {
          var data = response.data
          setTrainerTrainings(data);
          // console.log('Trainer-Trainings', data)
        } else {
          setIsError(true);
          GetEventMessage(response.status);
        }
      })
      .catch((error) => {
        GetEventMessage(error.response.status);
      });
  };
  /// Update after change in Update Api
  const handleOnUpdateTrainerTraining = (newData: any, oldData: any) => {
    if (trainerTrainings != undefined) {
      const trainings = [...trainerTrainings];
      var foundIndex = trainings.findIndex((x) => x.key == oldData.key);
      trainings[foundIndex] = newData;
      setTrainerTrainings(trainings);
    }

  };

  // const handleOnUpdateTrainerTraining = () => {
  //   GetTrainerEventsByMonth();
  // };



  //stat
  const dashboardState = () => {

    Api.get(ApiEndpoints.GetDashboardState)
      .then((response) => {
        if (response && response.status === 200) {
          // var result = response.data;
          const { pendingProductInvites,
            pendingTrainingInvites,
            unPaidProductOrders,
            unPaidTrainingOrders,
            upcomingTrainings,
            monthlySales
          } =
            response.data;
          setStateView({
            unpaidTraining: unPaidTrainingOrders,
            pendingTraining: pendingTrainingInvites,
            unpaidProduct: unPaidProductOrders,
            pendingProduct: pendingProductInvites,
            upcomingTraining: upcomingTrainings,
            monthlySales: monthlySales
          });
          // console.log("datsssssss", response.data)
        } else {
          setIsError(true);
          (GetEventMessage(response.status));
        }
      })
      .catch((error) => {
        (GetEventMessage(error.response.status));
      });
  };

  const GetTrainerStudents = () => {
    // Api.get(ApiEndpoints.GetTrainerStudents).then(
      Api.get(ApiEndpoints.TrainerStudents.GetTrainerStudentsGroupDto).then(

      (response) => {
        if (response && response.status == 200) {
          var studentHorsesListOptions = [] as any;
          var trainerStudents = response.data;
          setIsError(false);

          setMessage("");
          setTrainerStudents(response.data);

          [...trainerStudents].map((item: any) => {
            let horseListObj = [] as any;

            if (item.user.horses.length > 0) {
              [...item.user.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }
            if (item.trainer.horses.length > 0) {
              [...item.trainer.horses].map((h: any) => {
                let horseObj = {
                  key: h.id,
                  value: h.name,
                  label: h.name,
                };
                horseListObj.push(horseObj);
              });
            }

            let studentHorsesObj = {
              key: item.key,
              horseList: horseListObj,
            };

            studentHorsesListOptions.push(studentHorsesObj);
          });
          setStudentHorsesList(studentHorsesListOptions);
          // console.log("studentHorses:", studentHorsesListOptions);
          // console.log("TrainerStudents:",response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getPopulation = () => {
    Api.get(ApiEndpoints.eventGetPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setPopulation(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getGroupPopulation = () => {
    Api.get(ApiEndpoints.eventGetGroupPopulation).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setGroupPopulation(response.data);
          //console.table(population);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };

  const getAreas = () => {
    Api.get(ApiEndpoints.Location.GetUserAreaOptions).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setAreas(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getWeekDays = () => {
    Api.get(ApiEndpoints.eventGetWeekDays).then(
      (response) => {
        if (response && response.status == 200) {
          setIsError(false);
          setMessage("");
          setWeekDays(response.data);
        } else {
          setIsError(true);
          setMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const getImages = () => {
    Api.get("image/getEventImages").then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);
        }
      },
      () => {
        setIsError(true);
        setMessage("Error Occurred while fetching images from server");
      }
    );
  };

  const replyEmail = (message: IEqMessageData) => {
    GetMessages();
  };
  const mardFlagedEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.saveMessage, {
        Message: { Key: message.key },
        IsFlaged: message.isFlaged,
        IsRead: message.isRead,
      }).then(
        (response) => {
          if (response && response.status == 200) {
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const deleteEmail = (message: IEqMessageData) => {
    if (message != null) {
      Api.post(ApiEndpoints.deleteMessage, {
        Message: { Key: message.key },
      }).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const onCheckChange = (message: IEqMessageData, checked: boolean) => {
    if (checked) {
      selectedMessages.push({ Key: message.key });
    } else {
      if (selectedMessages != null && selectedMessages.length > 0) {
        const index = selectedMessages.findIndex((x) => x.Key == message.key);
        if (index > -1) {
          selectedMessages.splice(index, 1);
        }
      }
    }

    setKeys(selectedMessages);
  };
  const deleteSelectedMessages = () => {
    msetIsCheckAll(false);

    if (keys?.length > 0) {
      Api.post(ApiEndpoints.deleteSelectedMessages, keys).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();

            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const readSelectedMessages = () => {
    msetIsCheckAll(false);
    selectedMessages = [];

    keys.map((opt: any) => {
      selectedMessages.push({ Message: { Key: opt.Key }, IsRead: true });
    });
    if (keys?.length > 0) {
      Api.post(ApiEndpoints.saveSelectedMessages, selectedMessages).then(
        (response) => {
          if (response && response.status == 200) {
            GetMessages();
            selectedMessages = [];
            return true;
          } else {
            return false;
          }
        },
        (err) => {
          return false;
        }
      );
    } else {
      return false;
    }
  };
  const deleteStudent = (student: any) => {
    Api.post(ApiEndpoints.deleteStudent, student).then(
      (response) => {
        if (response && response.status == 200) {
          GetUnpaidStudents();
          return true;
        } else {
          return false;
        }
      },
      (err) => {
        return false;
      }
    );
  };
  const GetMessages = async () => {
    setMessageString("");
    var messagesFromDB = [] as any;
    await Api.get(ApiEndpoints.getMessages).then(
      (response) => {
        if (response && response.status == 200) {
          response.data?.result?.map((d: any) => {
            if (d.message.messageType !== EqMessageType.Notification) {
              messagesFromDB.push({
                message: {
                  key: d.message.key,
                  messageOn: new Date(d.message.sentOn),
                  type: EqMessageType[d.message.messageType],
                  from: d.message.sender.userName,
                  to: "trainer",
                  subject: d.message.subject,
                  text: d.message.message,
                  isRead: d.isRead,
                  isSent: d.isSent,
                  isFlaged: d.isFlaged,
                },
                checkBoxBordered: false,
                clickedReplyFun: replyEmail,
                clickedFlagFun: mardFlagedEmail,
                clickedDeleteFunc: deleteEmail,
                onCheckChangeFunc: onCheckChange,
                selected: false,
              });
            }
          });
          messagesFromDB = messagesFromDB?.sort((obj1: any, obj2: any) => {
            if (obj1.message.isRead > obj2.message.isRead) {
              return 1;
            } else if (obj1.message.isRead < obj2.message.isRead) {
              return -1;
            } else {
              return 0;
            }
          });

          setMessages(messagesFromDB);
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };

  const editEvent = (data: IEqEventData) => {
    navigate(FrontEndPoints.trainer.createEvent, { state: { event: data } });
  };

  const removeTraining = (trainingData: IGetTrainingDto, refund?: boolean) => {

    let path =
      ApiEndpoints.TrainerTraining.Delete +
      `?key=${trainingData.key}&refund=${refund}`;
    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeItem(trainingData);
        } else {
          setIsError(true);
          setErrorMessage(GenericMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GenericMessage(error.response.status));
        openNotificationWithIcon(
          GenericMessage(error.response.status),
          "Error occurred while Deleting. Please try again or contact administrator."
        );
      }
    );
  };



  const removeItem = (data: any) => {
    if (trainerTrainings != undefined) {
      var updatedList =
        trainerTrainings != undefined
          ? trainerTrainings.filter((item: any) => item.key !== data.key)
          : new Array<any>();
      setTrainerTrainings(updatedList);
    }
  };

  /// Trainer EVENTS


  const handleOnUpdateEvent = (newData: any, oldData: any) => {

    GetTrainerEventsByMonth();


    // if (trainerEvents != undefined) {
    //   const events = [...trainerEvents];
    //   var foundIndex = events.findIndex(
    //     (x) => x.referenceKey == oldData.referenceKey
    //   );
    //   events[foundIndex] = newData;
    //   setTrainerEvents(events);
    // }
  };

  const removeEvent = (data: IEqEventData, refund?: boolean) => {
    let path =
      ApiEndpoints.DeleteTrainerEvent + `?key=${data.key}&refund=${refund}`;

    Api.delete(path).then(
      (response) => {
        if (response && response.status == 200) {
          removeEventItem(data);

        } else {
          setIsError(true);
          setErrorMessage(GetEventMessage(response.status));
        }
      },
      (error) => {
        setIsError(true);
        setErrorMessage(GetEventMessage(error.response.status));
      }
    );
  };
  const removeEventItem = (data: any) => {
    GetTrainerEventsByMonth();
    // implement That  instead of re-calling

    // if (trainerEvents != undefined) {
    //   var updatedList =
    //   trainerEvents != undefined
    //       ? trainerEvents.filter((item: any) => item.key !== data.key)
    //       : new Array<any>();
    //   setTrainerEvents(updatedList);
    // }
  };

  //------------//
  const GetUnpaidStudents = () => {
    setMessageString("");
    Api.get(ApiEndpoints.getStudentsUnpaidPayments).then(
      (response) => {
        var students = [] as any;
        if (response && response.status == 200) {
          var data = response?.data?.result
            ? [...response.data.result]
            : new Array<IStudentDto>();
          data.map((d: any) => {
            students.push({
              student: {
                firstName: d.user?.firstName,
                lastName: d.user?.lastName,
                userName: d.user?.userName,
                email: d.user?.email,
                locations: d.user?.locations
                  ?.filter(
                    (opt: any) => opt.location?.type == EqLocationType.Home
                  )
                  .shift(),
                // payment: paymentStatusType.Unpaid,
                groups: d.user?.groups,
                selected: false,
              },
              deleteFunc: deleteStudent,
              selected: false,
            });
          });
          setCurrentStudentsList(students);
        } else {
          setMessageString("Not found");
        }
      },
      (err) => {
        setMessageString("Not found");
      }
    );
  };
  const messagesCheckAll = () => {
    msetIsCheckAll(!misCheckAll);

    messages.map((m: IEqMessage) => {
      if (m.selected == false) {
        m.selected = !misCheckAll;
        selectedMessages.push({ Key: m.message.key });
      } else {
        m.selected = !misCheckAll;
        if (selectedMessages != null && selectedMessages.length > 0) {
          const index = selectedMessages.findIndex(
            (x) => x.Key == m.message.key
          );
          if (index > -1) {
            selectedMessages.splice(index, 1);
          }
        }
      }
      setKeys(selectedMessages);
    });
    const modifiedList = [...messages];
    setMessages(modifiedList);
  };
  const finanacialCheckAll = () => {
    fsetIsCheckAll(!fisCheckAll);

    currentStudentslist.map((f: any) => {
      f.selected = !fisCheckAll;
    });
    const modifiedList = [...currentStudentslist];
    setCurrentStudentsList(modifiedList);
  };
  const GetStripeDashboardUrlAsync = () => {
    Api.get("Payment/GetAccountUrl").then(
      (response) => {
        if (response.status == 200) {
          const data = response.data;
          // window.location.href = data.url;
          window.open(data.url, "_blank");
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };



  //   const redirectToStripeDashboard = async () => {
  //     try {
  //         const response = await axios.get(`/api/stripe/dashboard-url/${connectedAccountId}`);
  //         const data = response.data;
  //         window.location.href = data.url;
  //     } catch (error) {
  //         console.error('Error redirecting to Stripe Dashboard:', error);
  //     }
  // };

  //filter for training
  const filterTrainings = (trainingList: any[]) => {
    let filteredList = trainingList;
    if (selectedTraining && selectedTraining.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedTraining.some((selectedType: any) => item?.disciplineType === selectedType);
      }
      );
    }
    if (fromDate) {
      const FromDate = moment(fromDate).startOf("day").valueOf();
      filteredList = filteredList.filter((item) => {

        const startDate = moment(item?.startDate).startOf("day").valueOf();
        return startDate === FromDate;
      });
    }

    // console.log(filteredList)
    return filteredList;
  }


  const trainingTypeProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("training"),
    extraHeight: true,
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedTraining ? selectedTraining : [],
    options: trainingOptions,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedTraining(value);
    },
  };
  const createTrainingList = () => {
    var eventTypes = [];
    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqTrainingType[EqTrainingType.PrivateLesson],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqTrainingType[EqTrainingType.GroupLesson],
    });
    eventTypes.push({
      key: EqDisciplineType.Open,
      value: EqTrainingType[EqTrainingType.AvailableTimeSlots],
    });
    setTrainingOptions(eventTypes);
    // console.log("options", trainingOptions);
    //   console.log("options eventTypes", eventTypes);
  };


  const filterEvents = (eventList: any[]) => {

    let filteredList = eventList;
    if (selectedEvents && selectedEvents.length > 0) {
      filteredList = filteredList.filter((item) => {
        return selectedEvents.some((selectedType: any) => item?.disciplineType === selectedType);
      });
    }

    if (fromDate) {
      const FromDate = moment(fromDate).startOf("day").valueOf();
      filteredList = filteredList.filter((item) => {

        const startDate = moment(item?.startDate).startOf("day").valueOf();
        return startDate === FromDate;
      });
    }

    // console.log(filteredList)
    return filteredList;
  }


  const eventTypeProps: ISelectProps = {
    classNames: `eq-multiSelect eq-searchSelect`,
    placeholder: t("events"),
    extraHeight: true,
    mode: "multiple",
    isTag: false,
    defaultSelectedValue: selectedEvents ? selectedEvents : [],
    options: eventsOptions,
    changeFunc: (value: any) => {
      console.log(value);
      setSelectedEvents(value);
    },
  };
  const createEventList = () => {
    var eventTypes = [];
    eventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqDisciplineType[EqDisciplineType.Dressage],
    });
    eventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqDisciplineType[EqDisciplineType.Jumping],
    });
    eventTypes.push({
      key: EqDisciplineType.Western,
      value: EqDisciplineType[EqDisciplineType.Western],
    });
    eventTypes.push({
      key: EqDisciplineType.Eventing,
      value: EqDisciplineType[EqDisciplineType.Eventing],
    }); eventTypes.push({
      key: EqDisciplineType.MountedGames,
      value: EqDisciplineType[EqDisciplineType.MountedGames],
    }); eventTypes.push({
      key: EqDisciplineType.IslandicHorses,
      value: EqDisciplineType[EqDisciplineType.IslandicHorses],
    }); eventTypes.push({
      key: EqDisciplineType.Racehorse,
      value: EqDisciplineType[EqDisciplineType.Racehorse],
    });
    setEventsOptions(eventTypes);
    console.log("options", eventsOptions);
  };
  //   const redirectToStripeDashboard = async () => {
  //     try {
  //         const response = await axios.get(`/api/stripe/dashboard-url/${connectedAccountId}`);
  //         const data = response.data;
  //         window.location.href = data.url;
  //     } catch (error) {
  //         console.error('Error redirecting to Stripe Dashboard:', error);
  //     }
  // };
  useEffect(() => {
    createTrainingList();
    createEventList();
    dashboardState();

    GetTrainerTrainingByMonth();
    GetTrainerEventsByMonth();


    // filterTrainings
    getAreas();
    getWeekDays();
    getPopulation();
    getGroupPopulation();
    getImages();
    GetTrainerStudents();




    GetUnpaidStudents();
    GetMessages();
    // UpdatePaymentInfo();
  }, []);

  return (

    <AnimationDiv>

      <div className="eq-sb-container">
        <div className="eq-container-fluid">
          <Row className="eq-sb-heading-row">
            <Col className="eq-sb-col" flex="auto" md={5} xs={24} sm={24}>
              <h2 className="eq-sb-heading">{t("myDashboard")}</h2>
            </Col>
          </Row>
          <div className="eq-section">
            <EqOverviewTrainerProfile
              stat={stateView}
              trainingList={trainerTrainings}
            />
          </div>
          {/* <div className="eq-section">
          <Row className="eq-db-row" align="stretch">
            <Col xs={24} sm={24} md={18} className="eq-db-icons">
              <Row>
                <div className="eq-db-h2">{t("quickLinks")}</div>
              </Row>
              <Row align="bottom" justify="start" className="eq-db-icon-row">
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <Link
                    to={FrontEndPoints.trainer.studentBoard}
                    state={{ prevPath: location.pathname }}
                  >
                    <span>
                      <img alt="" src="/studentplus.svg" />
                    </span>
                    <div className="eq-db-icon-text">{t("inviteStudent")}</div>
                  </Link>
                </Col>
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <Link
                    to={FrontEndPoints.trainer.activityBoard}
                    state={{ prevPath: location.pathname }}
                  >
                    <span>
                      <img alt="" src="/calendarplus.svg" />
                    </span>
                    <div className="eq-db-icon-text">
                      {t("createActivities")}
                    </div>
                  </Link>
                </Col>
                <Col className="eq-db-icon" xs={8} sm={8} md={4}>
                  <div onClick={GetStripeDashboardUrlAsync}>
                    <span>
                      <img alt="" src="/profile.svg" />
                    </span>
                    <div className="eq-db-icon-text">
                      {t("stripeDashboard")}
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div> */}

          {schoolInvitation ? (
            <>
              <div className="eq-section-divider"></div>
              <div className="eq-section">
                <EqCollapsePanel
                  defaultOpen={true}
                  header={"School Invitations"}
                  children={
                    <EqSchoolInvitationBoard
                      onloadActivity={(e: any) => {
                        setSchoolInvitation(e);
                      }}
                    />
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}


          {/* that is for open event requests to trainer by students */}

          {activityInvitation ? (
            <>
              <div className="eq-section-divider"></div>
              <div className="eq-section">
                <EqCollapsePanel
                  header={t("activityInvitations")}
                  children={
                    <div>
                      <EqTrainerEventInvitationBoard
                        onloadActivity={(e: any) => {
                          setActivityInvitation(e);
                        }}
                      />
                    </div>
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}

          {trainingInvitation ? (
            <>
              <div className="eq-section-divider"></div>
              <div className="eq-section">
                <EqCollapsePanel
                  header={t("trainingJoinRequests")}
                  defaultOpen={true}
                  children={
                    <div>
                      <EqTrainerTrainingJoinRequestBoard
                        onloadActivity={(e: any) => {
                          setTrainingInvitation(e);
                        }}
                      />
                    </div>
                  }
                />
              </div>
            </>
          ) : (
            ""
          )}

          {/* <div className="eq-section-divider"></div>
          <div className="eq-section">
            <EqCollapsePanel
              header={t("newMessages")}
              children={
                <>
                  <Row className="eq-msg-header" align="bottom">
                    <Col flex="auto">
                      <div className="eq-db-h2-collapse">{t("newMessages")}</div>
                    </Col>
                    <Col md={2}>
                      <Row>
                        <Col
                          className="eq-msg-icons"
                          onClick={readSelectedMessages}
                        >
                          <span>
                            <img alt="" src="/black-check.svg" />
                          </span>
                        </Col>
                  
                        <Col
                          className="eq-msg-icons"
                          onClick={deleteSelectedMessages}
                        >
                          <span>
                            <img alt="" src="/trash-alt.svg" />
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {messages.length > 0 && (
                    <>
                      {
                        <Row className="eq-list-header hidden-sm">
                          <Col md={22} flex="auto">
                            <Row>
                              <Col span={1} className="">
                                <Checkbox
                                  onChange={messagesCheckAll}
                                  checked={misCheckAll}
                                ></Checkbox>
                              </Col>
                              <Col span={3} className="">
                                {t("type")}
                              </Col>
                              <Col span={5} className="">
                                {t("from")}
                              </Col>
                              <Col flex="auto" className="">
                                {t("messageTitle")}
                              </Col>
                            </Row>
                          </Col>
                          <Col xs={4} sm={4} md={2} className="">
                            {t("action")}
                          </Col>
                        </Row>
                      }

                      {messages.map((item: IEqMessage, index: number) => (
                        <EqMessage key={index} {...item}></EqMessage>
                      ))}
                    </>
                  )}
                </>
              }
            />
          </div> */}
          <div className="eq-section-divider"></div>

          <div className="eq-section eq-section-training">
            <Skeleton loading={!trainerTrainings} active></Skeleton>

            <Row>
              {trainerTrainings && (
                <Col span={24}>
                  <EqCollapsePanel
                    defaultOpen
                    header={t("trainings")}
                    children={
                      <div>
                        <Row gutter={[16, 16]} className="eq-section-training-filter">
                          <Col xs={24} md={8}>
                            <EqSelect {...trainingTypeProps}></EqSelect>
                          </Col>
                          <Col xs={24} md={8}>
                            <DatePicker
                              placeholder={t("selectDate")}
                              className="eq-filter-date eq-input-info"
                              onChange={(date) => setFromDate(date)}
                              value={fromDate && fromDate.isValid() ? fromDate : null}

                            />
                          </Col>
                          <Col xs={24} md={8}>
                            <EqButton classNames="btn-background-white btn-border-text-orange eq-filter-btn">
                              <FontAwesomeIcon icon={faFilter} className="eq-filter-btn-icon" />
                              {t("filter")}
                            </EqButton>
                          </Col>
                        </Row>
                        <EqActivityTrainingListDashboard

                          eventList={filterTrainings(trainerTrainings)}
                          removeEventFunc={removeTraining}
                          // editEventFunc: editEvent,
                          onCreateOrUpdateActivity={handleOnUpdateTrainerTraining}
                          areas={areas}
                          weekDays={weekDays}
                          population={population}
                          groupPopulation={groupPopulation}
                          trainerStudents={trainerStudents}
                          studentHorsesList={studentHorsesList}

                        ></EqActivityTrainingListDashboard>
                      </div>
                    }
                  />
                </Col>
              )}
            </Row>
          </div>

          <div className="eq-section-divider"></div>

          <div className="eq-section eq-section-training">
            <Skeleton loading={!trainerEvents} active></Skeleton>

            <Row>
              {trainerEvents && (
                ////-------------------//
                <Col span={24}>
                  <EqCollapsePanel
                    defaultOpen
                    header={t("events")}
                    children={
                      <div>
                        <Row gutter={[16, 16]} className="eq-section-training-filter">
                          <Col xs={24} md={8}>
                            <EqSelect {...eventTypeProps}></EqSelect>
                          </Col>
                          <Col xs={24} md={8}>
                            <DatePicker
                             placeholder={t("selectDate")}
                              className="eq-filter-date eq-input-info"
                              onChange={(date) => setFromDate(date)}
                              value={fromDate && fromDate.isValid() ? fromDate : null}
                            />
                          </Col>
                          <Col xs={24} md={8}>
                            <EqButton classNames="btn-background-white btn-border-text-orange eq-filter-btn">
                              <FontAwesomeIcon icon={faFilter} className="eq-filter-btn-icon" />
                              {t("filter")}
                            </EqButton>
                          </Col>
                        </Row>
                        <EqActivityTrainingListDashboard
                          eventList={filterEvents(trainerEvents)}
                          areas={areas}
                          weekDays={weekDays}
                          population={population}
                          groupPopulation={groupPopulation}
                          trainerStudents={trainerStudents}
                          studentHorsesList={studentHorsesList}
                          removeEventFunc={removeEvent}
                          // editEventFunc= editEvent
                          // onCreateOrUpdateActivity={trainerEventDefaults}
                          onCreateOrUpdateActivity={handleOnUpdateEvent}
                        ></EqActivityTrainingListDashboard>
                      </div>}
                  />

                </Col>
              )}
            </Row>
          </div>

          <div className="eq-section-divider"></div>

          {/* ask about that part */}
          {/* <div className="eq-section">
          <Row className="" align="middle">
            <Col flex="auto">
              <div className="eq-db-h2">
                {t("studentsAcceptedUnpaid")}
              </div>
            </Col>
            <div className="eq-financials-header-right">
              <Col className="eq-financials-total">
                {t("totalStudents")} {currentStudentslist?.length}:
              </Col>
              <Col className="eq-financials-link">
                <Link to={FrontEndPoints.trainer.studentBoard}>
                  Students Pages
                </Link>
              </Col>
            </div>
          </Row>
          <Row className="eq-list-header hidden-sm">
            <Col className="eq-fl-th " span={1}>
              <Checkbox
                onChange={finanacialCheckAll}
                checked={fisCheckAll}
              ></Checkbox>
            </Col>
            <Col className="eq-sl-th" xs={8} sm={8} md={6}>
              {t("name")}
            </Col>
            <Col className="eq-sl-th" xs={0} sm={0} md={5}>
              {t("area")}
            </Col>
            <Col className="eq-sl-th" xs={10} sm={10} md={5}>
              {("payments")}
            </Col>
            <Col className="eq-sl-th" xs={0} sm={0} md={5}>
              {t("groups")}
            </Col>
            <Col className="eq-sl-th" xs={4} sm={4} md={2}>
              {t("action")}
            </Col>
          </Row>
          {currentStudentslist?.length > 0 && (
            <>
              {currentStudentslist?.map((item: any, index: number) => (
                <>
                  <EqStudent key={index} {...item}></EqStudent>
                </>
              ))}
            </>
          )}
        </div> */}
        </div>
      </div>
    </AnimationDiv>

  );
};

export default EqTrainerDashboard;
