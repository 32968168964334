import { Alert, Checkbox, Col, DatePicker, Input, Radio, Row, Select, Skeleton, Tabs } from 'antd'
import React, { useEffect, useState } from 'react'
import EqButton from '../eqButton/EqButton'
import "./EqHorseStats.scss"
import { ISelectProps } from '../../shared/Interfaces';
import moment from 'moment';
import Api from '../../shared/AApi';
import { ApiEndpoints } from '../../shared/Common';
import { useTranslation } from "react-i18next";
import { Empty } from 'antd-v5';
import AsyncImage from '../asyncImage/AsyncImage';

import EqCollapse from '../eqCollapse/EqCollapse';


const EqHorseStats = (
) => {

    const horseActivityDateFormat = "MM-DD-YYYY";

    const [loading, setLoading] = React.useState(false);
    
    const { t, i18n } = useTranslation();
    
    const [check, setCheck] = React.useState(false);
    
    var activityFilterInitialState = {
        selectedHorse: null,
        selectedWeek: null,
        horseSelection: [],
        weekSelection: []
    }

    const [horsesActivityTimes, setHorsesActivityTimes] = React.useState({} as any);

    const [activityFilter, setActivityFilter] = React.useState(activityFilterInitialState);

    const [isCollapseAll, setIsCollapseAll] = useState(false);
    const handleIsCollapseAll = (props: boolean) => {
        setIsCollapseAll(props);
    };
    const collapseAll = () => {
        handleIsCollapseAll(!isCollapseAll);
    };
    //filters************
    //#region filters

    const handleHorseChange = (value: any) => {

        setActivityFilter({
            ...activityFilter, selectedHorse: value
        })

        applyfilters(value, activityFilter.selectedWeek);
    };

    const handleWeekChange = (value: any) => {
        setActivityFilter({
            ...activityFilter, selectedWeek: value
        })

        applyfilters(activityFilter.selectedHorse, value);
    };

    const applyfilters = (horseKey: any, weekKey: any, data: any = null) => {

        var filtered = [];

        if (data == null) {
            data = horsesActivityTimes.allData
        }

        if (horseKey && weekKey) {
            filtered = data.map((weekParam: any) => weekParam.filter((horseParam: any) => horseParam.horseId == horseKey && horseParam.weekNumber == weekKey));

        } else if (horseKey) {
            filtered = data.map((weekParam: any) => weekParam.filter((horseParam: any) => horseParam.horseId == horseKey));
        } else if (weekKey) {
            filtered = data.filter((weekParam: any) => weekParam.filter((horseParam: any) => horseParam.weekNumber == weekKey).length);

        } else {
            filtered = [...data];

        }
        setHorsesActivityTimes({ ...horsesActivityTimes, filteredData: filtered })
        return filtered;
    }
    //#endregion filters

    //table************
    //#region table
 
    const formatDuration = (minutes: number): string => {
        if (minutes) {
            if (minutes < 60) {
                return Math.floor(minutes % 60) + 'm';
            } else {
                return Math.floor(minutes / 60) + 'h ' + minutes % 60 + 'm';
            }
        }
        return "-"
    }

    //#endregion table

    useEffect(() => {
        setLoading(true);
        // Api.get(ApiEndpoints.horse.getAllHorsesEventsIncluded).then((response: any) => {
            Api.get(ApiEndpoints.horse.getAllHorseActivityStatsDto).then((response: any) => {
            debugger;
            console.log(response.data);
            sortHorseActivityData(response.data);
            setLoading(false);
        }, error => {
            console.log(error);
            setLoading(false);
            //throw error;
        })
    }, [])

    const sortHorseActivityData = (param: any) => {
        
        const { eventUserGroupActivities, horseTrainings } = param;

        // Combine EventUserGroupActivities and HorseTrainings
        const combinedActivities = [...eventUserGroupActivities, ...horseTrainings];
        // const combinedActivities = [...param];
        var weekSelectData: any = [];
        var horseSelectData: any = [];
        var year = moment().year();
        // this gives an object with dates as keys
        const groups = combinedActivities?.reduce((groups: any, horseObj: any) => {

            const startOfWeek = moment(horseObj.startTime).startOf('week');
            const endOfWeek = moment(horseObj.startTime).endOf('week');
            const date = moment(horseObj.startTime).format(horseActivityDateFormat);
            const weeknumber = moment(horseObj.startTime).week();

            // check if week available in acc
            if (!groups[weeknumber]) {
                groups[weeknumber] = [];
                weekSelectData.push({
                    key: weeknumber,
                    value: `${t('week-')}-${weeknumber}`,
                    weekNumber: weeknumber,
                    startOfWeek: startOfWeek,
                    endOfWeek: endOfWeek,
                })
            }
            var existingObjArr = groups[weeknumber].filter((gwn: any) => gwn.horseId == horseObj.horseId);
            var newObj: any = {}
            //if same week number already exists
            if (existingObjArr.length) {
                newObj = existingObjArr[0]
            } else {
                //if same horse already exists in that week before
                if (!horseSelectData.filter((hsd: any) => hsd.key == horseObj.horseId).length) {
                    horseSelectData.push({
                        key: horseObj.horseId,
                        value: horseObj.horseName
                    })
                }


      // issue there 
      newObj.startOfWeek = startOfWeek
      newObj.endOfWeek = endOfWeek
      newObj.horseId = horseObj.horseId;
      newObj.weekNumber = weeknumber;
      newObj.horseName = horseObj.horseName;
      newObj.imageUrl = horseObj?.horseImageUrl;
      newObj.daysOfWeek = daysOfWeek(year, weeknumber);
            }

      

            //if same day is already added to in week before
            var dayArrFromWeekDays = newObj.daysOfWeek.filter((dw: any) => dw.date == date);

            if (dayArrFromWeekDays.length) {
                dayArrFromWeekDays[0].duration = dayArrFromWeekDays[0].duration + horseObj.duration;
            }

            if (!existingObjArr.length) {
                groups[weeknumber].push(newObj);
            }

            return groups;
        }, {});

        weekSelectData = weekSelectData.sort((one: any, two: any) => (one.weekNumber < two.weekNumber ? -1 : 1));


        const groupArrays = Object.keys(groups).map((weeknumber: any) => {
            return groups[weeknumber]
        });


        var selectedHorse: any = horseSelectData[0]?.key ? horseSelectData[0]?.key : null;
        var selectedWeek: any = weekSelectData[0]?.key ? weekSelectData[0]?.key : null;
        setActivityFilter({
            ...activityFilter,
            weekSelection: weekSelectData,
            horseSelection: horseSelectData,
            selectedWeek: selectedWeek
        });

        var filtered = applyfilters(null, selectedWeek, groupArrays)

        setHorsesActivityTimes({ ...horsesActivityTimes, filteredData: filtered, allData: [...groupArrays], ApiData: null })
    }

    const daysOfWeek = (yearNumber: number, weeknumber: number) => {
        let daysOfInputWeek = [1, 2, 3, 4, 5, 6, 7]
            .map(d => {
                var date = moment(`${yearNumber}-${weeknumber}-` + d, 'YYYY-W-E').format(horseActivityDateFormat);
                return {
                    dayOfWeek: d,
                    date: date,
                    duration: 0
                };
            });
        return daysOfInputWeek;
    }

    const getAllWeeks = () => {
        var weeks: any = [];
        var startDate = moment(new Date(2024, 0, 1)).isoWeekday(8);
        if (startDate.date() == 8) {
            startDate = startDate.isoWeekday(-6)
        }
        var today = moment(new Date(2024, 11, 31)).isoWeekday('Sunday');
        while (startDate.isBefore(today)) {
            let startDateWeek = startDate.isoWeekday('Monday').format(horseActivityDateFormat);
            let endDateWeek = startDate.isoWeekday('Sunday').format(horseActivityDateFormat);
            startDate.add(7, 'days');
            weeks.push([startDateWeek, endDateWeek]);
        }
    }

    const getWeekDay = (index: number) => {
        switch (index) {
            case 0: return t('monday');
            case 1: return t('tuesday');
            case 2: return t('wednesday');
            case 3: return t('thursday');
            case 4: return t('friday');
            case 5: return t('saturday');
            case 6: return t('sunday');
            case 7: return t('total');
            default: return t('day');
        }
    }

    const formatTotal = (daysOfWeek: Array<any>): string => {
        let totalTime = 0;
        daysOfWeek.forEach((day: any) => {
            totalTime += day.duration;
        });
        return formatDuration(totalTime);
    }

    return (
        <div className="horse-management">
            {/* filter */}
            <Row>
                <Col span={24} className="filters">
                    {/* <div className="filters"> */}
                    <Row gutter={[16, 16]} className="filter-type">

                        {/* <div className="event-location"> */}
                        <Col className="location-col " xs={24} sm={24} md={12} lg={11}>
                            <Row className="location-selector">
                                <Col xs={6} sm={5} md={6} lg={5}>
                                    <label className="picker-label">{t("horse")}</label>
                                </Col>
                                <Col className="location-input" flex="auto">
                                    <Select
                                        className="eq-filter-radius eq-input-info"
                                        allowClear
                                        placeholder={t("selectHorse")}
                                        onChange={handleHorseChange}
                                        value={activityFilter.selectedHorse}
                                    >
                                        {activityFilter?.horseSelection?.length && activityFilter?.horseSelection.map((actvty: any) => (
                                            <Select.Option
                                                label={actvty.value}
                                                value={actvty.key}
                                                key={actvty.key}
                                            >
                                                <span>{actvty.value}</span>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                        <Col className="location-col " xs={24} sm={24} md={12} lg={11}>
                            <Row className="location-selector">
                                <Col xs={6} sm={5} md={6} lg={5}>
                                    <label className="picker-label">{t("week")}</label>
                                </Col>
                                <Col className="location-input" flex="auto">
                                    <Select
                                        className="eq-filter-radius eq-input-info"
                                        allowClear={false}
                                        onChange={handleWeekChange}
                                        value={activityFilter.selectedWeek}
                                    >
                                        {activityFilter.weekSelection.map((actvty: any) => (
                                            <Select.Option
                                                label={actvty.value}
                                                value={actvty.key}
                                                key={actvty.key}
                                            >
                                                <span className='week-number'>{actvty.value}</span>
                                                <span className='week-limits'>
                                                    {actvty.startOfWeek && moment(actvty.startOfWeek).format(horseActivityDateFormat)} -
                                                    {actvty.endOfWeek && moment(actvty.endOfWeek).format(horseActivityDateFormat)}
                                                </span>
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* end filter */}

            <div className="loading"><Skeleton loading={loading} active></Skeleton></div>
            {loading == false && horsesActivityTimes?.filteredData?.length ? 
            <Col span={24}>

                {/*table header*/}
                <Row className="eq-fl-row hidden-sm" justify="space-between">

                    <Col className="eq-fl-th" span={5}>
                        {t("name")}
                    </Col>
                    <Col className="eq-fl-th" span={2}>
                        {t("monday")}
                    </Col>
                    <Col className="eq-fl-th" span={2}>
                        {t("tuesday")}
                    </Col>
                    <Col className="eq-fl-th" span={2}>
                        {t("wednesday")}
                    </Col>
                    <Col className="eq-fl-th" span={2}>
                        {t("thursday")}
                    </Col>
                    <Col className="eq-fl-th" span={2}>
                        {t("friday")}
                    </Col>
                    <Col className="eq-fl-th" span={2}>
                        {t("saturday")}
                    </Col>
                    <Col className="eq-fl-th" span={2}>
                        {t("sunday")}
                    </Col>
                    <Col className="eq-fl-th" span={2}>
                        {t("total")}
                    </Col>
                </Row>
                {/*table header*/}

                <div className="hidden-md">
                    <Row justify="start" align="middle" className="eq-list-checkAll">
                        <Col>
                            <EqButton
                                type="button"
                                classNames="btn-background-orange"
                                onClick={collapseAll}
                            >
                                {isCollapseAll ? "Collapse All" : "Expand All"}
                            </EqButton>
                        </Col>
                    </Row>
                </div>

                <div className="horse-task-list">
                    <Col md={12} xs={24} sm={24} className="mb-16"></Col>

                    {horsesActivityTimes?.filteredData?.length && horsesActivityTimes?.filteredData.map((item: any, index: any) => (

                        <>
                            {item.map((horse: any) => (<Row className="eq-f-row hidden-sm" justify="space-between">
                                <Col className="eq-f-td  horse-task-detail" span={5}>
                                    <div className="task-info">
                                        <AsyncImage
                                            className="event-img"
                                            alt="example"
                                            src={horse.imageUrl}
                                        />
                                    </div>
                                    <div>
                                        {horse.horseName}
                                    </div>
                                </Col>
                                {horse.daysOfWeek?.length && horse.daysOfWeek.map((day: any) => (
                                    <Col className="eq-f-td" span={2}>
                                        {formatDuration(day.duration)}
                                    </Col>))}
                                <Col className="eq-f-td" span={2}>
                                    {formatTotal(horse.daysOfWeek)}
                                </Col>
                            </Row>))}

                            {item.map((horse: any) => (<div className="hidden-md">
                                <EqCollapse header={
                                    <Row justify="start" align="middle">
                                        <Col className="" flex="auto">
                                            {horse?.horseName}
                                        </Col>
                                    </Row>


                                } >
                                    <>
                                        <Row className="eq-card-row">
                                            <Col className="eq-card-col-heading" span={24}>
                                                {t("name")}
                                            </Col>
                                            <Col className="eq-card-row horse-task-detail" span={24}>
                                                <div className="task-info">
                                                    <AsyncImage
                                                        className="event-img"
                                                        alt="example"
                                                        src={horse.imageUrl}
                                                    />
                                                </div>
                                                <div>
                                                    {horse.horseName}
                                                </div>
                                            </Col>
                                        </Row>
                                        {horse.daysOfWeek?.length && horse.daysOfWeek.map((day: any, index: any) => (
                                            <><Row className="eq-card-row">
                                                <Col className="eq-card-col-heading" span={24}>
                                                    {getWeekDay(index)}
                                                </Col>
                                                <Col className="eq-card-row horse-task-detail" span={24}>

                                                    <div>
                                                        {day.duration}

                                                    </div>
                                                </Col>
                                            </Row>
                                                <Row>
                                                    <Col flex="auto" className="eq-card-divider"></Col>
                                                </Row></>))}

                                    </>

                                </EqCollapse>

                            </div>))}

                        </>


                    ))}
                </div>

            </Col>:""}
            {loading == false && !horsesActivityTimes?.filteredData?.length && <div className="no-data">
                <Empty description={t("noHorseStatsAvailable")}>
                </Empty>
            </div>}
        </div>
    )
}

export default EqHorseStats

