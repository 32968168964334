import { Col, Row, Typography, Table, Modal } from "antd-v5";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./EqPublicBookingConfirmation.scss";
import AsyncImage from "../../components/custom/asyncImage/AsyncImage";
import EqButton from "../../components/custom/eqButton/EqButton";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "../../components/shared/Api";
import { QuestionType } from "../../components/shared/enums";
import { ticksToMilliSeconds } from "../../components/shared/Common";
import EqPdfInvoice from "../eqPdfInvoice/EqPdfInvoice";
import { pdf, PDFRenderer, PDFViewer } from "@react-pdf/renderer";

const { Text, Title } = Typography;

const EqPublicBookingConfirmation = () => {
    const options = { year: "numeric", month: "long", day: "2-digit" };

    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    const [bookingDataList, setBookingDataList] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility

    // Extract data from URL using URLSearchParams
    const params = new URLSearchParams(location.search);
    const success = params.get("success");
    const userId = params.get("userId");
    const sessionId = params.get("sessionId");
    const connectAccountId = params.get("connectAccountId");

    const handleDownloadPDF = async () => {
        // Generate the PDF Blob
        const blobFile = await pdf(<EqPdfInvoice bookingDataList={bookingDataList} />).toBlob(); // Generate PDF as Blob

        // Save PDF to backend
        const formData = new FormData();
        // formData.append('file', blobFile, 'invoice.pdf'); // Name the file as needed
        formData.append("EventFile", blobFile, `${firstBooking.id}.pdf`);
        try {
            const response = await Api.post("EventFile/UploadEventReceipt", formData);
            console.log("Invoice saved successfully:", response.data);
        } catch (error) {
            console.error("Error saving invoice:", error);
        }
    };

    // API call to retrieve session details
    // useEffect(() => {
    //     debugger
    //     if (bookingDataList.length>0) {
    //         handleDownloadPDF();
    //     }
    // }, [bookingDataList]);

    // API call to retrieve session details
    useEffect(() => {
        if (success && sessionId && connectAccountId) {
            Api.get(
                `/api/PublicEventBooking/confirmation?sessionId=${sessionId}&userId=${userId}&connectAccountId=${connectAccountId}`
            )
                .then((response) => {
                    // debugger;
                    setBookingDataList(response.data); // Assuming response data contains a list of booking info
                    setLoading(false);
                    // handleDownloadPDF();
                })
                .catch((error) => {
                    console.error("Error fetching booking confirmation:", error);
                    setLoading(false);
                });
        }
    }, [success, sessionId, userId, connectAccountId]);

    if (loading) {
        return <div>{t("Loading...")}</div>;
    }

    // Check if bookingDataList has data before accessing the first item
    const firstBooking = bookingDataList.length > 0 ? bookingDataList[0] : null;

    // Prepare data source for the table
    // const dataSource = bookingDataList.map((order, idx) => ({
    //     key: `${idx}`, // Unique key for each row
    //     item: order.eventClass.title,
    //     price: order.eventClass.price,
    //     vat: order.eventClass.vat,
    // }));

    const dataSource = bookingDataList.map((order, idx) => {
        const { title, price, vat } = order.eventClass;
        const adjustedPrice = price - price * (vat / 100);

        return {
            key: `${idx}`, // Unique key for each row
            item: title,
            // price: adjustedPrice.toFixed(2), // Keep two decimal places if needed
            price: price, // Keep two decimal places if needed
            vat,
        };
    });

    // Table columns for the ticket details
    const columns = [
        {
            title: t("items"),
            dataIndex: "item",
            key: "item",
            onCell: () => ({ style: { minWidth: "150px" } }), // Set minimum width for "items" column
        },
        {
            title: t("price"),
            dataIndex: "price",
            key: "price",
            width: "20%",
            onCell: () => ({ style: { minWidth: "100px" } }), // Set minimum width for "price" column
        },
        {
            title: t("vat"),
            dataIndex: "vat",
            key: "vat",
            width: "20%",
            onCell: () => ({ style: { minWidth: "80px" } }), // Set minimum width for "vat" column
            render: (vat: any) => `${vat}%`,
        },
    ];

    // Function to handle modal visibility
    const handleModalOpen = () => {
        setIsModalVisible(true); // Show the modal
    };

    const handleModalClose = () => {
        setIsModalVisible(false); // Hide the modal
    };

    return (
        <div className="eq-container-fluid eq-public-booking-confirmation">
            <Row>
                <Col xs={24} md={24} className="image-column">
                    <AsyncImage className="confirm-image" src="publicEvent/Two-horse-run.png" />
                </Col>

                <Col xs={24} md={24} className="image-column">
                    <Title className="text-center" level={1}>
                        {t("bookingConfirmation")}
                    </Title>
                </Col>
            </Row>

            <Row gutter={[16, 16]} style={{ margin: "20px auto" }}>
                <Col>
                    {/* Left section for booking confirmation details and table */}
                    {firstBooking && (
                        <div style={{marginTop:"20px"}}>
                            <div className="">
                                {t("enrolledToText1")}{" "}
                                {firstBooking.eventClass.alternative.publicEvent.title} {t("at")}{" "}
                                {firstBooking.eventClass.alternative.publicEvent?.user?.firstName +
                                    " " +
                                    firstBooking.eventClass.alternative.publicEvent?.user?.lastName}
                                .
                            </div>
                            <div>{t("enrolledToText2")}:</div>
                            {/* <br /> */}
                            {/* <Title level={2}>{t("details")}</Title> */}
                            <div className="">
                                {t("bookingId")}:{" "}
                                {firstBooking.eventClass.alternative.publicEvent.id}
                            </div>
                            {/* <br /> */}
                            <div className="">
                                {t("bookingDate")}:{" "}
                                {new Date(
                                    ticksToMilliSeconds(firstBooking?.createdDate)
                                ).toLocaleDateString(t("dateLang"), options as any)}
                            </div>
                            {/* 
                            <br />
                            <br /> */}

                            {/* <Title level={4}>{t("attendeeInfo")}</Title> */}
                            <div className="">
                                {t("nameOfAttendee")}:{" "}
                                {firstBooking.attendUsingName
                                    ? firstBooking.attendUsingName
                                    : firstBooking.user?.firstName +
                                      " " +
                                      firstBooking.user?.lastName}
                            </div>
                            <br />
                            <br />
                        </div>
                    )}
                </Col>
                <Col>
                    {/* Right section for the Question Answers */}

                    {/* Questions and Answers for each order */}
                    {bookingDataList.map((order, idx) => (
                        <div key={idx} className="question-answer-box ">
                            {order.answers && order.answers.length > 0 && (
                                <div className="question-answer-item">
                                    <Title level={4}>{order.eventClass.title}</Title>
                                    <div>
                                        {order.answers.map((answer: any, qIdx: number) => (
                                            <div key={qIdx} className="qa-item">
                                                <Text strong>{answer.question.questionText}</Text>
                                                <br />
                                                {answer.question.questionType ===
                                                QuestionType.Text ? (
                                                    <Text>{answer.answerText}</Text>
                                                ) : (
                                                    answer.selectedOptions.map(
                                                        (option: any, oIdx: number) => (
                                                            <Text key={oIdx}>
                                                                {oIdx !== 0 && <br />}
                                                                {option.option.optionText}
                                                            </Text>
                                                        )
                                                    )
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    ))}
                </Col>
            </Row>

            <Row style={{ margin: "20px auto"  }}>


               
                <Col span={24}>
                    <Table
                        className="booking-table"
                        dataSource={dataSource}
                        columns={columns}
                        pagination={false}
                    />
                </Col>
                
                <Col  style={{marginTop:"5px", marginLeft: "auto" }}> {/* Adjust margin as needed */}
        <Row gutter={20}>
            <Col >
                <div> <Text strong > {t("totalAmountExcludingVat")}: </Text> </div>
                <div><Text strong > {t("totalAmount")}:  </Text></div>
            </Col>
            <Col style={{ textAlign: "right" }}>
                <div>
                    {bookingDataList.reduce(
                        (total, order) =>
                            total +
                            order.eventClass.price -
                            order.eventClass.price * (order.eventClass.vat / 100),
                        0
                    ).toFixed(2)}
                </div>
                <div>
                    {bookingDataList.reduce(
                        (total, order) => total + order.eventClass.price,
                        0
                    ).toFixed(2)}
                </div>
            </Col>
        </Row>
        {/* <Row>
            <Col >
                <Text>{t("Total amount")}:</Text>
            </Col>
            <Col style={{ textAlign: "right" }}>
                <Text>
                    {bookingDataList.reduce(
                        (total, order) => total + order.eventClass.price,
                        0
                    ).toFixed(2)}
                </Text>
            </Col>
        </Row> */}
    </Col>
              
            </Row>

            {/* <Row style={{ margin: "auto" }}>
                <Col>
                    <Title level={5}>
                        {t("Total amount excluding vat")}:{" "}
                        {bookingDataList.reduce(
                            (total, order) =>
                                total +
                                order.eventClass.price -
                                order.eventClass.price * (order.eventClass.vat / 100),
                            0
                        )}
                    </Title>
                </Col>
            </Row>
            <Row justify={"center"} style={{ margin: "auto" }}>
                <Col style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Title level={5}>
                        {t("totalAmount")}:{" "}
                        {bookingDataList.reduce(
                            (total, order) => total + order.eventClass.price,
                            0
                        )}
                    </Title>
                </Col>
            </Row> */}

            <div style={{ margin: "auto" }}>
                {t("refundMsg1")}
                {t("refundMsg2")}. <br />
                {t("refundMsg3")} {firstBooking.eventClass.alternative.publicEvent?.user?.email}{" "}
                {t("or")} {firstBooking.eventClass.alternative.publicEvent?.user?.phoneNumber}
                .
                <br />
                <EqButton
                    type="primary"
                    style={{ margin: "15px 0px" }}
                    onClick={handleModalOpen} // Open the modal on click
                >
                    {t("downloadPdf")}
                </EqButton>
            </div>

            {/* <Row gutter={[16, 16]}>
                <Col xs={24} md={24} className="image-column">
                    <AsyncImage className="confirm-image" src="publicEvent/Two-horse-run.png" />
                </Col>

                <Col xs={24} md={24}>
                    <Title className="text-center" level={1}>
                        {t("bookingConfirmation")}
                    </Title>
                    {firstBooking ? (
                        <>
                            <div className="text-center">
                                {t("enrolledToText1")}{" "}
                                {firstBooking.eventClass.alternative.publicEvent.title} {t("at")}{" "}
                                {firstBooking.eventClass.alternative.publicEvent?.user?.firstName +
                                    " " +
                                    firstBooking.eventClass.alternative.publicEvent?.user?.lastName}
                                . {t("enrolledToText2")}:
                            </div>
               
                            <div className="text-center">
                                {t("bookingId")}:{" "}
                                {firstBooking.eventClass.alternative.publicEvent.id}
                            </div>
                       
                            <div className="text-center">
                                {t("bookingDate")}:{" "}
                                {new Date(
                                    ticksToMilliSeconds(firstBooking?.createdDate)
                                ).toLocaleDateString(t("dateLang"), options as any)}
                            </div>
                            
                     
                            <div className="text-center">
                                {t("nameOfAttendee")}:{" "}
                                {firstBooking.attendUsingName
                                    ? firstBooking.attendUsingName
                                    : firstBooking.user?.firstName +
                                      " " +
                                      firstBooking.user?.lastName}
                            </div>
                            <br />
                            <br />

                           
                            {bookingDataList.map((order, idx) => (
                                <div key={idx} className="question-answer-box text-center">
                                    {order.answers && order.answers.length > 0 && (
                                        <div className="question-answer-item">
                                            <Title level={4}>{order.eventClass.title}</Title>
                                            
                                            <div>
                                                {order.answers.map((answer: any, qIdx: number) => (
                                                    <div key={qIdx} className="qa-item">
                                                        <Text strong>
                                                            {answer.question.questionText}
                                                        </Text>
                                                        <br />
                                                        {answer.question.questionType ===
                                                        QuestionType.Text ? (
                                                            <Text>{answer.answerText}</Text>
                                                        ) : (
                                                            answer.selectedOptions.map(
                                                                (option: any, oIdx: number) => (
                                                                    <Text key={oIdx}>
                                                                        {oIdx !== 0 && <br />}
                                                                        {option.option.optionText}
                                                                    </Text>
                                                                )
                                                            )
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            ))}

                            <Title level={4}>{t("tickets")}</Title>
                            <Row justify={"center"}>
                                <Col xs={24} md={18}>
                                    <Table
                                        className="booking-table"
                                        dataSource={dataSource}
                                        columns={columns}
                                        pagination={false}
                                    />
                                </Col>
                            </Row>

                            <Row justify={"center"} style={{ margin: "10px" }}>
                                <Col
                                    xs={24}
                                    md={18}
                                    style={{ display: "flex", justifyContent: "flex-end" }}
                                >
                                    <Title level={5}>
                                        {t("Total amount excluding vat")}:{" "}
                                        {bookingDataList.reduce(
                                            (total, order) =>
                                                total +
                                                order.eventClass.price -
                                                order.eventClass.price *
                                                    (order.eventClass.vat / 100),
                                            0
                                        )}
                                    </Title>
                                </Col>
                            </Row>

                            <Row justify={"center"} style={{ margin: "10px" }}>
                                <Col
                                    xs={24}
                                    md={18}
                                    style={{ display: "flex", justifyContent: "flex-end" }}
                                >
                                    <Title level={5}>
                                        {t("totalAmount")}:{" "}
                                        {bookingDataList.reduce(
                                            (total, order) => total + order.eventClass.price,
                                            0
                                        )}
                                    </Title>
                                </Col>
                            </Row>
                            <br />
                            <div  style={{ margin: "auto" }}>
                                {t("refundMsg1")}
                                {t("refundMsg2")}.{" "}
                            </div>
                       
                            <Row  style={{ margin: "auto" }}>
                                {t("refundMsg3")}{" "}
                                {firstBooking.eventClass.alternative.publicEvent?.user?.email}{" "}
                                {t("or")}{" "}
                                {firstBooking.eventClass.alternative.publicEvent?.user?.phoneNumber}
                                .
                            </Row>

             
                            <Row >
                                <EqButton
                                    type="primary"
                                    style={{ margin: "15px" }}
                                    onClick={handleModalOpen} // Open the modal on click
                                >
                                    {t("downloadPdf")}
                                </EqButton>
                            </Row>
                        </>
                    ) : (
                        <Text>{t("No booking information available.")}</Text>
                    )}
                </Col>
            </Row> */}

            {/* Modal for PDF Invoice */}

            <Modal
                title={t("downloadPdf")}
                open={isModalVisible}
                onCancel={handleModalClose} // Close the modal
                footer={null}
                width={"80%"} // Adjust width as needed
            >
                <PDFViewer style={{ width: "100%", maxWidth: "80vw", height: "80vh" }}>
                    <EqPdfInvoice bookingDataList={bookingDataList} />
                </PDFViewer>
            </Modal>
        </div>
    );
};

export default EqPublicBookingConfirmation;
