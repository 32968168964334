import React, { useEffect } from "react";
import './EqTrainingFilter.scss';
import { Row, Col } from 'antd';
import EqDatePicker, { IDatePickerOptions } from '../../custom/eqDatePicker/EqDatePicker';
import moment from 'moment';
import EqSelect from '../../custom/eqSelect/EqSelect';
import { ISelectProps } from '../../shared/Interfaces';
import Api from '../../shared/Api';
import { ApiEndpoints, GetProfessionalsMessage, GetEventMessage } from '../../shared/Common';
import { EqDisciplineType} from '../../shared/enums';
import { useTranslation } from "react-i18next";

export interface IEqtrainingFilter {
    searchChange: (searchData: any) => void,
}
const EqTrainingFilter = ({ searchChange,...rest }: IEqtrainingFilter) => {
    const [searchData, setSearchData] = React.useState({ date: moment(), trainer: "", location: "", type: "" });
    const [areas, setAreas] = React.useState([]);
    const [eventTypes, setEventTypes] = React.useState([] as any);
    const [trainers, setTrainers] = React.useState([] as any);
    const [isError, setIsError] = React.useState(false);
    const [message, setMessage] = React.useState("");

    const { t } = useTranslation();
    const getAreas = () => {

        Api.get(ApiEndpoints.Location.GetUserAreaOptions).then(response => {
            if (response && response.status == 200) {
                setIsError(false);
                setMessage("");
                setAreas(response.data);
            }
            else {
                setIsError(true);
                setMessage(GetEventMessage(response.status));
            }
        },
            error => {
                setIsError(true);
                setMessage(GetEventMessage(error.response.status));
            });
    }
    const createEventTypes = () => {
        var types = [];

        for (var x in EqDisciplineType) {
            if (isNaN(Number(x)) == false) {
                types.push({ key: x, value: EqDisciplineType[x] });
            }
        }
        setEventTypes(types);

    }
    const getTrainers = () => {
        Api.get(ApiEndpoints.getTrainers).then(response => {
            if (response && response.status == 200) {
                var dto = [] as any;
                response.data?.map((item: any) => {

                    dto.push({
                            key: item.key.toString(),
                            value: item.firstName || item.userName
                    });
                });
                console.log(dto);
                setTrainers(dto);

                setIsError(false);
                setMessage(GetProfessionalsMessage(response.status));
            }
        },
            error => {
                setIsError(true);
                setMessage(GetProfessionalsMessage(error.response.status));
            });
    }
    const dateProps: IDatePickerOptions = {
        isSuffixIcon: true,
        isBordered: true,
        defaultValue: searchData?.date,
        placeHolder: t("date"),
        classNames: "eq-event",
        onChangeFunc: (date: any, dateString: string) => {
            setSearchData({ ...searchData, date: moment(date) });
            searchChange({ ...searchData, date: moment(date) });
        }
    }
    const areaSelectProps: ISelectProps = {
        isTag: false,
        placeholder: t("selectArea"),
        defaultSelectedValue: searchData.location==""?null:searchData.location,
        icon: [<span className=""><img alt="" src="/caret-down.svg" /></span>],
        extraHeight: true,
        options: areas,
        changeFunc: (value: string) => {
            setSearchData({ ...searchData, location: value });
            searchChange({ ...searchData, location: value });
        }
    }
    const typeSelectProps: ISelectProps = {
        isTag: false,
        placeholder: "Select training type",
        defaultSelectedValue: searchData.type==""?null:searchData.type,
        icon: [<span className=""><img alt="" src="/caret-down.svg" /></span>],
        extraHeight: true,
        options: eventTypes,
        changeFunc: (value: string) => {
            setSearchData({ ...searchData, type: value });
            searchChange({ ...searchData, type: value });
        }
    }
    const trainerSelectProps: ISelectProps = {
        isTag: false,
        placeholder: t("selectTrainer"),
        defaultSelectedValue: searchData.trainer ==""?null:searchData.trainer,
        icon: [<span className=""><img alt="" src="/caret-down.svg" /></span>],
        extraHeight:true,
        options: trainers,
        showSearch: true,
        optionFilterProp: "label",
        filterOption:true,
        changeFunc: (value: string) => {
            setSearchData({ ...searchData, trainer: value });
            searchChange({ ...searchData, trainer: value });
        }
    }
    useEffect(() => {
        createEventTypes();
        getAreas();
        getTrainers();
    }, []);
    return (
        <>
            <div className="eq-training-filters">
                <Row>
                    <Col xs={24} sm={24} md={6} className="filter-col">
                    <EqDatePicker {...dateProps}></EqDatePicker>
                </Col>
                    <Col xs={24} sm={24} md={6} className="filter-col">
                    <EqSelect {...areaSelectProps} />
                </Col>
                    <Col xs={24} sm={24} md={6} className="filter-col">
                    <EqSelect {...trainerSelectProps} />
                </Col>
                    <Col xs={24} sm={24} md={6} className="filter-col">
                    <EqSelect {...typeSelectProps} />
                </Col>
                </Row>
                </div>
            </>
        )
};
export default EqTrainingFilter;