import React, { useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import './EqDatePicker.scss';
import { dateFormat } from '../../shared/Common';

export interface IDatePickerOptions {
    label?:any,
    allowClear?: boolean;
    isSuffixIcon?: boolean;
    isBordered?: boolean;
    isDisabled?: boolean;
    defaultValue?: any;
    placeHolder?: string;
    classNames?: string;
    disabledDateFunc?: any;
    showTime?:any;
    dateFormat?:any;
    onChangeFunc: (date: any, dateString: string) => void;
}

const EqDatePicker = ({label, isSuffixIcon, isBordered, isDisabled, defaultValue, placeHolder, classNames, disabledDateFunc,showTime,dateFormat, onChangeFunc }: IDatePickerOptions) => {
    //console.log(defaultValue);
    const defaultDateFormat = 'DD/MM/YYYY';
   
    const dateChanged = (date: any, dateString: string) => {
        console.log(dateString);
        onChangeFunc(date, dateString);
    }

    return (
        <>
            <div className={`eq-date-pick ${classNames ? classNames : ""}`} >
                {label && label.length > 0 && <label className="eq-label">{label}</label>}

                <DatePicker
                    showTime={showTime}
                    placeholder={placeHolder}
                    format={dateFormat?dateFormat:defaultDateFormat}
                    allowClear={false}
                    suffixIcon={isSuffixIcon ? <span className=""><img alt="" src="/Calendar-alt.svg" /></span> : null}
                    bordered={isBordered}
                    value={defaultValue?moment(defaultValue):null}
                    disabled={isDisabled ? true : false}
                    disabledDate={disabledDateFunc}
                    onChange={dateChanged}
                />
                </div>
            </>
        );
}
export default EqDatePicker;
// {/*defaultValue={defaultValue ? moment(defaultValue).format(dateFormat) : null as any}*/}