import React, { useState } from 'react'
import './EqRegistrationForm.scss';
import { Col, Progress, Row, Tooltip, Typography } from 'antd';
import { EqInput, EqInputPassword } from '../../custom/eqInput/EqInput';
import { CheckOutlined, EyeInvisibleOutlined, EyeTwoTone, UnlockFilled, UnorderedListOutlined, UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

export interface IEqRegistrationForm {
    formData: any
    setFormData: any
}
const EqRegistrationForm = ({ formData, setFormData }: IEqRegistrationForm) => {
    var defaultFeedback = { error: "", success: "" };
    const { Text, Title } = Typography;
    const { t } = useTranslation();
    const [feedback, setFeedback] = useState(defaultFeedback) as any;
    const [progressPercent, setProgressPercent] = useState(0);
    const [passwordCriteria, setPasswordCriteria] = useState({
        minLength: false,
        capitalLetter: false,
        specialCharOrNumber: false,
    });

    // const validateAndSetEmail = (evt: any): void => {
    //     // validateEmails(evt.target.value, formData.repeatEmail);

    //     setFormData({ ...formData, email: evt.target.value });
    // };

    const validateAndSetEmail = (evt: any): void => {
        const email = evt.target.value;
        // const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
        // if (!emailRegex.test(email)) {
        //     showError("emailError");
        // } else {
        //     setFeedback(defaultFeedback);
        // }
    
        setFormData({ ...formData, email: email });
    };
    


    const showError = (msg: string) => {
        setFeedback({ ...feedback, error: msg });
    };

    const validateAndSetRepeatEmail = (evt: any): void => {
        // validateEmails(evt.target.value, formData.email);

        setFormData({ ...formData, repeatEmail: evt.target.value });
    };

    // const validateEmails = (emailTxt: string, repeatEmailTxt: string) => {
    //     if (emailTxt != repeatEmailTxt) {
    //         showError(t("emailMatchError"));
    //     } else {
    //         setFeedback(defaultFeedback);
    //     }

    //     return true;
    // };

    // const validateAndSetPassword = (evt: any): void => {
    //     const passwordLength = evt.target.value.length;

    //     // Only update progress if password length is less than or equal to 6
    //     if (passwordLength <= 6) {
    //         if (passwordLength > formData.password.length) {
    //             increase();
    //         } else {
    //             decline();
    //         }
    //     }

    //     if (passwordLength < 6) {
    //         showError("pwdGrtThanFive");
    //     } else {
    //         setFeedback(defaultFeedback);
    //     }

    //     setFormData({ ...formData, password: evt.target.value });
    // };

    const validateAndSetPassword = (evt: any): void => {
        const password = evt.target.value;
        const passwordLength = password.length;
        const hasCapitalLetter = /[A-Z]/.test(password);
        const hasSpecialCharOrNumber = /[0-9!@#$%^&*]/.test(password);

        const passIsValid= passwordLength && hasCapitalLetter && hasSpecialCharOrNumber
        setPasswordCriteria({
            minLength: passwordLength >= 8,
            capitalLetter: hasCapitalLetter,
            specialCharOrNumber: hasSpecialCharOrNumber,
        });



        
        // Calculate the progress percentage based on password length (up to 8 characters)
        const progress = Math.min((passwordLength / 8) * 100, 100);
        setProgressPercent(progress);
        


        setFormData({ ...formData, password: password, passIsValid });
    };



    const validateAndSetRepeatPassword = (evt: any): void => {
        
        setFormData({ ...formData, repeatPassword: evt.target.value });
        // validateRepeatPassword(evt.target.value, formData.password);

    };
    // const validateRepeatPassword = (psd: string, repeatPsd: string) => {
    //     if (psd != repeatPsd) {
    //         showError("pwdMatchError");
    //     } else {
    //         setFeedback(defaultFeedback);
    //     }

    //     return true;
    // };
    return (
        <div className='eq-registrationForm'>
            <Row gutter={[16, 16]}>
            <Col span={24}>
                    <EqInput
                        label={t("fullName")}
                        name="fullName" 
                        placeholder={t("fullName")}
                        prefix={<UserOutlined />}
                        value={formData.fullName}
                        onChange={(e: any) =>
                            setFormData({ ...formData, fullName: e.target.value })
                        }
                        />
                </Col>

              {formData.userType===8?
             
             <Col span={24}>
                    <EqInput
                        label={t("companyName")}
                        name="companyName"
                        placeholder={t("companyName")}
                        prefix={<UserOutlined />}
                        value={formData.companyName}
                        onChange={(e: any) =>
                            setFormData({ ...formData, companyName: e.target.value })
                        }
                        />
                </Col>
                    :"" }
               
               
                <Col span={24}>
                    <EqInput
                        label={t("email")}
                        name="email"
                        type="email"
                        placeholder={t("emailAddress")}
                        prefix={<UserOutlined />}
                        value={formData.email}
                        onChange={(e: any) => validateAndSetEmail(e)}
                    />
                </Col>
                {/* <Col span={24}>
                    <EqInput
                        label={t("repeatEmail")}
                        name="email"
                        type="email"
                        placeholder={t("repeatEmail")}
                        prefix={<UserOutlined />}
                        value={formData.repeatEmail}
                        onChange={(e: any) => validateAndSetRepeatEmail(e)}
                        onKeyUp={(e: any) => validateAndSetRepeatEmail(e)}
                    />
                </Col> */}
                <Col span={24}>
                    <EqInputPassword
                        label={t("password")}
                        name="password"
                        value={formData.password}
                        placeholder={t("inputPassword")}
                        iconRender={(visible: any) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        prefix={<UnlockFilled />}
                        onChange={(e: any) => validateAndSetPassword(e)}
                    />
                </Col>
                
                <Col span={24}>
                    <EqInputPassword
                        label={t("repeatPassword")}
                        id="repeatPassword"
                        name="repeatPassword"
                        placeholder={t("repeatPassword")}
                        iconRender={(visible: any) =>
                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                        }
                        prefix={<UnlockFilled />}
                        value={formData.repeatPassword}
                        onChange={(e: any) =>
                            setFormData({ ...formData, repeatPassword: e.target.value })
                        }
                        onKeyUp={(e: any) => validateAndSetRepeatPassword(e)}
                    />
                </Col>
                  {/* Password Criteria */}
                  <Col span={24}>
                  {formData.password.length > 0 && (
    <div className="password-criteria">
        <Text className={passwordCriteria.minLength ? 'valid' : 'invalid'}>
            {passwordCriteria.minLength ? <CheckOutlined /> : <span className="dot">•</span>} {t("passwordMin")} 
        </Text>
        <Text className={passwordCriteria.capitalLetter ? 'valid' : 'invalid'}>
            {passwordCriteria.capitalLetter ? <CheckOutlined /> : <span className="dot">•</span>} {t("passwordCap")}
        </Text>
        <Text className={passwordCriteria.specialCharOrNumber ? 'valid' : 'invalid'}>
            {passwordCriteria.specialCharOrNumber ? <CheckOutlined /> : <span className="dot">•</span>} {t("passwordNum")}
        </Text>
    </div>)}
</Col>

                <Col span={24}>
                    <Tooltip title={progressPercent}>
                        <Progress
                            percent={progressPercent}
                            success={{ percent: progressPercent, strokeColor: "#FF8838" }}
                            type="line"
                            strokeWidth={4}
                            trailColor="#FFFFFF"
                            strokeLinecap="round"
                            showInfo={false}
                        />
                    </Tooltip>
                </Col>

                <Col span={24}>
                    {feedback.error.length > 0 && (
                        <Text id="merrorMsg" className="show invalid">
                            {t(feedback.error)}
                        </Text>
                    )}
                    {feedback.success.length > 0 && (
                        <Text id="msuccessMsg" className="show valid">
                            {feedback.success}
                        </Text>
                    )}
                </Col>

            </Row>
        </div>
    )
}

export default EqRegistrationForm