import React, { useEffect, useState } from "react";
import { Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { useTranslation } from "react-i18next";
import Api from "../../components/shared/Api";

interface EqUploadTermsProps {
    onUploadSuccess: (newImage: any) => void;
    onRemove: () => void;
    defaultFile?: any; // Optional if you want to show default files
    disabled?: boolean; 
}

const EqUploadTerms: React.FC<EqUploadTermsProps> = ({
    onUploadSuccess,
    onRemove,
    defaultFile,
    disabled
}) => {
    const [fileObj, setFileObj] = useState<any | undefined>(defaultFile);
    const { t } = useTranslation();


   
  useEffect(() => {
    
    // Set the default image if passed
    if (defaultFile) {
        setFileObj(defaultFile);
    }
  }, [defaultFile]);

    const handleChangeFile = (info: any) => {
        if (info.file.status === "uploading") {
            return;
        }
        if (info.file.originFileObj) {
            UploadToServer(info.file.originFileObj);
        } else {
            UploadToServer(info.file);
        }
    };

    const UploadToServer = (file: any) => {
        const form = new FormData();
        form.append("EventFile", file);

        Api.post("EventFile/UploadEventFile", form)
            .then((response: any) => {
                const newFileObj = {
                    uid: file.uid,
                    // name: file.name,
                    name: t("eventTerms"),
                    status: "done",
                    url: response.data,
                };
                setFileObj(newFileObj);
                onUploadSuccess(response.data);
            })
            .catch(() => {
                message.error("Error Occurred while uploading file");
            });
    };

    const handleDeleteFile = () => {
        setFileObj(null);
        onRemove();
    };

    return (
        <Upload
            disabled={disabled}
            fileList={fileObj ? [fileObj] : []}
            customRequest={handleChangeFile}
            onRemove={handleDeleteFile}
        >
            <Button 
            disabled={disabled}
            
            icon={<UploadOutlined />}>{t("uploadTerms")}</Button>
        </Upload>
    );
};

export default EqUploadTerms;
