import React from 'react';
import './EtTag.scss';
import { Tag } from 'antd-v5';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IEtTags {
	children?: string
	color?: string
	className?: string
	border?: boolean
	icon?: any
	onClick?: any

}
const EtTag = ({ children, color = '#FF8838', className, border = false, icon, onClick, ...rest }: IEtTags) => {
	return (
		<div className='rg-tag-body'>
			<Tag className={`tag  ${(className != null) || ''}`}
				onClick={onClick}
				style={{ color, background: `${color}15`, border: `${border ? `1px solid ${color}` : ''}` }} {...rest}>
				{icon && 
				 <span className='tag-icon' >
					{icon}
				</span>
				}
				
				{children}
			</Tag>
		</div>
	);
};

export default EtTag;
