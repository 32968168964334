import * as React from "react";
import "./EqActivityStudentView.scss";
import { Row, Col, Input } from "antd";
import { useTranslation } from "react-i18next";
import {
  ApiEndpoints,
  GetEventMessage,
  ValidateIsStudent,
} from "../../shared/Common";
import EqToggle, { IEqToggle } from "../../custom/eqToggle/EqToggle";
import Api from "../../shared/Api";
import { IStudentGroupDto } from "../../shared/interfaces/IStudentGroupDto";
import { EqDisciplineType, EqEventAccessType } from "../../shared/enums";
import EqTrainingStudentView from "./eqTrainingStudentView/EqTrainingStudentView";
import EqEventStudentView from "./eqEventStudentView/EqEventStudentView";
import { useAuthState } from "../../context/Context";

export interface IEqActivityStudentView {
  event?: any;
  areas?: any;
  weekDays?: any;
  trainerStudents?: any;
  studentHorsesList?: any;
  population?: any;
  groupPopulation?: any;
  onCreateOrUpdateActivity?: any;
}
const EqActivityStudentView = ({
  areas,
  weekDays,
  event,
  trainerStudents,
  studentHorsesList,
  population,
  groupPopulation,
  onCreateOrUpdateActivity,
}: IEqActivityStudentView) => {
  const { TextArea } = Input;
  const gutter = { xs: 8, sm: 16, md: 24, lg: 32 };
  const userDetails: any = useAuthState();
  const isStudent: boolean = ValidateIsStudent(userDetails);
  const [activityTitle, setActivityTitle] = React.useState("");

  const [isError, setIsError] = React.useState(false);
  const [isSuccess, setSuccess] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const { t } = useTranslation();
  const [value, setValue] = React.useState(1);

  const [imagesData, setImagesData] = React.useState([]) as any;
  // const [areas, setAreas] = React.useState([]) as any;
  // const [population, setPopulation] = React.useState([]) as any;
  // const [groupPopulation, setGroupPopulation] = React.useState([]) as any;

  const [eventTypes, setEventTypes] = React.useState({
    privateDiscipline: [],
    publicDiscipline: [],
    editOpenDiscipline: [],
    toggleOptions: [],
  } as any);

  const [eventAccessTypes, setEventAccessTypes] = React.useState({
    allAccessTypes: [],
    privateOnly: [],
    toggleOptions: [],
  } as any);
  const [eventKey, setEventKey] = React.useState(0);

  const [isPublic, setIsPublic] = React.useState() as any;
  const [isOpenEvent, setIsOpenEvent] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [isPaymentDeadline, setIsPaymentDeadline] = React.useState(true);
  //for private event

  const [formData, setFormData] = React.useState({}) as any;

  const setEditFormData = (data: any) => {
    if (data != undefined) {
      return {
        accessType: data.accessType as EqEventAccessType,
      };
    } else {
      return {
        accessType: EqEventAccessType.Private + "",
      };
    }
  };

  /////////////

  const setEventDefaults = () => {
    setFormData({
      accessType: EqEventAccessType.Private + "",
    });

    setEventAccessAndDisciplineTypes(
      false,
      EqEventAccessType.Private,
      EqDisciplineType.Dressage
    );
    setIsPublic(false);
    setIsPaymentDeadline(true);
    setIsOpenEvent(false);
  };



  const getImages = () => {
    Api.get("image/getEventImages").then(
      (response) => {
        if (response.data && response.data.length) {
          setImagesData(response.data);

          // setUploadState({
          //     selectedKey: response.data[0].key,
          //     fileList: response.data
          // });
          // props.selected(response.data[0]);
        }
      },
      () => {
        setIsError(true);
        setMessage("Error Occurred while fetching images from server");
      }
    );
  };

  // const getPopulation = () => {
  //   Api.get(ApiEndpoints.eventGetPopulation).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setMessage("");
  //         setPopulation(response.data);
  //         //console.table(population);
  //       } else {
  //         setIsError(true);
  //         setMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  // const getGroupPopulation = () => {
  //   Api.get(ApiEndpoints.eventGetGroupPopulation).then(
  //     (response) => {
  //       if (response && response.status == 200) {
  //         setIsError(false);
  //         setMessage("");
  //         setGroupPopulation(response.data);
  //         //console.table(population);
  //       } else {
  //         setIsError(true);
  //         setMessage(GetEventMessage(response.status));
  //       }
  //     },
  //     (error) => {
  //       setIsError(true);
  //       setMessage(GetEventMessage(error.response.status));
  //     }
  //   );
  // };

  const createEventTypes = () => {
    var privatetypes = [];
    var editPrivatTypes = [];
    var publictypes = [];

    if (isEdit == false) {
      privatetypes.push({
        key: EqDisciplineType.Dressage,
        value: EqDisciplineType[EqDisciplineType.Dressage],
      });
      privatetypes.push({
        key: EqDisciplineType.Jumping,
        value: EqDisciplineType[EqDisciplineType.Jumping],
      });
      privatetypes.push({
        key: EqDisciplineType.Open,
        value: EqDisciplineType[EqDisciplineType.Open],
      });
    } else {
      privatetypes.push({
        key: EqDisciplineType.Dressage,
        value: EqDisciplineType[EqDisciplineType.Dressage],
      });
      privatetypes.push({
        key: EqDisciplineType.Jumping,
        value: EqDisciplineType[EqDisciplineType.Jumping],
      });
    }

    editPrivatTypes.push({
      key: EqDisciplineType.Open,
      value: EqDisciplineType[EqDisciplineType.Open],
    });

    for (var x in EqDisciplineType) {
      if (isNaN(Number(x)) == false && Number(x) != EqDisciplineType.Open) {
        publictypes.push({ key: Number(x), value: EqDisciplineType[x] });
      }
    }
    // console.log(formData.accessType);
    if (isEdit == true) {
      if (formData.accessType == EqEventAccessType.Private) {
        setEventTypes({
          privateDiscipline: [...privatetypes],
          publicDiscipline: [...publictypes],
          editOpenDiscipline: [...editPrivatTypes],
          toggleOptions: [...privatetypes],
        });
      } else {
        setEventTypes({
          privateDiscipline: [...privatetypes],
          publicDiscipline: [...publictypes],
          editOpenDiscipline: [...editPrivatTypes],
          toggleOptions: [...publictypes],
        });
      }
    } else {
      setEventTypes({
        privateDiscipline: [...privatetypes],
        publicDiscipline: [...publictypes],
        editOpenDiscipline: [...editPrivatTypes],
        toggleOptions: [...privatetypes],
      });
    }
  };

  const createEventAccessTypes = () => {
    var accessTypes = [];
    var pAccess = [];
    for (var x in EqEventAccessType) {
      let label;
      if (x == EqEventAccessType.Private.toString()) {
        label = "Training";
      } else if (x == EqEventAccessType.Public.toString()) {
        label = "Event";
      }
      if (isNaN(Number(x)) == false) {
        accessTypes.push({ key: x, value: label });

        if (Number(x) == EqEventAccessType.Private) {
          pAccess.push({ key: Number(x), value: label });
        }
      }
    }
    // console.log(formData?.eventType);
    if (isEdit == true) {
      if (formData?.eventType == EqDisciplineType.Open) {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...pAccess],
        });
      } else {
        setEventAccessTypes({
          allAccessTypes: [...accessTypes],
          privateOnly: [...pAccess],
          toggleOptions: [...accessTypes],
        });
      }
    } else {
      setEventAccessTypes({
        allAccessTypes: [...accessTypes],
        privateOnly: [...pAccess],
        toggleOptions: [...accessTypes],
      });
    }
  };

  const sortAscending = (unsorted: Array<IStudentGroupDto>) => {
    const sorted = [...unsorted].sort((obj1, obj2) => {
      if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() >
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return 1;
      } else if (
        (
          obj1?.user?.firstName ??
          obj1?.user?.userName ??
          obj1?.group?.name ??
          obj1.key
        ).toLowerCase() <
        (
          obj2?.user?.firstName ??
          obj2?.user?.userName ??
          obj2?.group?.name ??
          obj2.key
        ).toLowerCase()
      ) {
        return -1;
      } else {
        return 0;
      }
    });
    // console.log(sorted);
    return sorted;
  };

  ////////////////

  const accessTogglerProps: IEqToggle = {
    items: eventAccessTypes.toggleOptions,
    defaultSelectedValue: formData?.accessType,
    classNames: "eq-toggle-orange-border eq-toggle-create-event",
    changeFunc: (value: string) => {
      let type: number = +value;
      setFormData({ ...formData, accessType: type });
      if (type == EqEventAccessType.Private) {
        setIsPublic(false);
        var privateEventTypes = [...eventTypes.privateDiscipline];
        // console.log(eventTypes);
        setEventTypes({ ...eventTypes, toggleOptions: privateEventTypes });
      } else {
        if (imagesData.length == 0) {
          getImages();
        }
        setIsPublic(true);
        var publicEventTypes = [...eventTypes.publicDiscipline];
        // console.log(eventTypes);
        setEventTypes({ ...eventTypes, toggleOptions: publicEventTypes });
      }
    },
  };

  const GenerateEventAccessAndDisciplineTypes = (
    isEdit: boolean,
    accessType: EqEventAccessType,
    eventType: EqDisciplineType
  ) => {
    let allAccessTypes = [];
    let privateAccessTypes = [];
    let privateOpenEventType = [];
    let privateEventTypes = [];
    let publicEventTypes = [];
    createEventAccessTypes();
    createEventTypes();
    //console.log(eventAccessTypes);
    //console.log(eventTypes);

    for (var x in EqEventAccessType) {
      let label;
      if (x == EqEventAccessType.Private.toString()) {
        label = "Training";
      } else if (x == EqEventAccessType.Public.toString()) {
        label = "Event";
      }
      if (isNaN(Number(x)) == false) {
        allAccessTypes.push({ key: x, value: label });

        if (Number(x) == EqEventAccessType.Private) {
          privateAccessTypes.push({ key: Number(x), value: label });
        }
      }
    }

    privateEventTypes.push({
      key: EqDisciplineType.Dressage,
      value: EqDisciplineType[EqDisciplineType.Dressage],
    });
    privateEventTypes.push({
      key: EqDisciplineType.Jumping,
      value: EqDisciplineType[EqDisciplineType.Jumping],
    });
    privateEventTypes.push({
      key: EqDisciplineType.Open,
      value: EqDisciplineType[EqDisciplineType.Open],
    });

    privateOpenEventType.push({
      key: EqDisciplineType.Open,
      value: EqDisciplineType[EqDisciplineType.Open],
    });

    for (var x in EqDisciplineType) {
      if (isNaN(Number(x)) == false && Number(x) != EqDisciplineType.Open) {
        publicEventTypes.push({ key: Number(x), value: EqDisciplineType[x] });
      }
    }

    setIsOpenEvent(false);
    if (isEdit) {
      if (
        accessType == EqEventAccessType.Private &&
        eventType == EqDisciplineType.Open
      ) {
        setIsOpenEvent(true);
        setIsPublic(false);
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...privateAccessTypes],
        });
        //setEventTypes({ ...eventTypes, toggleOptions: [...privateOpenEventType] });
        setEventTypes({
          privateDiscipline: [...privateEventTypes],
          publicDiscipline: [...publicEventTypes],
          editOpenDiscipline: [...privateOpenEventType],
          toggleOptions: [...privateOpenEventType],
        });
      } else if (accessType == EqEventAccessType.Private) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        //setEventTypes({ ...eventTypes, toggleOptions: [...privateEventTypes] });
        setEventTypes({
          privateDiscipline: [...privateEventTypes],
          publicDiscipline: [...publicEventTypes],
          editOpenDiscipline: [...privateOpenEventType],
          toggleOptions: [...privateEventTypes],
        });
        setIsPublic(false);
      } else {
        setIsPublic(true);
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        //setEventTypes({ ...eventTypes, toggleOptions: [...publicEventTypes] });
        setEventTypes({
          privateDiscipline: [...privateEventTypes],
          publicDiscipline: [...publicEventTypes],
          editOpenDiscipline: [...privateOpenEventType],
          toggleOptions: [...publicEventTypes],
        });
      }
    } else {
      if (accessType == EqEventAccessType.Private) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...privateEventTypes] });
      } else if (accessType == EqEventAccessType.Public) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...publicEventTypes] });
      }
    }
  };

  const setEventAccessAndDisciplineTypes = (
    isEdit: boolean,
    accessType: EqEventAccessType,
    eventType: EqDisciplineType
  ) => {
    let allAccessTypes = [...eventAccessTypes.allAccessTypes];
    let privateAccessTypes = [...eventAccessTypes.privateOnly];
    let privateOpenEventType = [...eventTypes.editOpenDiscipline];
    let privateEventTypes = [...eventTypes.privateDiscipline];
    let publicEventTypes = [...eventTypes.publicDiscipline];

    if (isEdit) {
      if (
        accessType == EqEventAccessType.Private &&
        eventType == EqDisciplineType.Open
      ) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...privateAccessTypes],
        });
        setEventTypes({
          ...eventTypes,
          toggleOptions: [...privateOpenEventType],
        });
      } else if (accessType == EqEventAccessType.Private) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...privateEventTypes] });
      } else {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...publicEventTypes] });
      }
    } else {
      if (accessType == EqEventAccessType.Private) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...privateEventTypes] });
      } else if (accessType == EqEventAccessType.Public) {
        setEventAccessTypes({
          ...eventAccessTypes,
          toggleOptions: [...allAccessTypes],
        });
        setEventTypes({ ...eventTypes, toggleOptions: [...publicEventTypes] });
      }
    }
  };

  React.useEffect(() => {
    // history.push(FrontEndPoints.trainer.createEvent);
    // console.log(isStudent);

    // getAreas();
    // // not necessary for student viw
    // getPopulation();
    // getGroupPopulation();
    ////setDates();
    // in training
    // getWeekDays();
    ////GenerateEventAccessAndDisciplineTypes();
    // var event =props?.event ||props?.location?.state?.event;
    // var event =props?.event ||props?.location?.state?.event;

    if (population) {
      sortAscending(population);
    }
    if (event != undefined) {
      
      setIsEdit(true);
      setActivityTitle(event.title);
      if (event.accessType === 1) {
        setIsPublic(false);
      } else {
        if (imagesData.length == 0) {
          getImages();
        }
        setIsPublic(true);
      }
      setEventKey(event.key);
      GenerateEventAccessAndDisciplineTypes(
        true,
        event.accessType,
        event.disciplineType
      );
      var formObj = setEditFormData(event);
      setFormData(formObj);
    } else {
      setIsEdit(false);
      setEventDefaults();
      setEventKey(0);
      createEventTypes();
      createEventAccessTypes();
    }
  }, [event]);

  //console.log("Data", attendeeCardData);

  return (
    <div className="eq-student-activity-main">
      {/* <Row className="create-title">
        <Col className="title-text gutter-row" flex="auto" span={24}>
          {activityTitle === "" ? (eventKey==0? t("Add New Activity"): t("Edit Activity")): activityTitle}
        </Col>
        <Col className="action-group">
   
        </Col>
      </Row> */}

      <div className="eq-create-activity-container">
        <div className="">
          <Row gutter={gutter} className="event-info-input">
            <Col className="gutter-row" span={24}>
              {!isEdit && (
                <Row gutter={gutter}>
                  <Col className="gutter-row" span={24}>
                    <div className="event-type">
                      <div className="label">Select Event Access Type</div>
                      <div className="toggler">
                        <EqToggle {...accessTogglerProps} />
                      </div>
                    </div>
                  </Col>
                </Row>
              )}

              {event.accessType == EqEventAccessType.Private ? (
                <EqTrainingStudentView
                  // onActivityTitleChange={(e: any) => {
                  //   setActivityTitle(e.currentTarget.value);
                  // }}
                  areas={areas}
                  weekDaysData={weekDays}
                  groupPopulation={groupPopulation}
                  trainerStudents={trainerStudents}
                  // studentHorsesList={studentHorsesList}

                  eventTypes={eventTypes}
                  eventKey={eventKey}
                  eventData={event}
                  onCreateOrUpdateActivity={onCreateOrUpdateActivity}
                  isStudent={isStudent}
                  // onCreateOrUpdateActivity={()=>{
                  //
                  //   onCreateOrUpdateActivity}}
                />
              ) : isPublic == true ? (
                <EqEventStudentView
                  onActivityTitleChange={(e: any) => {
                    setActivityTitle(e.currentTarget.value);
                  }}
                  imagesData={imagesData}
                  areas={areas}
                  population={population}
                  groupPopulation={groupPopulation}
                  eventTypes={eventTypes}
                  eventKey={eventKey}
                />
              ) : null}
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};
export default EqActivityStudentView;
